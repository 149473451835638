<div class="panel panel-with-borders">
    <div class="panel-body">
        <mat-tab-group animationDuration="250ms" class="margin-top-10" [selectedIndex]="selectedLimit" (selectedIndexChange)="selectTab($event)">
            <!-- Withdrawal -->
            <mat-tab label="Withdrawal" *ngIf="isLimitsDefined('withdraw')">
                <div class="display-flex-row margin-top-25 margin-bottom-3">
                    <mat-card *ngFor="let level of levels" class="limits-card">
                        <mat-card-title>LEVEL <span>{{ level }}</span></mat-card-title>
                        <!--mat-card-subtitle>Without any KYC</mat-card-subtitle-->
                        <mat-card-content>
                            <div *ngFor="let t of (limits[level].withdraw) | keyvalue" class="display-flex-row space-between">
                                <span>{{ t.key | uppercase }}</span>
                                <span class="font-weight-bold">{{ t.value | number }}</span>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </mat-tab>
            <!-- Transactions -->
            <mat-tab label="Transactions" *ngIf="isLimitsDefined('transaction')">
                <div class="display-flex-row  margin-top-25 margin-bottom-3">
                    <mat-card *ngFor="let level of levels" class="limits-card">
                        <mat-card-title>LEVEL <span>{{ level }}</span></mat-card-title>
                        <!--mat-card-subtitle>Without any KYC</mat-card-subtitle-->
                        <mat-card-content>
                            <div *ngFor="let t of (sortLimitValues(limits[level].transaction))" class="display-flex-row space-between">
                                <span>{{ t[0] | uppercase }}</span>
                                <span class="font-weight-bold">{{ t[1] | number }}</span>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </mat-tab>
            <!-- Velocity -->
            <mat-tab label="Velocity" *ngIf="isLimitsDefined('velocity')">
                <div class="display-flex-row  margin-top-25 margin-bottom-3">
                    <mat-card *ngFor="let level of levels" class="limits-card">
                        <mat-card-title>LEVEL <span>{{ level }}</span></mat-card-title>
                        <!--mat-card-subtitle>Without any KYC</mat-card-subtitle-->
                        <mat-card-content>
                            <div *ngFor="let t of (sortLimitValues(limits[level].velocity))" class="display-flex-row space-between">
                                <span>{{ t[0] | uppercase }}</span>
                                <span class="font-weight-bold">{{ t[1] | number }}</span>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </mat-tab>
            <!-- Send -->
            <mat-tab label="Send" *ngIf="isLimitsDefined('send')">
                <div class="display-flex-row  margin-top-25 margin-bottom-3">
                    <mat-card *ngFor="let level of levels" class="limits-card">
                        <mat-card-title>LEVEL <span>{{ level }}</span></mat-card-title>
                        <!--mat-card-subtitle>Without any KYC</mat-card-subtitle-->
                        <mat-card-content>
                            <div *ngFor="let t of (limits[level].send) | keyvalue" class="display-flex-row space-between">
                                <span>{{ t.key | uppercase }}</span>
                                <span class="font-weight-bold">{{ t.value | number }}</span>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </mat-tab>
            <!-- Receive -->
            <mat-tab label="Receive" *ngIf="isLimitsDefined('receive')">
                <div class="display-flex-row  margin-top-25 margin-bottom-3">
                    <mat-card *ngFor="let level of levels" class="limits-card">
                        <mat-card-title>LEVEL <span>{{ level }}</span></mat-card-title>
                        <!--mat-card-subtitle>Without any KYC</mat-card-subtitle-->
                        <mat-card-content>
                            <div *ngFor="let t of (limits[level].receive) | keyvalue" class="display-flex-row space-between">
                                <span>{{ t.key | uppercase }}</span>
                                <span class="font-weight-bold">{{ t.value | number }}</span>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </mat-tab>
            <!-- Topup -->
            <mat-tab label="Topup" *ngIf="isLimitsDefined('topup')">
                <div class="display-flex-row  margin-top-25 margin-bottom-3">
                    <mat-card *ngFor="let level of levels" class="limits-card">
                        <mat-card-title>LEVEL <span>{{ level }}</span></mat-card-title>
                        <!--mat-card-subtitle>Without any KYC</mat-card-subtitle-->
                        <mat-card-content>
                            <div class="display-flex-row space-between">
                                <span>Maximum</span>
                                <span class="font-weight-bold">{{ limits[level].topup | number }}</span>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </mat-tab>
            <!-- Balance -->
            <mat-tab label="Balance" *ngIf="isLimitsDefined('balance')">
                <div class="display-flex-row  margin-top-25 margin-bottom-3">
                    <mat-card *ngFor="let level of levels" class="limits-card">
                        <mat-card-title>LEVEL <span>{{ level }}</span></mat-card-title>
                        <!--mat-card-subtitle>Without any KYC</mat-card-subtitle-->
                        <mat-card-content>
                            <div class="display-flex-row space-between">
                                <span>Maximum</span>
                                <span class="font-weight-bold">{{ limits[level].balance | number }}</span>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </mat-tab>
            <!-- Handout -->
            <mat-tab label="Handout" *ngIf="isLimitsDefined('handout')">
                <div class="display-flex-row  margin-top-25 margin-bottom-3">
                    <mat-card *ngFor="let level of levels" class="limits-card">
                        <mat-card-title>LEVEL <span>{{ level }}</span></mat-card-title>
                        <!--mat-card-subtitle>Without any KYC</mat-card-subtitle-->
                        <mat-card-content>
                            <div class="display-flex-row space-between">
                                <span>Maximum</span>
                                <span class="font-weight-bold">{{ limits[level].handout | number }}</span>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
