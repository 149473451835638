/**
 * Created by Arindam Bajpayee on 04.03.2022.
 */
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";

import { AuthenticationService } from "./authentication.service";

import { environment } from "../../environments/environment";

@Injectable()
export class LimitService {
    constructor(private http: HttpClient,
                private authenticationService: AuthenticationService) {
    }

    /* Get all limits information */
    getLimits = () => this.http.get(`${environment.baseurl.api}/protected/limits`, this.authenticationService.getHeaders()).pipe(map(res => res));
}
