/**
 * Created by Arindam Bajpayee on 28-04-2017.
 */
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";

import { environment } from "../../environments/environment";
import { AuthenticationService } from "./authentication.service";
import { UserProfileComments } from "../models/user";
import { TopupsFiltersParams } from "../components/admin/user/interfaces/csv-filters.interface";

@Injectable()
export class UserService {
    constructor(private http: HttpClient,
                private authenticationService: AuthenticationService) {
    }

    addBalance(parameters) {
        return this.http.post(
            environment.baseurl.api + "/protected/user/balance",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                amount: parameters.amount,
                comments: parameters.comments,
                currency: parameters.currency,
                id: parameters.id,
                country: parameters.country
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    blockUser(identifier: string, country: any, comments: string) {
        return this.http.post(
            environment.baseurl.api + "/protected/user/block",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                identifier,
                country,
                comments
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    deleteTag(identifier: string, country: any, tag: string) {
        const httpOptions = {
            headers: this.authenticationService.getHeaders().headers,
            body: {
                identifier,
                country,
                tag
            }
        };
        return this.http.delete(environment.baseurl.api + "/protected/user/tag", httpOptions).pipe(map(res => res));
    }

    deleteUser(identifier: string, country: any, comments: string) {
        return this.http.post(
            environment.baseurl.api + "/protected/user/delete",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                identifier,
                country,
                comments
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    /* For Users page */
    getAll(parameters) {
        return this.http.post(
            environment.baseurl.api + "/protected/user/all",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                currency: parameters.currency,
                filter: parameters.filter,
                country: parameters.country,
                status: parameters.status,
                page: parameters.page
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    /* For Users's page */
    getAllData(country: any) {
        return this.http.post(
            environment.baseurl.api + "/protected/user/allData",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                country
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    /* Get the details of an user by _id */
    getDetails(identifier: string, country: any) {
        return this.http.post(
            `${environment.baseurl.api}/protected/user/id=${identifier}`,
            { country },
            this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    getAuditsLogs(identifier: string, country: any) {
        return this.http.post(
            environment.baseurl.api + "/protected/user/auditsLogs",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                identifier,
                country
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    saveAuditLogComments(id: string, comments: string, country: any) {
        return this.http.post(
            environment.baseurl.api + "/protected/user/auditLogComments",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                id,
                comments,
                country
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    getProfiles(parameters) {
        return this.http.post(
            environment.baseurl.api + "/protected/user/profiles",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                ...parameters
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    getTopups({ start, end, country }: TopupsFiltersParams) {
        return this.http.post(
            environment.baseurl.api + "/protected/user/topups",
            { currentUser: this.authenticationService.getCurrentUser(), startDate: start, endDate: end, country },
            this.authenticationService.getHeaders()
        );
    }

    getTransactionsHistory(parameters) {
        return this.http.post(
            environment.baseurl.api + "/protected/user/transactionHistory",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                currency: parameters.currency,
                id: parameters.id,
                from: parameters.from,
                to: parameters.to,
                country: parameters.country
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    getUsersCount(frequency: string, filter: any, country: any) {
        return this.http.post(
            environment.baseurl.api + "/protected/user/count",
            {
                filter,
                currentUser: this.authenticationService.getCurrentUser(),
                frequency,
                country
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    getUsersCountByPaymentMethod(frequency: string, filter: any, country: any) {
        return this.http.post(
            environment.baseurl.api + "/protected/user/count/payment",
            {
                filter,
                currentUser: this.authenticationService.getCurrentUser(),
                frequency,
                country
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    getUsersHistory(filter: any, country: any) {
        return this.http.post(
            environment.baseurl.api + "/protected/user/history",
            {
                filter,
                currentUser: this.authenticationService.getCurrentUser(),
                country
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    resetUserLimits(user) {
        return this.http.post(
            environment.baseurl.api + `/protected/user/${user._id}/resetLimits`,
            { countryCode: user.address.countryCode },
            this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    resetPassword(parameters) {
        return this.http.post(
            environment.baseurl.api + "/protected/user/password/reset",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                email: parameters.email,
                phone: parameters.phone,
                country: parameters.country,
                userLocale: parameters.userLocale,
                userType: parameters.userType
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    /* Set user profile (KYC) comments */
    resetUserKyc(identifier: string, comments: UserProfileComments[], country: any) {
        return this.http.post(
            environment.baseurl.api + "/protected/user/kyc/reset",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                identifier,
                comments,
                country
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    restoreUser(identifier: string, country: any, comments: string) {
        return this.http.post(
            environment.baseurl.api + "/protected/user/restore",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                identifier,
                country,
                comments
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    sendDataToIdenfy({ userId, countryCode }) {
        return this.http.post(
            environment.baseurl.api + "/protected/user/sendDataToIdenfy",
            {
                userId,
                countryCode
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    deleteDataFromIdenfy({ userId, countryCode }) {
        return this.http.post(
            environment.baseurl.api + "/protected/user/deleteDataFromIdenfy",
            {
                userId,
                countryCode
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    setIban(identifier: string, iban: string, country: any) {
        return this.http.post(
            environment.baseurl.api + "/protected/user/iban",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                iban,
                identifier,
                country
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    setLimits(identifier: string, limits: object, country: any) {
        return this.http.post(
            environment.baseurl.api + "/protected/user/limits",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                identifier,
                limits,
                country
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    /* Set user profile (KYC) comments */
    setProfileComments(identifier: string, comments: UserProfileComments[], country: any) {
        return this.http.post(
            environment.baseurl.api + "/protected/user/profile/comments",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                identifier,
                comments,
                country
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    /* Set Rejection Message with comments for Address/ SourceofFunds */
    setRejection(parameters) {
        return this.http.post(
            environment.baseurl.api + "/protected/user/profile/rejection",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                country: parameters.country,
                comment: parameters.data.comment,
                address: parameters.data.address,
                kyc: parameters.data.kyc,
                sourceOfFunds: parameters.data.sourceOfFunds,
                id: parameters.id
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    setContactDetails(identifier: string, details: { phone?: string, email?: string }, country: any) {
        return this.http.post(
            environment.baseurl.api + "/protected/user/contact",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                details,
                identifier,
                country
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    setAddress(identifier: string, address: any, country: any, manualAddress: boolean) {
        return this.http.post(
            environment.baseurl.api + "/protected/user/address",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                address,
                manualAddress,
                identifier,
                country
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    setDateOfBirth(identifier: string, dateOfBirth: any, country: any) {
        return this.http.post(
            environment.baseurl.api + "/protected/user/dateOfBirth",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                dateOfBirth,
                identifier,
                country
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    setNationality(identifier: string, nationality: any, country: any) {
        return this.http.post(
            environment.baseurl.api + "/protected/user/nationality",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                nationality,
                identifier,
                country
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    setName(identifier: string, name: any, country: any) {
        return this.http.post(
            environment.baseurl.api + "/protected/user/name",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                name,
                identifier,
                country
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    setRiskCategory(parameters) {
        return this.http.post(
            environment.baseurl.api + "/protected/user/risk/category",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                country: parameters.country,
                id: parameters.id,
                category: parameters.category
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    setVerificationStatus({ identifier, decision = null, country, verificationLevel = null }) {
        return this.http.post(
            environment.baseurl.api + "/protected/user/verificationStatus",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                identifier,
                decision,
                verificationLevel,
                country
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    triggerCIF(country: any, data: any) {
        return this.http.post(
            environment.baseurl.jobs + "/job/start?name=USER_CIF",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                data,
                country
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }
}
