import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { environment } from "../../environments/environment";
import { AuthenticationService } from "./authentication.service";
import { ResponseBO } from "../models/common";

@Injectable({
    providedIn: "root"
})
export class ProtectedRequestService {

    constructor(
        private authenticationService: AuthenticationService,
        private http: HttpClient
    ) {
    }

    delete<T>(url: string, params?: { [ key: string ]: any }): Observable<ResponseBO<T>> {
        const { headers } = this.authenticationService.getHeaders();
        return this.http.delete<ResponseBO<T>>(
            environment.baseurl.api + "/protected" + url,
            { params, headers }
        );
    }

    get<T>(url: string, params?: { [ key: string ]: any }): Observable<ResponseBO<T>> {
        const { headers } = this.authenticationService.getHeaders();
        return this.http.get<ResponseBO<T>>(
            environment.baseurl.api + "/protected" + url,
            { params, headers }
        );
    }

    post<T>(url: string, payload?: { [ key: string ]: any } | any[], options: { [ key: string ]: any } = {}): Observable<ResponseBO<T>> {
        return this.http.post<ResponseBO<T>>(
            environment.baseurl.api + "/protected" + url,
            {
                payload,
                currentUser: this.authenticationService.getCurrentUser(),
                ...options
            },
            this.authenticationService.getHeaders()
        );
    }

}
