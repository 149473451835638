/**
 * Created by Arindam Bajpayee on 28-04-2017.
 */
import { Injectable } from "@angular/core";
// import { Http, Headers, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { Observable, Subject } from "rxjs";

import { environment } from "../../environments/environment";
import { SelectedCountryData } from "../components/shared/components/domain-selector.component";
import { map } from "rxjs/operators";

interface IAuthHeaders {
    headers: { [ name: string ]: string | string[] } | HttpHeaders;
}

@Injectable({ providedIn: "root" })
export class AuthenticationService {
    private DEFAULT_COUNTRY: SelectedCountryData = {
        code: "CH",
        currency: "CHF",
        name: "Switzerland"
    };
    private DEFAULT_COUNTRY_CODE = "CH";
    private SELECTED_COUNTRY_KEY = "SELECTED_COUNTRY_KEY";
    private SELECTED_COUNTRY_CODE_KEY = "SELECTED_COUNTRY_CODE_KEY";
    public URL_2FA = "trigger/two-factor-authentication";

    private userLoggedIn = new Subject<boolean>();

    constructor(private http: HttpClient, private router: Router) {
        this.userLoggedIn.next(false);
    }

    setUserLoggedIn(userLoggedIn: boolean) {
        this.userLoggedIn.next(userLoggedIn);
    }

    getUserLoggedIn(): Observable<boolean> {
        return this.userLoggedIn.asObservable();
    }

    clearState() {
        sessionStorage.removeItem("atms");
        sessionStorage.removeItem("transactions");
        sessionStorage.removeItem("users");
        sessionStorage.removeItem("userProfiles");
    }

    getCurrentCountry(): SelectedCountryData {
        return JSON.parse(localStorage.getItem(this.SELECTED_COUNTRY_KEY)) || this.DEFAULT_COUNTRY;
    }

    setCurrentCountry(country: SelectedCountryData) {
        localStorage.setItem(this.SELECTED_COUNTRY_KEY, JSON.stringify(country));
    }

    getCurrentCountryCode(): string {
        return localStorage.getItem(this.SELECTED_COUNTRY_CODE_KEY) || this.DEFAULT_COUNTRY_CODE;
    }

    setCurrentCountryCode(code: string) {
        localStorage.setItem(this.SELECTED_COUNTRY_CODE_KEY, code);
    }

    getCurrentUser(): any {
        return JSON.parse(localStorage.getItem("currentUser"));
    }

    getCurrentToken(): any {
        return JSON.parse(localStorage.getItem("currentToken"));
    }

    getHeaders(): { headers: HttpHeaders } {
        const headers = new HttpHeaders(
            {
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.getCurrentToken()
            });
        return { headers };
    }

    getHeadersForText(): any {
        const header = new HttpHeaders(
            {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.getCurrentToken()}`
            });
        return { headers: header, responseType: "text", observe: "response" };
    }

    getState(route: string) {
        return sessionStorage.getItem(route);
    }

    isUserLoggedIn() {
        return localStorage.getItem("currentUser");
    }

    login(username: string, password: string): Observable<any> {
        return this.http.post<any>(`${environment.baseurl.api}/public/auth/login`, { username, password });
    }

    logout() {
        this.setUserLoggedIn(false);
        // localStorage.removeItem("currentUser");
        // localStorage.removeItem("currentToken");
        localStorage.clear();
    }

    saveState(route: string, data: any) {
        sessionStorage.setItem(route, JSON.stringify(data));
    }

    trigger2FA(message, reason = "kyc_bnl") {
        return this.http.post(
            `${environment.baseurl.api}/protected/${this.URL_2FA}`,
            {
                currentUser: this.getCurrentUser(),
                message,
                reason,
            }, this.getHeaders()
        ).pipe(map(res => res));
    }

    verifyRequest(token: string, code?: string) {
        const path = code && code.length === 6 ? `/public/auth/status?code=${code}` : "/public/auth/status";
        return this.http
            .get<any>(
                environment.baseurl.api + path,
                { headers: new HttpHeaders({ Token: token }) });
    }
}
