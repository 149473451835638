import { AfterViewChecked, Component } from '@angular/core';

@Component({
  selector: 'bo-print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.scss']
})
export class PrintComponent implements  AfterViewChecked {
  public  printDataNodeId = "print-data";
  public  printContainerNodeId = "print-data-container";

  constructor() { }

  ngAfterViewChecked(): void {
    const printContent = document.getElementById(this.printContainerNodeId);
    if (printContent) {
      printContent.remove();
    }

    const printContainer = document.getElementById(this.printDataNodeId);
    if (printContainer) {
      const newPrintContent = document.createElement(printContainer.tagName);
      newPrintContent.innerHTML = printContainer.innerHTML;
      newPrintContent.id = this.printContainerNodeId;
      document.body.appendChild(newPrintContent);
    }
  }

  print() {
    window.print();
  }

}
