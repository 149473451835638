/**
 * Created by Arindam Bajpayee on 08-03-2020.
 */
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";

import { Job } from "../models/job";
import { AuthenticationService } from "./authentication.service";

import { environment } from "../../environments/environment";

@Injectable()
export class JobService {
    constructor(private http: HttpClient,
                private authenticationService: AuthenticationService) {
    }

    /* Create a new job */
    create(job: Job) {
        return this.http.post(
            environment.baseurl.api + "/protected/job/create",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                job
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    /* Get all job information */
    getJobs() {
        return this.http.get(
            environment.baseurl.api + "/protected/job/all",
            this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    /* Create a new job */
    run(job: Job) {
        return this.http.post(
            environment.baseurl.api + "/protected/job/run",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                job
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }
}
