/**
 * Created by Arindam Bajpayee on 2/23/2020.
 */
export class Country {
    currency = "CHF";
    name = "Switzerland";
    callingCode = "41";
    cca2 = "CH";
    cca3 = "CHE";
    country = "Switzerland";
    region = "Europe";
    subregion = "Western Europe";
    tld = [ ".ch" ];

    constructor(data: Partial<Country> = {}) {
        Object.assign(this, data);
    }
}
