/**
 * Created by Arindam Bajpayee on 28-04-2017.
 */
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";

import { environment } from "../../environments/environment";
import { AuthenticationService } from "./authentication.service";
import { of } from "rxjs";
import { OpeningHoursPayload } from "../models/openingHoursPayload";

@Injectable()
export class AtmService {
    constructor(
        private http: HttpClient,
        private authenticationService: AuthenticationService
    ) {
    }

    /* For ATM's page */
    create(atm: any, country: any) {
        // const options = new RequestOptions(this.headers());
        atm.country = country;
        return this.http.post(
            environment.baseurl.api + "/protected/atm/create",
            atm, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    patchAtm(id: string, data: { phone?: string, email?: string }, country: any) {
        console.log("id", id);
        console.log("data", data);
        console.log("country", country);
        return this.http.patch(
            `${environment.baseurl.api}/protected/atm/id=${id}`,
            {
                currentUser: this.authenticationService.getCurrentUser(),
                country,
                data
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    /* Calculate ATM risk category */
    calculateRiskCategory({ id, countryCode }) {
        return this.http.post(
            environment.baseurl.api + "/protected/atm/risk/category",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                id,
                countryCode
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    /* For creating multiple shops for a PARTNER */
    createAtm(country: any, data: object) {
        return this.http.post(
            environment.baseurl.api + "/protected/atm/createAtm",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                country,
                data
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }


    blockAtm(country: any, identifier: string, comments: string) {
        return this.http.post(
            environment.baseurl.api + "/protected/atm/block",
            {
                country,
                comments,
                identifier
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    unblockAtm(country: any, identifier: string, comments: string) {
        return this.http.post(
            environment.baseurl.api + "/protected/atm/unblock",
            {
                country,
                comments,
                identifier
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    deleteAtm(country: any, id: string, comments: string) {
        return this.http.post(
            environment.baseurl.api + "/protected/atm/delete",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                country,
                comments,
                id
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    restoreAtm(country: any, id: string) {
        return this.http.post(
            environment.baseurl.api + "/protected/atm/restore",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                country,
                id
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    getCountOfSimilarAtms(filter: any, country: any) {
        return this.http.post(
            environment.baseurl.api + "/protected/atm/countSimilars",
            {
                filter,
                currentUser: this.authenticationService.getCurrentUser(),
                country,
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    /* For dashboard widgets */
    getAtmsCount(frequency: string, filter: any, country: any) {
        return this.http.post(
            environment.baseurl.api + "/protected/atm/count",
            {
                filter,
                currentUser: this.authenticationService.getCurrentUser(),
                country,
                frequency
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    getAuditsLogs(identifier: string, sonectId: string, country: any) {
        return this.http.post(
            `${environment.baseurl.api}/protected/atm/auditsLogs`,
            {
                currentUser: this.authenticationService.getCurrentUser(),
                identifier,
                sonectId, // to fetch for old audit logs
                country
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    getAuditsForLimitBreaches(country: any) {
        return this.http.get(
            `${environment.baseurl.api}/protected/audits/country=${country.code}/type=atm`,
            this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    getProfiles(parameters) {
        return this.http.post(
            environment.baseurl.api + "/protected/atm/profiles",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                ...parameters
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    /* For ATM's page */

    // getAll(currency: string, country: any, status: string, filter: any, page: number) {
    getAll(parameters) {
        return this.http.post(
            `${environment.baseurl.api}/protected/atm/all`,
            {
                currency: parameters.currency,
                filter: parameters.filter,
                country: parameters.country,
                status: parameters.status,
                page: parameters.page
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    /* For ATM's page */
    download(country: any) {
        return this.http.post(
            `${environment.baseurl.api}/protected/atm/download`,
            {
                currentUser: this.authenticationService.getCurrentUser(),
                country
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    /* For ATM's JSON */
    downloadAsJson(country: any) {
        return this.http.post(
            `${environment.baseurl.api}/protected/atm/download/type=json`,
            {
                currentUser: this.authenticationService.getCurrentUser(),
                country
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    getAtmCategories() {
        return this.http.get(environment.baseurl.api + "/public/atm/categories").pipe(map(res => res));
    }

    getAtmAuditLimits(country: any) {
        return this.http.post(
            environment.baseurl.api + "/protected/atm/audit/limits",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                country
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    getByAtmId(country: any, id: string) {
        return this.http.post(
            environment.baseurl.api + `/protected/atm/id=${id}`,
            {
                currentUser: this.authenticationService.getCurrentUser(),
                country,
                id
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    getPhysicalAtms() {
        return this.http.post(
            environment.baseurl.api + "/protected/bank/atm/all",
            {
                currentUser: this.authenticationService.getCurrentUser()
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    getTransactionsHistory(parameters) {
        return this.http.post(
            environment.baseurl.api + "/protected/atm/transactionHistory",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                currency: parameters.currency,
                id: parameters.id,
                from: parameters.from,
                to: parameters.to
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    getFraudCheckData({ companyName, countryCode }) {
        return this.http.post(
            environment.baseurl.api + "/protected/atm/fraudCheckData",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                companyName,
                countryCode
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    sendDataToIdenfy({ atmId, sonectId, companyName, countryCode }) {
        return this.http.post(
            environment.baseurl.api + "/protected/atm/sendDataToIdenfy",
            {
                atmId,
                sonectId,
                companyName,
                countryCode
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    deleteDataFromIdenfy({ atmId, countryCode }) {
        return this.http.post(
            environment.baseurl.api + "/protected/atm/deleteDataFromIdenfy",
            {
                atmId,
                countryCode
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    regenerateFile(identifier: string, country: any) {
        return this.http.post(
            environment.baseurl.api + "/protected/atm/ubo/kyc/file",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                identifier,
                country
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    resetPassword(id: string, masterSonectId: string, country: any) {
        return this.http.post(
            environment.baseurl.api + "/protected/atm/password/reset",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                id,
                masterSonectId,
                country
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    resetKyc(parameters) {
        return this.http.post(
            environment.baseurl.api + "/protected/atm/kyc/reset",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                comments: parameters.comments,
                country: parameters.country,
                destination: parameters.destination,
                sonectId: parameters.sonectId,
                type: parameters.type
            }, this.authenticationService.getHeaders());
    }

    setAccountDetails(parameters) {
        return this.http.post(
            environment.baseurl.api + "/protected/atm/accountDetails",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                id: parameters.id,
                iban: parameters.iban,
                ownerIban: parameters.ownerIban,
                beneficiaryName: parameters.beneficiaryName,
                ownerBeneficiaryName: parameters.ownerBeneficiaryName,
                accountAddress: parameters.accountAddress,
                country: parameters.country,
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    setCategory(parameters) {
        return this.http.post(
            environment.baseurl.api + "/protected/atm/category",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                sonectId: parameters.sonectId,
                category: parameters.category,
                categoryText: parameters.categoryText,
                country: parameters.country
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    setComments(parameters) {
        return this.http.post(
            environment.baseurl.api + "/protected/atm/kyc/comments",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                comments: parameters.comments,
                country: parameters.country,
                destination: parameters.destination,
                id: parameters.id,
                type: parameters.type
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    setOpeningHours(parameters) {
        let payload: OpeningHoursPayload = {
            currentUser: this.authenticationService.getCurrentUser(),
            id: parameters.id,
            day: parameters.day,
            open: parameters.open,
            close: parameters.close,
            country: parameters.country
        };
        if (parameters.breaks.length) {
            payload = {
                ...payload,
                breaks: parameters.breaks
            };
        }
        return this.http.post(environment.baseurl.api + "/protected/atm/openingHours", payload, this.authenticationService.getHeaders())
            .pipe(map(res => res));
    }

    saveOpeningHours(atmId: string, country, openingHours) {
        const payload = {
            country,
            openingHours
        }
        return this.http.post(environment.baseurl.api + `/protected/atm/id=${atmId}/hours`, payload, this.authenticationService.getHeaders())
        .pipe(map(res => res));
    }

    setParent(parameters) {
        return this.http.post(
            environment.baseurl.api + "/protected/atm/parent",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                parent: parameters.parent,
                referenceId: parameters.referenceId,
                sonectId: parameters.sonectId,
                country: parameters.country
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    setRiskCategory(parameters) {
        return this.http.post(
            environment.baseurl.api + "/protected/atm/updateRiskCategory",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                country: parameters.country,
                id: parameters.id,
                category: parameters.category
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    setTagline(sonectId: string, tagline: string, country: any) {
        return this.http.post(
            environment.baseurl.api + "/protected/atm/tagline",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                sonectId,
                tagline,
                country
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    setVerificationStatus(parameters) {
        return this.http.post(
            environment.baseurl.api + "/protected/atm/kyc/verificationStatus",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                decision: parameters.decision,
                destination: parameters.destination,
                id: parameters.id,
                country: parameters.country
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    triggerMonthlyReport(domain: string, sonectId: string, triggerMonth: string, triggerYear: string) {
        if (triggerMonth === null && triggerYear === null) {
            return this.http.get(`${environment.baseurl.jobs}/job/start?name=MERCHANT_SUMMARY_MONTHLY&sonectId=${sonectId}`, this.authenticationService.getHeaders()).pipe(map(res => res));
        } else {
            return this.http.get(
                `${environment.baseurl.jobs}/job/start?name=MERCHANT_SUMMARY_MONTHLY&domain=${domain}&sonectId=${sonectId}&triggerMonth=${triggerMonth}&triggerYear=${triggerYear}`,
                this.authenticationService.getHeaders()
            ).pipe(map(res => res));
        }
    }

    toggleShopVisibilityOnMap(country: any, atmId: string, hideShopOnMap: boolean) {
        return this.http.post(
            `${environment.baseurl.api}/protected/atm/id=${atmId}/visibility`,
            { country, hideShopOnMap },
            this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    /* For ATM's Image Update */
    updateImage(sonectId: string, imageBase64: any, country: any) {
        return this.http.post(
            `${environment.baseurl.api}/protected/atm/updateImage`,
            {
                sonectId,
                imageBase64,
                country
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));

    }

    createOwnerInformation(atm, owner) {
        const { _id: ownerId, ...payload } = owner;

        return this.http.post(
            `${environment.baseurl.api}/protected/atm/id=${atm._id}/owner`,
            { countryCode: atm.address.countryCode, owner: payload },
            this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    updateOwnerInformation(atm, owner) {
        if (!owner._id) {
            alert("no owner._id");
            return of({});
        }
        const { _id: ownerId, ...payload } = owner;
        return this.http.patch(
            `${environment.baseurl.api}/protected/atm/id=${atm._id}/owner/id=${ownerId}`,
            { countryCode: atm.address.countryCode, owner: payload },
            this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    resetAtmLimits(atm) {
        return this.http.post(
            `${environment.baseurl.api}/protected/atm/id=${atm._id}/resetLimits`,
            { countryCode: atm.address.countryCode },
            this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }
}
