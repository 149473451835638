<section class="page-content">
    <div class="page-content-inner single-page-login-alpha">
        <div class="single-page-block-header">
            <div class="row">
                <div class="col-lg-12">
                    <div class="logo">
                        <img alt="SONECT AG" src="../../../assets/img/logo/Sonect_Wordmark_RGB_TRANSP.png"/>
                    </div>
                </div>
            </div>
        </div>

        <div class="single-page-block">
            <div class="row">
                <div class="col-xl-12">
                    <div class="welcome-text">
                        <h2 class="sonect-beige-color">Backoffice <span class="app-environment sonect-berry-bg color-white" *ngIf="!getEnvironment().production">{{ getEnvironment().name }}</span></h2>
                        <p>Two-factor Authentication (2FA) is now mandatory for login.</p>
                    </div>
                    <div class="single-page-block-inner">
                        <div class="single-page-block-form">
                            <form>
                                <ng-container *ngIf="!is2FAStarted; else twoFactorCodeEntry">
                                    <div class="form-group">
                                        <label class="font-weight-500">Username</label>
                                        <div class="form-input-icon">
                                            <i class="fas fa-user"></i>
                                            <input [disabled]="is2FAStarted" [ngModelOptions]="{standalone: true}" [(ngModel)]="username" class="form-control" name="username" placeholder="Email/username" type="text">
                                            <small>Email/username</small>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="font-weight-500">Password</label>
                                        <div class="form-input-icon">
                                            <i class="fas fa-key"></i>
                                            <input [ngModelOptions]="{standalone: true}" [(ngModel)]="password" class="form-control password" name="password" placeholder="Password" type="password">
                                        </div>
                                    </div>
                                    <button class="btn btn-primary float-right margin-top-10 width-150" type="submit" data-style="zoom-out" (click)="login()">Login</button>
                                </ng-container>
                                <ng-template #twoFactorCodeEntry>
                                    <div class="form-group">
                                        <label class="font-weight-500">Enter the code from Authy or approve notification</label>
                                        <div class="form-input-icon">
                                            <i class="fas fa-mobile-alt"></i>
                                            <input [ngModelOptions]="{standalone: true}" [(ngModel)]="code" class="form-control" name="code" placeholder="6-Digit code" type="text">
                                        </div>
                                    </div>
                                    <button class="btn btn-primary float-right margin-top-10 width-150" type="submit" data-style="zoom-out" (click)="verify()">Verify</button>
                                </ng-template>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>
