<div class="page-title">Reports</div>
<div class="panel panel-with-borders margin-top-10 margin-bottom-0 clear-fix">
    <div class="panel-body">
        <div class="nav-tabs-horizontal margin-bottom-20">
            <ul class="nav nav-tabs">
                <li class="nav-item">
                    <a (click)="selectTab($event, 'list')" [ngClass]="{'active': selectedTab === 'list'}" class="nav-link"
                       href="javascript: void(0);">List</a>
                </li>
                <!--
                <li class="nav-item">
                    <a (click)="selectTab($event, 'new')" [ngClass]="{'active': selectedTab === 'new'}" class="nav-link"
                       href="javascript: void(0);">Create new</a>
                </li>
                -->
            </ul>
        </div>

        <div *ngIf="selectedTab === 'list'" class="" id="list_reports">
            <!-- Filter -->
            <!--<form (ngSubmit)="searchUserProfiles()">-->
            <!--    <div class="row margin-bottom-15">-->
            <!--        <div class="col">-->
            <!--            <domain-selector (out)="selectedCountry($event)"></domain-selector>-->
            <!--            <button mat-icon-button><i class="fas fa-search"></i></button>-->
            <!--        </div>-->
            <!--    </div>-->
            <!--</form>-->
            <div class="row margin-bottom-15 padding-horizontal-15">
                <div class="col-lg-12 background-light-grey padding-vertical-8 padding-horizontal-16">
                    Total number of reports: <b>{{ reports.length }}</b>
                </div>
            </div>

            <table [dataSource]="dataTableSource" mat-table matSort class="mat-elevation-z1 table table-hover width-full">
                <!--
                Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition"
                -->
                <!-- # -->
                <ng-container matColumnDef="reportId">
                    <th mat-header-cell *matHeaderCellDef><i class="left-menu-link-icon fas fa-fw fa-hashtag"></i></th>
                    <td *matCellDef="let report" mat-cell>{{ report.reportId }}</td>
                </ng-container>
                <!-- Name -->
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                    <td *matCellDef="let report" mat-cell>{{ report.name }}</td>
                </ng-container>
                <!-- Target -->
                <ng-container matColumnDef="target">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Target</th>
                    <td *matCellDef="let report" mat-cell>{{ report.for }}</td>
                </ng-container>
                <!-- Owner -->
                <ng-container matColumnDef="owner">
                    <th *matHeaderCellDef mat-sort-header mat-header-cell>Owner</th>
                    <td *matCellDef="let report" mat-cell>
                        <p class="margin-bottom-0">
                            <i class="left-menu-link-icon fas fa-fw fa-user"></i> {{ report.owner?.name || "SYSTEM" }}
                        </p>
                        <p class="margin-bottom-0">
                            <i class="left-menu-link-icon fas fa-fw fa-hashtag"></i> {{ report.owner?.id || "system" }}
                        </p>
                    </td>
                </ng-container>
                <!-- Private -->
                <ng-container matColumnDef="private">
                    <th *matHeaderCellDef mat-sort-header mat-header-cell>Private</th>
                    <td *matCellDef="let report" claass="text-center" mat-cell>
                        <!--
                        <i [ngClass]="{ 'fa-lock': report.private === true, 'fa-unlock' : report.private === false}"
                           class="fab fa-fw font-size-18"
                           matTooltip="{{ report.private === true ? 'Private' : 'Public' }}"></i>
                       -->
                        {{ report.private === true ? 'Private' : 'Public' }}
                    </td>
                </ng-container>
                <!-- Creation Date -->
                <ng-container matColumnDef="since">
                    <th *matHeaderCellDef mat-sort-header mat-header-cell>Created</th>
                    <td *matCellDef="let report" mat-cell>{{ report.creationDate | date:'y.MM.dd HH:mm:ss' }}</td>
                </ng-container>
                <!-- Action -->
                <ng-container matColumnDef="action">
                    <th *matHeaderCellDef mat-sort-header mat-header-cell></th>
                    <td *matCellDef="let report" class="text-right" mat-cell>
                        <a (click)="openReportQueryDialog(report)" *ngIf="isViewReportQueryAllowed(report)"
                           class="row-action font-size-16"
                           href="javascript: void(0);" matTooltip="View query">
                            <i class="fad fa-fw fa-code"></i>
                        </a>
                        <a (click)="download(report)" *ngIf="isDownloadReportAllowed(report)"
                           class="row-action font-size-16"
                           href="javascript: void(0);" matTooltip="Download report">
                            <i class="fad fa-fw fa-arrow-alt-to-bottom"></i>
                        </a>
                        <a (click)="run(report)" *ngIf="isRunReportAllowed(report)"
                           class="row-action font-size-16"
                           href="javascript: void(0);" matTooltip="Run report">
                            <i class="fad fa-fw fa-play-circle"></i>
                        </a>
                    </td>
                </ng-container>

                <tr *matHeaderRowDef="dataTableColumns" mat-header-row></tr>
                <tr *matRowDef="let row; columns: dataTableColumns;" mat-row></tr>
            </table>
        </div>

        <!--
        <div *ngIf="selectedTab === 'new'" style="background-color:white;">
            <form #reportCreateForm="ngForm" class="form-validation">

            </form>
        </div>
        -->
    </div>
</div>
