import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: "bo-cell-tooltip",
    templateUrl: "./cell-tooltip.component.html",
    styleUrls: ["./cell-tooltip.component.scss"]
})
export class CellTooltipComponent {

    @Input("className") className = "";
    @Input("tooltipText") tooltipText = "";
    @Output() onClick: EventEmitter<void> = new EventEmitter();

    onLinkClick() {
        this.onClick.emit();
    }

}
