import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { AuthenticationService } from "./authentication.service";
import { environment } from "src/environments/environment";

export interface Spotlight {
    name: string;
    imagePath: string;
    id: number;
    translationKeys?: {
        title: string;
        description: string;
        link: string;
    };
}

@Injectable({
    providedIn: "root"
})
export class SpotlightService {

    constructor(
        private http: HttpClient,
        private authenticationService: AuthenticationService
    ) {
    }

    getSpotlights(): Observable<Spotlight[]> {
        return this.http.get<Spotlight[]>(
            environment.baseurl.api + "/protected/spotlight/all",
            this.authenticationService.getHeaders()
        ).pipe(map((res: any) => {
            return res.payload.spotlights;
        }));
    }

    getAvailableIdForSpotlight(): Observable<number> {
        return this.http.get<number>(
            environment.baseurl.api + "/protected/spotlight/draftId",
            this.authenticationService.getHeaders()
        ).pipe(map((res: any) => res.payload.id));
    }

    createSpotlight(payload): Observable<any> {
        return this.http.post<any>(
            environment.baseurl.api + "/protected/spotlight/create",
            payload,
            this.authenticationService.getHeaders(),
        );
    }

    getSpotlightById(id: number): Observable<any> {
        return this.http.get<number>(
            environment.baseurl.api + `/protected/spotlight/${id}`,
            this.authenticationService.getHeaders()
        ).pipe(map((res: any) => res.payload.spotlight));
    }

    updateSpotlight(spotlight) {
        return this.http.put<any>(
            environment.baseurl.api + `/protected/spotlight/${spotlight.id}/update`,
            spotlight,
            this.authenticationService.getHeaders(),
        );
    }

    getTranslationKeys(id: number) {
        return {
            title: `dashboard_spotlight_id${id}_title`,
            description: `dashboard_spotlight_id${id}_desc`,
            link: `dashboard_spotlight_id${id}_link`,
        };
    }

    getBase64(file, callback) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function() {
            callback(null, reader.result);

        };
        reader.onerror = function(error) {
            callback(error);
        };
    }

    toDataURL(fileUrl: string) {
        return fetch(fileUrl)
            .then(response => response.blob())
            .then(blob => new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            }));
    }
}
