/**
 * Created by Arindam Bajpayee on  07.03.2022.
 */
import { animate, state, style, transition, trigger } from "@angular/animations";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgProgress } from "@ngx-progressbar/core";
import { Papa } from "ngx-papaparse";
import { ToastrService } from "ngx-toastr";
/* Models */
/* Services */
import { ModalComponent, ModalOptions, Severity } from "../../../modal/modal.component";


@Component({
    selector: "bo-limits-user",
    templateUrl: "./limits-user.component.html",
    styleUrls: [ "./limits-user.component.scss" ],
    animations: [
        trigger("detailExpand", [
            state("collapsed", style({ height: "0px", minHeight: "0", display: "none" })),
            state("expanded", style({ height: "*" })),
            transition("expanded <=> collapsed", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)"))
        ])
    ]
})
export class LimitsUserComponent implements OnInit {
    @Input() limits: any;
    @Input() selectedLimit: number;
    @Output() selectedLimitChange = new EventEmitter<number>();
    levels: any;

    ngOnInit(): void {
        this.levels = Object.keys(this.limits).slice(1);
    }

    /* Returns a boolean if the object is defined or not */
    isLimitsDefined(property: string): boolean {
        if (property) {
            return this.limits["1"].hasOwnProperty(property);
        }
        return false;
    }

    selectTab($event) {
        this.selectedLimit = $event;
        this.selectedLimitChange.next($event);
    }

    sortLimitValues(data = {}) {
        const order = [ "daily", "weekly", "monthly", "yearly" ];
        const entries = Object.entries(data);

        return order.reduce((acc, item) => {
            const obj = entries.find(a => a[ 0 ] === item);

            if (obj) {
                acc.push(obj);
            }

            return acc;
        }, []);
    }
}
