<h6 mat-dialog-title>{{ data.transaction.trxId }}</h6>
<div mat-dialog-content>
    <div class="row">
        <div class="col-lg-12">
            <table class="table table-hover nowrap margin-bottom-0" style="width: 100%;">
                <tbody>
                <tr class="border-none">
                    <th rowspan="1" colspan="1">Type</th>
                    <th rowspan="1" colspan="1">Time</th>
                    <th rowspan="1" colspan="1">Sender</th>
                    <th rowspan="1" colspan="1">Receiver</th>
                    <th rowspan="1" colspan="1">Payment</th>
                </tr>
                <tr>
                    <td class="text-top"><i class="fad fa-comments-dollar fa-lg" matTooltip="Transfer"></i></td>
                    <td class="text-top">
                        <p *ngIf="true" class="margin-bottom-0">
                            {{ data.transaction.sendTime | date:'y.MM.dd HH:mm:ss' }}
                        </p>
                    </td>
                    <td class="text-top">
                        <p class="margin-bottom-0"> {{ data.transaction.history[ 0 ].what.from.name }} </p>
                        <p class="padding-top-5">{{ data.transaction.history[ 0 ].what.from.phone }}</p>
                    </td>
                    <td class="text-top">
                        <p class="margin-bottom-0"> {{ data.transaction.history[ 0 ].what.to.name }} </p>
                        <p class="padding-top-5">{{ data.transaction.history[ 0 ].what.to.phone }}</p>
                    </td>
                    <td class="text-top"> {{ data.transaction.payment.method }} </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<div mat-dialog-actions class="float-right">
    <button color="accent" mat-button (click)="onClickClose()">Close</button>
</div>
