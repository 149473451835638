/**
 * Created by Arindam Bajpayee on 27-04-2017.
 */
import { Component, Input } from "@angular/core";

@Component({
    selector: "bo-user-profile-status",
    template: `
        <div *ngIf="status"
             class="user-profile-status"
             [ngClass]="{
                'approved':status === 'APPROVED',
                'blocked':status === 'BLOCKED',
                'rejected': (status === 'REJECTED' || status === 'REJECTED_PEP'),
                'pending':status === 'PENDING'
             }">
            {{ status }}
        </div>
    `,
    styleUrls: [ "./user-profile-status.component.scss" ]
})
export class UserProfileStatusComponent {
    @Input() status = "";
}
