import { Injectable } from "@angular/core";

import { BehaviorSubject, Observable, Subject } from "rxjs";
import { Partner } from "../../../../models/partner";
import { FeesPayerType, FeesVariablePercentOfType, FeesVariableType } from "../../../../models/fee";
import { CheckboxUI } from "../interfaces/checkbox-ui.interface";
import { FeatureSettingsUI } from "../interfaces/feature-settings-ui.insterface";
import { SpotlightView } from "../interfaces/spotlight-view.interface";
import {
    AdditionalFunctions,
    DashboardSettings, Limits, ScanAndConfirm, SCARequired, ShowHide,
    TabBarContact,
    TabBarDashboard,
    TabBarMonitor,
    TabBarProfile, TCPolices,
    Transaction,
    TutorialCustomization, WithdrawGuaranteeMessage,
    AuthorizePartnerProvidedData, UserCapture, TCPolicesShop, ThemeSwitcher
} from "../enums/configuration.enum";

@Injectable({
    providedIn: "root"
})
export class PartnerDetailsStateService {
    private activeTabName$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
    public activeTabNamePublic$: Observable<string> = this.activeTabName$.asObservable();

    private clearFileUploadEvent$ = new Subject();
    public readonly clearFileUpload$ = this.clearFileUploadEvent$.asObservable();

    public spotlightsListSubject: BehaviorSubject<SpotlightView[]> = new BehaviorSubject<SpotlightView[]>([]);
    public spotlightsList$: Observable<SpotlightView[]> = this.spotlightsListSubject.asObservable();

    defaultImage = "https://res.cloudinary.com/sonect/image/upload/v1585184116/dev/partners/IMG_sonect-I_1585184115731.png";

    getActiveTabName(): string {
        return this.activeTabName$.getValue();
    }

    setActiveTabName(name: string): void {
        this.activeTabName$.next(name);
    }

    getInitialPartnerData(): Partner {
        return {
            id: "",
            name: "",
            address: {
                line1: "",
                line2: "",
                city: "",
                state: "",
                zip: "",
                country: "",
                countryCode: "",
                formatted: ""
            },
            auth: {
                accessToken: "",
                apiVersion: "",
                authorizationCode: "",
                clientId: "",
                clientSecret: "",
                external: {
                    bankId: "",
                    baseUrl: "",
                    clientId: "",
                    clientSecret: "",
                    path: {
                        login: "",
                        token: ""
                    },
                    realm: "",
                    redirectUrl: "",
                    scope: ""
                },
                hmacKey: "",
                refreshToken: "",
                tokenCreationDate: Date.now(),
                tokenExpiry: 86400,
                tokenType: ""
            },
            balance: 0,
            categories: [],
            commission: {
                type: "percent",
                sonect: 60,
                partner: 0,
                merchant: 40
            },
            contact: {
                name: "",
                phone: "",
                email: ""
            },
            configuration: {
                sdk: {
                    features: [],
                    denominations: [],
                    mapCategories: [],
                    sliderStepSize: 0,
                    tutorials: [],
                    customerTutorials: [],
                    merchantTutorials: [],
                    limits: null,
                    contact: {
                        chat: "",
                        email: "",
                        phone: ""
                    }
                }
            },
            email: "",
            fees: {
                fixed: 0,
                variable: {
                    value: 0,
                    type: FeesVariableType.FIXED,
                    of: FeesVariablePercentOfType.FIXED
                },
                payer: FeesPayerType.PARTNER,
                trial: false
            },
            partnerKyc: "idenfy",
            pricing: {
                fees: [ {
                    fixed: 0,
                    variable: {
                        value: 0,
                        type: FeesVariableType.FIXED,
                        of: FeesVariablePercentOfType.NONE
                    },
                    payer: FeesPayerType.CUSTOMER,
                    trial: false,
                    min: 0
                } ],
                tags: [],
                trial: false
            },
            onboardedBy: "sonect",
            payment: {
                iban: "",
                ibanSonect: "",
                ibanVerificationRequired: false,
                payee: "none",
                report: {
                    delivery: "email",
                    format: ["csv"],
                    segregateMatchedTransactions: false,
                    triggerReconciliationInXML: false
                },
                schedule: {
                    commission: "none",
                    payout: {
                        day: [],
                        time: []
                    },
                }
            },
            registrationDate: Date.now(),
            timezone: {
                id: "",
                name: ""
            }
        };
    }

    resetAddress(obj: Partner): Partner {
        const address = {
            line1: "",
            line2: "",
            city: "",
            state: "",
            zip: "",
            country: "",
            countryCode: "",
            formatted: ""
        };

        return {
            ...obj,
            address
        };
    }

    listOfEURegions(): string[] {
        return [
            "AD", "AL", "AT",
            "BA", "BE", "BG", "BY",
            "CY", "CZ",
            "DK", "DE",
            "EE", "ES",
            "FI", "FO", "FR",
            "GB", "GL", "GR",
            "HR", "HU",
            "IE", "IN", "IS", "IT",
            "LI", "LT", "LU", "LV",
            "MC", "MD", "ME", "MK", "MT",
            "NL", "NO",
            "PL", "PT",
            "RO", "RS", "RU",
            "SE", "SI", "SK",
            "TR",
            "UA",
            "VA",
            "XK"
        ];
    }

    getCustomerTutorialsMock(): { imagePath: string, description: string }[] {
        return [
            { imagePath: this.defaultImage, description: "no description provided" },
            { imagePath: this.defaultImage, description: "no description provided" },
            { imagePath: this.defaultImage, description: "no description provided" }
        ];
    }

    getCustomerTutorialsOptions(): CheckboxUI[] {
        return [
            { name: TutorialCustomization.TUTORIAL_USER_INTRO, value: TutorialCustomization.TUTORIAL_USER_INTRO, status: false },
            { name: TutorialCustomization.TUTORIAL_USER_PAYMENT_METHOD, value: TutorialCustomization.TUTORIAL_USER_PAYMENT_METHOD, status: false },
            { name: TutorialCustomization.TUTORIAL_USER_TOPUP, value: TutorialCustomization.TUTORIAL_USER_TOPUP, status: false },
            { name: TutorialCustomization.TUTORIAL_USER_WITHDRAWAL, value: TutorialCustomization.TUTORIAL_USER_WITHDRAWAL, status: false },
        ];
    }

    getUserTutorialOptions(): CheckboxUI[] {
        return [
            // { name: TutorialCustomization.TUTORIAL_USER_INTRO, value: TutorialCustomization.TUTORIAL_USER_INTRO, status: false },
            // { name: TutorialCustomization.TUTORIAL_USER_PAYMENT_METHOD, value: TutorialCustomization.TUTORIAL_USER_PAYMENT_METHOD, status: false },
            // { name: TutorialCustomization.TUTORIAL_USER_TOPUP, value: TutorialCustomization.TUTORIAL_USER_TOPUP, status: false },
            // { name: TutorialCustomization.TUTORIAL_USER_WITHDRAWAL, value: TutorialCustomization.TUTORIAL_USER_WITHDRAWAL, status: false },
            { name: TutorialCustomization.TUTORIAL_SHOP_INTRO, value: TutorialCustomization.TUTORIAL_SHOP_INTRO, status: false }
        ];
    }

    getTabBarDashboard(): FeatureSettingsUI[] {
        return [
            { name: TabBarDashboard.DASHBOARD_EXPLORE, value: "DASHBOARD_EXPLORE", checked: false, disabled: false, hint: "" },
            { name: TabBarDashboard.DASHBOARD_WITHDRAW, value: "DASHBOARD_WITHDRAW", checked: false, disabled: false, hint: "" },
            { name: TabBarDashboard.DASHBOARD_SEND, value: "DASHBOARD_SEND", checked: false, disabled: false, hint: "Enable the function of sending barcode to other person for withdrawing money" },
            { name: TabBarDashboard.DASHBOARD_BACK_BUTTON, value: "DASHBOARD_BACK_BUTTON", checked: false, disabled: false, hint: "Show/Hide the dashboard back button" },
            { name: TabBarDashboard.DASHBOARD_TOPUP, value: "DASHBOARD_TOPUP", checked: false, disabled: false, hint: "" },
            { name: TabBarDashboard.DASHBOARD_SPOTLIGHT, value: "DASHBOARD_SPOTLIGHT", checked: false, disabled: false, hint: "Enable/Show the spotlight information area" }
        ];
    }

    getTabBarProfile(): FeatureSettingsUI[] {
        return [
            { name: TabBarProfile.DASHBOARD_PROFILE, value: "DASHBOARD_PROFILE", checked: false, disabled: false }
        ];
    }

    getTabBarMonitor(): FeatureSettingsUI[] {
        return [
            { name: TabBarMonitor.DASHBOARD_MONITOR, value: "DASHBOARD_MONITOR", checked: false, disabled: false, hint: "Enable button to transaction history screen" }
        ];
    }

    getTabBarContact(): FeatureSettingsUI[] {
        return [
            { name: TabBarContact.CONTACT_US, value: "CONTACT_US", checked: false, disabled: false, hint: "FAQ, Email, Phone" },
            { name: TabBarContact.CONTACT_US_PARTNER, value: "CONTACT_US_PARTNER", checked: false, disabled: false, hint: "Link to Consumer Customer support (L1)" }
        ];
    }

    getTabBarMonitorTransactionsLimits(): FeatureSettingsUI[] {
        return [
            { name: TabBarMonitor.DASHBOARD_MONITOR_TRANSACTION, value: "DASHBOARD_MONITOR_TRANSACTION", checked: false, disabled: false, hint: "History of all transactions" },
            { name: TabBarMonitor.DASHBOARD_MONITOR_LIMITS, value: "DASHBOARD_MONITOR_LIMITS", checked: false, disabled: false, hint: "Information about remaining limit" }
        ];
    }

    getTabBarMonitorLimitsWithdraw(): FeatureSettingsUI[] {
        return [
            { name: TabBarMonitor.LIMIT_AMOUNT_WITHDRAW_DAILY, value: "LIMIT_AMOUNT_WITHDRAW_DAILY", checked: false, disabled: false },
            { name: TabBarMonitor.LIMIT_AMOUNT_WITHDRAW_MONTHLY, value: "LIMIT_AMOUNT_WITHDRAW_MONTHLY", checked: false, disabled: false },
            { name: TabBarMonitor.LIMIT_AMOUNT_WITHDRAW_YEARLY, value: "LIMIT_AMOUNT_WITHDRAW_YEARLY", checked: false, disabled: false }
        ];
    }

    getTabBarMonitorLimitsSend(): FeatureSettingsUI[] {
        return [
            // { name: TabBarMonitor.LIMIT_AMOUNT_SEND_DAILY, value: "LIMIT_AMOUNT_SEND_DAILY", checked: false, disabled: false },
            { name: TabBarMonitor.LIMIT_AMOUNT_SEND_MONTHLY, value: "LIMIT_AMOUNT_SEND_MONTHLY", checked: false, disabled: false },
            { name: TabBarMonitor.LIMIT_AMOUNT_SEND_YEARLY, value: "LIMIT_AMOUNT_SEND_YEARLY", checked: false, disabled: false }
        ];
    }

    getTabBarMonitorLimitsReceive(): FeatureSettingsUI[] {
        return [
            // { name: TabBarMonitor.LIMIT_AMOUNT_RECEIVE_DAILY, value: "LIMIT_AMOUNT_RECEIVE_DAILY", checked: false, disabled: false },
            { name: TabBarMonitor.LIMIT_AMOUNT_RECEIVE_MONTHLY, value: "LIMIT_AMOUNT_RECEIVE_MONTHLY", checked: false, disabled: false },
            { name: TabBarMonitor.LIMIT_AMOUNT_RECEIVE_YEARLY, value: "LIMIT_AMOUNT_RECEIVE_YEARLY", checked: false, disabled: false }
        ];
    }

    getTabBarMonitorLimitsAmountTransaction(): FeatureSettingsUI[] {
        return [
            { name: TabBarMonitor.LIMIT_AMOUNT_TRANSACTION_DAILY, value: "LIMIT_AMOUNT_TRANSACTION_DAILY", checked: false, disabled: false },
            { name: TabBarMonitor.LIMIT_AMOUNT_TRANSACTION_WEEKLY, value: "LIMIT_AMOUNT_TRANSACTION_WEEKLY", checked: false, disabled: false },
            { name: TabBarMonitor.LIMIT_AMOUNT_TRANSACTION_MONTHLY, value: "LIMIT_AMOUNT_TRANSACTION_MONTHLY", checked: false, disabled: false },
            { name: TabBarMonitor.LIMIT_AMOUNT_TRANSACTION_YEARLY, value: "LIMIT_AMOUNT_TRANSACTION_YEARLY", checked: false, disabled: false }
        ];
    }

    getTransactionCancelButton(): FeatureSettingsUI[] {
        return [
            { name: Transaction.TRANSACTION_CANCEL_BUTTON, value: "TRANSACTION_CANCEL_BUTTON", checked: false, disabled: false, hint: "The ability to cancel the transaction" }
        ];
    }

    getTCPolices(): FeatureSettingsUI[] {
        return [
            { name: TCPolices.CAPTURE_TC_PP, value: "CAPTURE_TC_PP", checked: false, disabled: false, hint: "Enable checkbox to confirm T&C+PP" },
            { name: TCPolices.CAPTURE_TC_PP_CUSTOM, value: "CAPTURE_TC_PP_CUSTOM", checked: false, disabled: false, hint: "Enable custom T&C/PP for this partner, Disable = Sonect default " }
        ];
    }

    getTCPolicesShop(): FeatureSettingsUI[] {
        return [
            { name: TCPolicesShop.CAPTURE_TC_PP_SHOP, value: "CAPTURE_TC_PP_SHOP", checked: false, disabled: false, hint: "Enable checkbox to confirm T&C+PP" },
            { name: TCPolicesShop.CAPTURE_TC_PP_SHOP_CUSTOM, value: "CAPTURE_TC_PP_SHOP_CUSTOM", checked: false, disabled: false, hint: "Enable custom T&C/PP for this partner, Disable = Sonect default " }
        ];
    }

    getDashboardSettings(): FeatureSettingsUI[] {
        return [
            { name: DashboardSettings.DASHBOARD_SETTINGS_SHOP, value: "DASHBOARD_SETTINGS_SHOP", checked: false, disabled: false, hint: "Shows Settings card on dashboard" },
            { name: DashboardSettings.DASHBOARD_MONITOR_TRANSACTION_HISTORY_SHOP, value: "DASHBOARD_MONITOR_TRANSACTION_HISTORY_SHOP", checked: false, disabled: false, hint: "Shows Transactions card on dashboard" },
            { name: DashboardSettings.SHOW_ON_THE_GO_SHOP, value: "SHOW_ON_THE_GO_SHOP", checked: false, disabled: false },
        ];
    }

    getAdditionalFunctions(): FeatureSettingsUI[] {
        return [
            { name: AdditionalFunctions.ACTIVATE_WORKERS_SHOP, value: "ACTIVATE_WORKERS_SHOP", checked: false, disabled: false },
        ];
    }

    getScanAndConfirm(): FeatureSettingsUI[] {
        return [
            { name: ScanAndConfirm.SCAN_AND_CONFIRM_SHOP, value: "SCAN_AND_CONFIRM_SHOP", checked: false, disabled: false },
        ];
    }

    getShowHide(): FeatureSettingsUI[] {
        return [
            { name: ShowHide.SETTINGS_ACCOUNTS_SHOP, value: "SETTINGS_ACCOUNTS_SHOP", checked: false, disabled: false, hint: "Screen that includes Beneficiary details and users email" },
            { name: ShowHide.SETTINGS_EDIT_SHOP, value: "SETTINGS_EDIT_SHOP", checked: false, disabled: false },
            { name: ShowHide.SETTINGS_SHOP_INFO_SHOP, value: "SETTINGS_SHOP_INFO_SHOP", checked: false, disabled: false },
            { name: ShowHide.PAYOUT_DETAILS, value: "PAYOUT_DETAILS", checked: false, disabled: false },
            { name: ShowHide.SHOW_TRANSACTIONS_SHOP, value: "SHOW_TRANSACTIONS_SHOP", checked: false, disabled: false, hint: "History of cash handouts" },
            { name: ShowHide.SHOW_PAYOUTS_SHOP, value: "SHOW_PAYOUTS_SHOP", checked: false, disabled: false, hint: "History of  payouts to the shop" }
        ];
    }

    getLimit(): FeatureSettingsUI[] {
        return [
            { name: Limits.LIMITS_PER_TIME_RANGE, value: "LIMITS_PER_TIME_RANGE", checked: false, disabled: false },
        ];
    }

    getWithdrawGuaranteeMessage(): FeatureSettingsUI[] {
        return [
            { name: WithdrawGuaranteeMessage.INFO_MESSAGES_WITHDRAW_SCREEN, value: "INFO_MESSAGES_WITHDRAW_SCREEN", hint: "Show / Hide Info messages on withdraw screen", checked: false, disabled: false },
        ];
    }

    getSCARequired(): FeatureSettingsUI[] {
        return [
            { name: SCARequired.SCA_REQUIRED, value: "SCA_REQUIRED", checked: false, disabled: false },
            { name: SCARequired.SCA_PASSCODE_REQUIRED, value: "SCA_PASSCODE_REQUIRED", checked: false, disabled: false },
        ];
    }

    getThemeSwitcher(): FeatureSettingsUI[] {
        return [
            { name: ThemeSwitcher.THEME_SWITCHER, value: "THEME_SWITCHER", checked: false, disabled: false },
        ];
    }

    getAuthorizePartnerProvidedData(): FeatureSettingsUI[] {
        return [
            // TODO: be aware!! this done temporally according to SO-13384
            { name: AuthorizePartnerProvidedData.APPLY_PARTNER_FEES, value: "APPLY_PARTNER_FEES", checked: true, disabled: true },
            { name: AuthorizePartnerProvidedData.APPLY_PARTNER_TAGS, value: "APPLY_PARTNER_TAGS", checked: true, disabled: true },
        ];
    }

    getUserCapture(): FeatureSettingsUI[] {
        return [
            { name: UserCapture.CAPTURE_USER_IBAN, value: "CAPTURE_USER_IBAN", checked: false, disabled: false },
            { name: UserCapture.CAPTURE_USER_EMAIL, value: "CAPTURE_USER_EMAIL", checked: false, disabled: false },
        ];
    }

    detectClearFileEvent(): void {
        this.clearFileUploadEvent$.next();
    }
}
