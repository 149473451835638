<div *ngIf="(payoutsStateService.filteredPayouts | async)?.length > 0" class="payouts-list">
    <div class="payouts-list-filter">
        <mat-form-field>
            <mat-label>Filter:</mat-label>
            <mat-select (selectionChange)="onFilterPayouts($event)">
                <mat-option [value]="ALL_YEARS_KEY">All</mat-option>
                <mat-option *ngFor="let year of payoutsStateService.availableYears | async" [value]="year">{{year}}</mat-option>
            </mat-select>
        </mat-form-field>
        <button (click)="downloadReportPayout()"  class="btn btn-sm btn-secondary btn-small margin-left-15">
            <i class="far fa-file-download"></i> Download all
        </button>
    </div>
    <ng-container *ngFor="let payout of payoutsStateService.filteredPayouts | async">
        <ng-template #popContent>
            <div *ngFor="let key of getPayoutStatuses(payout)" class="payout-status {{key}}">
                {{key}}: {{payout.statuses[key]}}
            </div>
        </ng-template>
        <div class="steps-inline margin-inline vertical-align-middle" (click)="onPayoutClicked(payout)">
            <div class="step-block wide sonect-blue-bg color-white">
                {{ payout.date | date:'y MMM' }}
            </div>
            <div class="step-block wide font-weight-600 color-white" [ngClass]="{'accepted': payout.mainStatus === PAYOUT_STATUSES?.accepted}" [title]="payout.amount + ' ' +payout.currency">
                {{ payout.amount }} {{ payout.currency }}
            </div>
            <div *ngIf="payout.showStatuses" class="statuses-bage" [ngClass]="payout.mainStatus" placement="top" [ngbPopover]="popContent"
                 triggers="mouseenter:mouseleave"></div>
        </div>
    </ng-container>
</div>
