/**
 * Created by Arindam Bajpayee on 27-04-2017.
 */
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgProgress } from "@ngx-progressbar/core";
import { ToastrService } from "ngx-toastr";
/* Services */
import { AccountService } from "../../../services/account.service";
import { AuthenticationService } from "../../../services/authentication.service";
/* Components */
import { ModalAction, ModalComponent, ModalOptions, Severity } from "../../modal/modal.component";

@Component({
    selector: "bo-profile",
    templateUrl: "./profile.component.html",
    styleUrls: [ "./profile.component.css" ]
})
export class ProfileComponent implements OnInit {
    public account: any = {
        name: "",
        user: "",
        // role:"",
        email: ""
    };

    public password: any = {
        confirm: "",
        current: "",
        new: ""
    };

    submitted = false;
    editable = false;
    hideCurrent = true;
    hideNew = true;
    hideConfirm = true;
    isLoading = false;

    constructor(private router: Router,
                public activeModal: NgbActiveModal,
                private modalService: NgbModal,
                private progressBar: NgProgress,
                private authenticationService: AuthenticationService,
                private accountService: AccountService,
                private toastr: ToastrService
    ) {
    }

    ngOnInit(): void {
        // throw new Error('Method not implemented.');
        const account = this.getCurrentUser();
        this.account.name = account.name;
        this.account.user = account.user;
        this.account.email = account.email;
    }

    getCurrentUser() {
        return this.authenticationService.getCurrentUser();
    }

    cancel() {
        this.password.confirm = "";
        this.password.current = "";
        this.password.new = "";
        this.editable = false;
        this.submitted = false;
    }


    /* Action: Reset password */
    resetPassword($event): void {
        const modalRef = this.modalService.open(ModalComponent);
        const parameters = {
            parentComponent: this,
            password: this.password
        };

        modalRef.componentInstance.modalOptions =
            new ModalOptions(
                Severity.WARNING,
                "Reset password",
                "Are you sure you want to proceed?",
                [
                    new ModalAction("Confirm", parameters, function($event, parameters) {
                        const parentRef = parameters.parentComponent;
                        parentRef.progressBar.ref().start();

                        parentRef.accountService.resetPassword().subscribe(response => {
                            const data: any = { ...response };
                            parentRef.progressBar.ref().complete();
                            const modalRef = parentRef.modalService.open(ModalComponent);
                            modalRef.componentInstance.modalOptions = new ModalOptions(Severity.SUCCESS, "Success", data.message, null);
                            parentRef.cancel();
                        }, error => {
                            parentRef.handleError(error, true);
                            parentRef.progressBar.ref().complete();
                        });
                    })
                ]);
    }

    /* Action: Save */
    save($event, property: string): void {
        this.submitted = true;
        if (this.password.new !== "" && (this.password.new === this.password.confirm)) {
            if (property === "changePassword") {
                this.progressBar.ref().start();
                this.isLoading = true;
                this.accountService.changePassword(this.password).subscribe(response => {
                    const data: any = { ...response };
                    this.progressBar.ref().complete();
                    this.isLoading = false;
                    this.cancel();
                    const modalRef = this.modalService.open(ModalComponent);
                    modalRef.componentInstance.modalOptions = new ModalOptions(Severity.SUCCESS, "Success", data.message, null);

                }, error => {
                    this.handleError(error, true);
                    this.progressBar.ref().complete();
                    this.isLoading = false;
                });
            }
        }
    }

    /* Error Handler */
    private handleError(error: any, showModal: boolean) {
        if (error.status === 401 && error.statusText === "Unauthorized") {
            this.toastr.error("Unauthorized User", "User logout");
            this.authenticationService.logout();
            this.router.navigate([ "/login" ]);
        } else {
            let response = error.error;
            if (typeof response === "string") {
                response = JSON.parse(response);
            }
            this.toastr.error(response.message, response.result);
            if (showModal) {
                const modalRef = this.modalService.open(ModalComponent);
                const errorMessage = response.result ? response.result : error.statusText;
                modalRef.componentInstance.modalOptions = new ModalOptions(Severity.ERROR, errorMessage, response.message, null);
            }
        }
    }
}
