/**
 * Created by Arindam Bajpayee on 27-04-2017.
 */
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { LoginComponent } from "./components/login/login.component";

const routes: Routes = [
    // { path: 'admin', canActivate: [ AuthGuard ], loadChildren: './components/admin/admin.module#AdminModule' },
    { path: "login", component: LoginComponent },
    { path: "", redirectTo: "/login", pathMatch: "full" },
    { path: "**", redirectTo: "/login" }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule {
}
