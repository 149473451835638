import { Component, OnInit } from '@angular/core';

import { environment } from "../../../../../environments/environment";

@Component({
  selector: 'bo-environment-name',
  templateUrl: './environment-name.component.html',
  styleUrls: ['./environment-name.component.scss']
})
export class EnvironmentNameComponent {
  public isProd = environment.production;
  public logoImagePath = environment.name === 'DEV' ? '/assets/img/logo/dev_env_logo.png' : '/assets/img/logo/test_env_logo.png';
}
