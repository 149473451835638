<div class="page-title">
    <span>ATM Limit Alerts</span>
    <div class="page-title-actions">
        <bo-domain-selector showEEA="true" (out)="selectedCountry($event)"></bo-domain-selector>
    </div>
</div>
<div class="panel panel-with-borders margin-top-10 margin-bottom-0 clear-fix">
    <div class="panel-body">
        <div class="nav-tabs-horizontal margin-bottom-20">
            <ul class="nav nav-tabs">
                <li class="nav-item">
                    <a (click)="selectTab($event, 'list')" [ngClass]="{'active': selectedTab === 'list'}" class="nav-link"
                       href="javascript: void(0);">List</a>
                </li>
                <!--
                <li class="nav-item">
                    <a (click)="selectTab($event, 'new')" [ngClass]="{'active': selectedTab === 'new'}" class="nav-link"
                       href="javascript: void(0);">Create new</a>
                </li>
                -->
            </ul>
        </div>

        <div *ngIf="selectedTab === 'list'" class="" id="list_audits">
            <!-- Filter -->
            <!--<form (ngSubmit)="searchUserProfiles()">-->
            <!--    <div class="row margin-bottom-15">-->
            <!--        <div class="col">-->
            <!--            <domain-selector (out)="selectedCountry($event)"></domain-selector>-->
            <!--            <button mat-icon-button><i class="fas fa-search"></i></button>-->
            <!--        </div>-->
            <!--    </div>-->
            <!--</form>-->
            <div class="row margin-bottom-15 padding-horizontal-15">
                <div class="col-lg-12 background-light-grey padding-vertical-8 padding-horizontal-16">
                    Total number of audits: <b>{{ audits.length }}</b>
                </div>
            </div>

            <table [dataSource]="dataTableSource" mat-table matSort class="mat-elevation-z1 table table-hover width-full">
                <!--
                Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition"
                -->
                <!-- Date -->
                <ng-container matColumnDef="date">
                    <th *matHeaderCellDef mat-sort-header mat-header-cell>Date</th>
                    <td *matCellDef="let audit" mat-cell>{{ audit.date | date:'y.MM.dd HH:mm:ss' }}</td>
                </ng-container>

                <!-- Type -->
                <ng-container matColumnDef="type">
                    <th *matHeaderCellDef mat-sort-header mat-header-cell>Type</th>
                    <td *matCellDef="let audit" mat-cell>{{ audit.type }}</td>
                </ng-container>

                <!-- Name -->
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                    <td *matCellDef="let audit" mat-cell>
                        {{ audit.name }}
                        <p *ngIf="audit.parent" class="margin-bottom-0">
                            <i class="left-menu-link-icon fas fa-fw fa-hashtag"></i> {{ audit.parent }}
                        </p>
                        <p *ngIf="audit.referenceId" class="margin-bottom-0">
                            <i class="left-menu-link-icon fas fa-fw fa-hashtag"></i> {{ audit.referenceId }}
                        </p>
                    </td>
                </ng-container>

                <!-- Time -->
                <ng-container matColumnDef="time">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Time</th>
                    <td *matCellDef="let audit" mat-cell>{{ audit.limitTime }}</td>
                </ng-container>

                <!-- Amount -->
                <ng-container matColumnDef="amount">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-right">Amount</th>
                    <td *matCellDef="let audit" mat-cell class="text-right">{{ audit.limitAmount }} {{ audit.currency }}</td>
                </ng-container>

                <!-- Action
                <ng-container matColumnDef="action">
                    <th *matHeaderCellDef mat-sort-header mat-header-cell></th>
                    <td *matCellDef="let audit" class="text-right" mat-cell>

                    </td>
                </ng-container>
                -->

                <tr *matHeaderRowDef="dataTableColumns" mat-header-row></tr>
                <tr *matRowDef="let row; columns: dataTableColumns;" mat-row></tr>
            </table>
        </div>

        <!--
        <div *ngIf="selectedTab === 'new'" style="background-color:white;">
            <form #auditCreateForm="ngForm" class="form-validation">

            </form>
        </div>
        -->
    </div>
</div>
