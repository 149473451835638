/**
 * Created by Arindam Bajpayee on 27-04-2017.
 */
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgProgress } from "@ngx-progressbar/core";
import { ToastrService } from "ngx-toastr";
import { of, Subscription } from "rxjs";
import { delay, mergeMap, repeat, tap } from "rxjs/operators";

import { ModalComponent, ModalOptions, Severity } from "../modal/modal.component";
import { AuthenticationService } from "../../services/authentication.service";
import { environment } from "../../../environments/environment";

@Component({
    selector: "bo-login",
    templateUrl: "./login.component.html",
    styleUrls: [ "./login.component.css" ]
})
export class LoginComponent implements OnInit, OnDestroy {
    username = "";
    password = "";
    code = "";
    is2FAStarted = false;
    isLoading = false;
    authSubscription: Subscription;
    token = "";

    constructor(private router: Router,
                private modalService: NgbModal,
                private progressBar: NgProgress,
                private toastr: ToastrService,
                private authenticationService: AuthenticationService) {
    }

    ngOnInit(): void {
        if (this.authenticationService.isUserLoggedIn()) {
            this.router.navigate([ "/admin/dashboard" ]);
        }
    }

    ngOnDestroy() {
        if (this.authSubscription) {
            this.authSubscription.unsubscribe();
        }
    }

    /* Call the service */
    verify(): Subscription {
        if (this.authSubscription) {
            return;
        }

        const handleResponse = response => {
            const { status } = response;
            if (status === "approved") {
                localStorage.setItem("currentToken", JSON.stringify(response.token));
                localStorage.setItem("currentUser", JSON.stringify(response.details));
                localStorage.setItem("limitsMerchant", JSON.stringify(response.details.limitsForMerchant));
                localStorage.setItem("limitsUser", JSON.stringify(response.details.limitsForUser));
                this.authenticationService.setUserLoggedIn(true);
                return true;
            }
        };

        const applyResponse = (response) => {
            const { status } = response;
            if (status === "pending") {
                return;
            }
            this.toastr.clear();
            this.progressBar.ref().complete();
            this.isLoading = false;

            if (this.authSubscription) {
                this.authSubscription.unsubscribe();
            }

            if (status === "approved") {
                this.router.navigate([ "/admin/dashboard" ]);
            } else {
                const modalRef = this.modalService.open(ModalComponent);
                modalRef.componentInstance.modalOptions =
                    new ModalOptions(Severity.ERROR, "Error", "The login attempt failed! Make sure to approve the request on Authy.", null);
            }
        };

        return of({}).pipe(
            mergeMap(_ => this.authenticationService.verifyRequest(this.token, this.code)),
            tap(handleResponse),
            tap(applyResponse),
            delay(2000),
            repeat()
        ).subscribe();
    }

    login(): void {
        this.isLoading = true;
        this.progressBar.ref().start();
        const message = "Please approve the login request on your Authy app!";
        this.toastr.info(message, "Waiting for 2FA...");

        this.authenticationService.login(this.username, this.password)
            .subscribe(
                (data) => {
                    this.is2FAStarted = true;
                    this.token = data.token;
                    this.authSubscription = this.verify();
                },
                error => {
                    this.toastr.clear();
                    this.progressBar.ref().complete();
                    this.isLoading = false;
                    const modalRef = this.modalService.open(ModalComponent);
                    if (error.status == 0) { // or whatever condition you like to put
                        modalRef.componentInstance.modalOptions = new ModalOptions(Severity.ERROR, "Network error", "Internet connection failed!", null);
                    } else {
                        modalRef.componentInstance.modalOptions = new ModalOptions(Severity.ERROR, "Error", "The username/password combination is not correct!", null);
                    }
                });
    }

    getEnvironment() {
        return {
            name: environment.name,
            production: environment.production
        };
    }
}
