/**
 * Created by Arindam Bajpayee on 27-04-2017.
 */
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgProgress } from "@ngx-progressbar/core";
import { Papa } from "ngx-papaparse";
import { ToastrService } from "ngx-toastr";
/* Models */
/* Services */
import { AuthenticationService } from "../../../services/authentication.service";
import { LimitService } from "../../../services/limit.service";
import { PartnerService } from "../../../services/partner.service";
import { ModalComponent, ModalOptions, Severity } from "../../modal/modal.component";
import { animate, state, style, transition, trigger } from "@angular/animations";

@Component({
    selector: "bo-limits",
    templateUrl: "./limits.component.html",
    styleUrls: [ "./limits.component.scss" ],
    animations: [
        trigger("detailExpand", [
            state("collapsed", style({ height: "0px", minHeight: "0", display: "none" })),
            state("expanded", style({ height: "*" })),
            transition("expanded <=> collapsed", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)"))
        ])
    ]
})
export class LimitsComponent implements OnInit {
    amount: 0;
    // collectionSize = 100;
    country = {
        code: "CH",
        currency: "CHF",
        name: "Switzerland"
    };
    currency = "CHF";
    filter = {
        country: "",
        type: "User"
    };
    levels: any;
    limits: any;
    limitsAll: any;
    limitsPartners: any;
    merchantPartnerLimits: Array<Object>;
    loaded = false;
    selectedLimit = 0;

    constructor(private router: Router,
                private modalService: NgbModal,
                private papaParse: Papa,
                private progressBar: NgProgress,
                private authenticationService: AuthenticationService,
                private limitService: LimitService,
                private partnerService: PartnerService,
                private toastr: ToastrService) {
    }

    ngOnInit(): void {
        if (!this.authenticationService.isUserLoggedIn()) {
            this.router.navigate([ "/login" ]);
            return;
        }
        (async () => {
            await this.callService();
        })();
    }

    /* Returns a boolean if the object is defined or not */
    isDefined(object: any): boolean {
        return typeof object !== "undefined" && object !== null;
    }

    /* Check if the selected country is a part of EU Region and return a boolean */
    isEURegion(): boolean {
        const cca2_eu = [
            "AD", "AL", "AT",
            "BA", "BE", "BG", "BY",
            "CY", "CZ",
            "DK", "DE",
            "EE", "ES",
            "FI", "FO", "FR",
            "GB", "GL", "GR",
            "HR", "HU",
            "IE", "IN", "IS", "IT",
            "LI", "LT", "LU", "LV",
            "MC", "MD", "ME", "MK", "MT",
            "NL", "NO",
            "PL", "PT",
            "RO", "RS", "RU",
            "SE", "SI", "SK",
            "TR",
            "UA",
            "VA",
            "XK"
        ];
        return cca2_eu.indexOf(this.country.code) > -1;
    }

    // Get the domain, country and currency info
    async selectedCountry(data: any) {
        this.country = data;
        await this.callService();
    }

    async selectLimitsType($event, type: string) {
        this.filter.type = type;
        await this.callService();
    }

    /* PRIVATE METHODS */

    /* Call the service */
    private async callService() {
        this.progressBar.ref().start();
        this.loaded = false;

        try {
            let response: any = await this.limitService.getLimits().toPromise();
            this.limitsAll = response.payload.limits;
            localStorage.setItem("limitsMerchant", JSON.stringify(this.limitsAll.merchant));
            localStorage.setItem("limitsUser", JSON.stringify(this.limitsAll.user));

            response = await this.partnerService.getLimitsByCountryCode(this.country.code).toPromise();
            this.limitsPartners = response.payload.limits;

            if (this.filter.type === "User") {
                const countryOrDomain = Object.keys(this.limitsAll.user).includes(this.country.code) ? this.country.code : this.getDomainFromCountryCode();
                this.limits = this.limitsAll.user[ countryOrDomain ];
                this.levels = Object.keys(this.limits).slice(1);
            } else {
                const merchantLimitsResponse: any = await this.partnerService.getMerchantLimitsBy(this.country.code).toPromise();
                this.merchantPartnerLimits = merchantLimitsResponse.payload.limits;
                const countryOrDomain = Object.keys(this.limitsAll.merchant).includes(this.country.code) ? this.country.code : this.getDomainFromCountryCode();
                this.limits = this.limitsAll.merchant[ countryOrDomain ];
                this.levels = ["1"]; // ATMs have only 1 level for now
            }
            this.loaded = true;
            this.progressBar.ref().complete();
        } catch (error) {
            this.handleError(error, false);
            this.progressBar.ref().complete();
        }
        /*
        this.limitService.getLimits().subscribe(response => {
            const data: any = { ...response };
            this.limitsAll = data.payload.limits;
            localStorage.setItem("limitsMerchant", JSON.stringify(this.limitsAll.merchant));
            localStorage.setItem("limitsUser", JSON.stringify(this.limitsAll.user));

            if (this.filter.type === "User") {
                this.limits = this.limitsAll.user[ countryOrDomain ];
                this.levels = Object.keys(this.limits).slice(1);
            } else {
                this.limits = this.limitsAll.merchant[ countryOrDomain ];
            }
            this.loaded = true;
            this.progressBar.ref().complete();
        }, error => {
            this.handleError(error, false);
            this.progressBar.ref().complete();
        });
        */
    }

    private getDomainFromCountryCode() {
        if (this.isEURegion()) {
            return "EU";
        } else if (this.country.code === "MX") {
            return "LATAM";
        } else {
            if (this.country.code === "CH") {
                return "CH";
            }
        }
        return null;
    }

    sortLimitValues(data = {}) {
        const order = [ "daily", "weekly", "monthly", "yearly" ];
        const entries = Object.entries(data);

        return order.reduce((acc, item) => {
            const obj = entries.find(a => a[ 0 ] === item);

            if (obj) {
                acc.push(obj);
            }

            return acc;
        }, []);
    }

    /* Error Handler */
    private handleError(error: any, showModal: boolean) {
        if (error.status === 0) { // or whatever condition you like to put
            this.toastr.error("Internet connection failed!", "Network error");
        } else if (error.status === 401 && error.statusText === "Unauthorized") {
            this.toastr.error("Unauthorized User", "User logout");
            this.authenticationService.logout();
            this.router.navigate([ "/login" ]);
        } else {
            let response = error.error;
            if (typeof response === "string") {
                response = JSON.parse(response);
            }
            this.toastr.error(response.message, response.result);
            if (showModal) {
                const modalRef = this.modalService.open(ModalComponent);
                modalRef.componentInstance.modalOptions = new ModalOptions(Severity.ERROR, error.statusText, response.message, null);
            }
        }
    }
}
