/**
 * Created by Arindam Bajpayee on 12-05-2017.
 */
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "transactionStatus" })
export class TransactionStatusPipe implements PipeTransform {
    transform(status: number): string {
        if (!status) {
            return "";
        } else if (status === -999) {
            return "FAILED";
        } else if (status === -2) {
            return "CANCELLED";
        } else if (status === -1) {
            return "REJECTED";
        } else if (status === 1) {
            return "PENDING";
        } else if (status === 2) {
            return "CONFIRMED";
        } else if (status === -9) {
            return "EXPIRED";
        }
    }
}
