import { animate, state, style, transition, trigger } from "@angular/animations";
import { Component, Inject, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MatTableDataSource } from "@angular/material/table";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgProgress } from "@ngx-progressbar/core";
import { ToastrService } from "ngx-toastr";
/* Components */
import { ModalComponent, ModalOptions, Severity } from "../../modal/modal.component";
/* Models */
import { Country } from "../../../models/country";
import { Report } from "../../../models/report";
/* Services */
import { AuthenticationService } from "../../../services/authentication.service";
import { PusherService } from "../../../services/pusher/pusher.service";
import { ReportService } from "../../../services/report.service";

@Component({
    selector: "bo-reports",
    templateUrl: "./report.component.html",
    styleUrls: [ "./report.component.scss" ],
    animations: [
        trigger("detailExpand", [
            state("collapsed", style({ height: "0px", minHeight: "0", display: "none" })),
            state("expanded", style({ height: "*" })),
            transition("expanded <=> collapsed", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)"))
        ])
    ]
})
export class ReportComponent implements OnInit {
    country: Country = new Country();
    date: Date = new Date();
    dataTableColumns: string[] = [ "reportId", "name", "target", "owner", "private", "since", "action" ];
    dataTableSource: MatTableDataSource<Report> = new MatTableDataSource([]);
    isLoading = false;
    report: Report = new Report();
    reports: Report[] = [];
    selectedTab = "list";

    constructor(private router: Router,
                public activeModal: NgbActiveModal,
                public dialog: MatDialog,
                private modalService: NgbModal,
                private progressBar: NgProgress,
                private authenticationService: AuthenticationService,
                private pusherService: PusherService,
                private reportService: ReportService,
                private toastr: ToastrService) {
    }

    /* LIFECYCLE */
    ngOnInit(): void {
        if (!this.authenticationService.isUserLoggedIn()) {
            this.router.navigate([ "/login" ]);
            return;
        }
        this.getReports();
    }

    /* FUNCTIONS */

    create() {
        this.reportService.create(this.report).subscribe(response => {
            const data: any = { ...response };
            this.progressBar.ref().complete();
            this.isLoading = false;
            const modalRef = this.modalService.open(ModalComponent);
            modalRef.componentInstance.modalOptions = new ModalOptions(Severity.SUCCESS, "Success", data.message, null);
            this.router.navigate([ "/admin/reports" ]);
        }, error => {
            this.handleError(error, true);
            this.progressBar.ref().complete();
            this.isLoading = false;
        });
    }

    download(report: Report) {
        this.progressBar.ref().start();
        if (!report) {
            this.progressBar.ref().complete();
            return;
        }

        this.reportService.download(report).subscribe(response => {
            const data: any = { ...response };
            this.progressBar.ref().complete();
            this.isLoading = false;
        }, error => {
            this.handleError(error, true);
            this.progressBar.ref().complete();
            this.isLoading = false;
        });
    }

    isDownloadReportAllowed(report: Report): boolean {
        return report?.lastRun && report.lastRun?.status === "COMPLETED";
    }

    isRunReportAllowed(report: Report): boolean {
        return !report?.lastRun || report.lastRun?.status === "COMPLETED" || report.lastRun?.status === "NO_RUN";
    }

    isViewReportQueryAllowed(report: Report): boolean {
        return report?.query?.length > 0 && (this.authenticationService.getCurrentUser().isAccountAdmin || this.authenticationService.getCurrentUser().isAccountSupport);
    }

    openReportQueryDialog(report: Report): void {
        const dialogRef = this.dialog.open(ReportQueryDialog, {
            width: "800px",
            data: { report }
        });

        dialogRef.afterClosed().subscribe(data => {
            // Nothing to do
        });
    }

    run(report: Report): void {
        this.progressBar.ref().start();
        if (!report) {
            this.progressBar.ref().complete();
            return;
        }
        this.reportService.download(report).subscribe(response => {
            const data: any = { ...response };
            this.progressBar.ref().complete();
            this.isLoading = false;
        }, error => {
            this.handleError(error, true);
            this.progressBar.ref().complete();
            this.isLoading = false;
        });
    }

    selectTab($event, tab) {
        this.selectedTab = tab;
        if (tab === "list") {
            this.getReports();
        }
    }

    /* PRIVATE */
    private getReports() {
        this.progressBar.ref().start();
        this.reportService.getReports().subscribe(response => {
            const data: any = { ...response };
            this.reports = data.payload.reports;
            this.dataTableSource.data = this.reports;
            this.progressBar.ref().complete();
            this.isLoading = false;
        }, error => {
            this.handleError(error, true);
            this.progressBar.ref().complete();
            this.isLoading = false;
        });
    }

    private handleError(error: any, showModal: boolean) {
        if (error.status === 401 && error.statusText === "Unauthorized") {
            this.toastr.error("Unauthorized User", "User logout");
            this.authenticationService.logout();
            this.router.navigate([ "/login" ]);
        } else {
            let response = error.error;
            if (typeof response === "string") {
                response = JSON.parse(response);
            }
            this.toastr.error(response.message, response.result);
            if (showModal) {
                const modalRef = this.modalService.open(ModalComponent);
                modalRef.componentInstance.modalOptions = new ModalOptions(Severity.ERROR, error.statusText, response.message, null);
            }
        }
    }
}

/* Component to show the report query dialog */
export interface ReportQueryDialogData {
    report: Report;
}

@Component({
    selector: "bo-report-query-dialog",
    template: `
        <h1 mat-dialog-title>{{ data.report.name }} (ID: {{ data.report.reportId }})</h1>
        <div mat-dialog-content>
                <mat-form-field class="margin-top-20 width-full">
                    <mat-label>Query</mat-label>
                    <textarea matInput disabled placeholder="Query" style="font-family: monospace !important">{{ data.report.query }}</textarea>
                </mat-form-field>
        </div>
        <div mat-dialog-actions class="float-right">
            <button mat-button (click)="close()">Close</button>
        </div>
    `
})
export class ReportQueryDialog {
    constructor(
        public dialogRef: MatDialogRef<ReportQueryDialog>,
        @Inject(MAT_DIALOG_DATA) public data: ReportQueryDialogData) {
    }

    close(): void {
        this.dialogRef.close();
    }
}
