import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NgProgress } from "@ngx-progressbar/core";
import moment from "moment";
import { Papa } from "ngx-papaparse";

import { environment } from "../../../../../environments/environment";
import { AuthenticationService } from "../../../../services/authentication.service";
import { PartnerPayouts } from "../../../admin/partner/interfaces/partner-payouts.interface";
import { Partner } from "../../../../models/partner";
import { AtmPayoutsStateService, GroupedPayout } from "./atm-payouts-state.service";

@Component({
    selector: "bo-payout-list",
    templateUrl: "./payout-list.component.html",
    styleUrls: [ "./payout-list.component.scss" ]
})

export class PayoutListComponent {
    @Output("onClick") onClick: EventEmitter<any> = new EventEmitter();
    @Output() downloadPayoutsByMonthEvent: EventEmitter<GroupedPayout[]> = new EventEmitter<GroupedPayout[]>();
    @Input() set payouts(payouts: PartnerPayouts[]) {
        this.payoutsStateService.setAtmPayouts(payouts, this.dateKeyName);
    }
    @Input() set downloadedPayoutsByMonth(payouts: GroupedPayout[]) {
        if (payouts.length) {
            this.downloadReportPayout(payouts);
        }
    }
    @Input("showPopover") showPopover = false;
    @Input("dateKeyName") dateKeyName = "date";
    @Input("showDownloadButton") showDownloadButton = false;
    @Input("partner") partner: Partner = null;

    PAYOUT_STATUSES;
    ALL_YEARS_KEY = "";

    constructor(
        private authenticationService: AuthenticationService,
        private progressBar: NgProgress,
        private papaParse: Papa,
        public payoutsStateService: AtmPayoutsStateService
    ) {
        this.PAYOUT_STATUSES = this.payoutsStateService.PAYOUT_STATUSES;
        this.ALL_YEARS_KEY = this.payoutsStateService.ALL_YEARS_KEY;
    }

    onPayoutClicked(payout) {
        this.onClick.emit(payout);
    }

    getPayoutStatuses(payout: GroupedPayout): string[] {
        return Object.keys(payout.statuses);
    }

    onFilterPayouts({ value: year }) {
        this.payoutsStateService.onFilterPayouts(year);
    }

    get isDownloadPayoutAllowed(): boolean {
        const { isAccountAdmin, isAccountSupport, isAccountFinance } = this.authenticationService.getCurrentUser();
        return isAccountAdmin || isAccountSupport || isAccountFinance;
    }

    /* Action: Download payout details */
    downloadReportPayout(payoutsByMonth?: GroupedPayout[]): void {
        this.progressBar.ref().start();
        const data: GroupedPayout[] = [];

        if (payoutsByMonth?.length) {
            this.formattedPayouts(payoutsByMonth, data);
        } else {
            this.formattedPayouts(this.payoutsStateService.filteredPayouts.getValue(), data);
        }

        const blob = new Blob([ this.papaParse.unparse(data) ], { type: "text/csv;charset=utf-8;" });
        const downloadLink = document.createElement("a");
        const url = URL.createObjectURL(blob);
        const isSafariBrowser = navigator.userAgent.indexOf("Safari") !== -1 && navigator.userAgent.indexOf("Chrome") === -1;
        // if Safari open in new window to save file with random filename.
        if (isSafariBrowser) {
            downloadLink.setAttribute("target", "_blank");
        }

        const fileName = `payout_partner_${this.partner?.id}_${moment().format("YYYYMMDD")}${environment.production ? "" : ("_" + environment.name)}.csv`;
        downloadLink.setAttribute("href", url);
        downloadLink.setAttribute("download", fileName);
        downloadLink.style.visibility = "hidden";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        this.progressBar.ref().complete();
    }

    private formattedPayouts(payouts: GroupedPayout[], data): GroupedPayout[] {
        return payouts.map((payout: GroupedPayout) => {
            return data.push({
                DATE: moment(payout.date).format("YYYY.MM.DD HH:mm:SS"),
                NAME: this.partner?.name || "n.a.",
                ID: this.partner?.id,
                DESCRIPTION: payout.description || "n.a.",
                PHONE: payout.phone || "n.a.",
                CURRENCY: payout.currency,
                AMOUNT: payout.amount,
                STATUS: payout.status || "n.a."
            });
        });
    }
}
