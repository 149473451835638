import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: "bo-edit-actions",
    templateUrl: "./edit-actions.component.html",
    styleUrls: [ "./edit-actions.component.scss" ]
})
export class EditActionsComponent {
    @Input() editMode = false;
    @Input() isValid = true;
    @Output() onEdit: EventEmitter<any> = new EventEmitter();
    @Output() onSave: EventEmitter<any> = new EventEmitter();
    @Output() onCancel: EventEmitter<any> = new EventEmitter();
}
