/**
 * Created by Arindam Bajpayee on 28-04-2017.
 */
import { Injectable } from "@angular/core";
// import { Http,Response }        from '@angular/http';
import { HttpClient } from "@angular/common/http";
// import { Observable } from 'rxjs/Rx';
import { map } from "rxjs/operators";

import { environment } from "../../environments/environment";
import { AuthenticationService } from "./authentication.service";

@Injectable()
export class PaymentService {
    constructor(private http: HttpClient,
                private authenticationService: AuthenticationService) {
    }

    /* For Transactions page */
    geAllInbound(filter: any) {
        return this.http.post(
            environment.baseurl.api + "/protected/payment/inbound/all",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                filter
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    /* Get all of the outstanding balance */
    getAllOutstanding({ country: country, currency: currency, type: type }) {
        return this.http.get(
            environment.baseurl.api + "/protected/balance?country=" + country + "&currency=" + currency + "&type=" + type,
            this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    upload(fileName: string, fileContent: string) {
        return this.http.post(
            environment.baseurl.api + "/protected/payment/upload",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                fileName,
                data: fileContent
            }, this.authenticationService.getHeaders()).pipe(map(res => res));
    }
}
