<div class="page-title">
    Dashboard
    <div class="page-title-actions">
        <bo-domain-selector (out)="selectedCountry($event)"></bo-domain-selector>
    </div>
</div>

<div class="row margin-top-10">
    <div class="col">
        <form (ngSubmit)="searchByFilter()" class="display-inline-block">
            <mat-form-field class="margin-right-15">
                <input matInput [ngModelOptions]="{standalone: true}" [(ngModel)]="dateFrom" name="dateFrom" placeholder="From" [matDatepicker]="dateFromPicker" [max]="maxDate">
                <mat-datepicker-toggle matSuffix [for]="dateFromPicker"></mat-datepicker-toggle>
                <mat-datepicker #dateFromPicker></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="margin-right-15">
                <input matInput [ngModelOptions]="{standalone: true}" [(ngModel)]="dateTo" name="dateTo" placeholder="To" [matDatepicker]="dateToPicker" [max]="maxDate">
                <mat-datepicker-toggle matSuffix [for]="dateToPicker"></mat-datepicker-toggle>
                <mat-datepicker #dateToPicker></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="margin-right-15" style="width: 200px;">
                <input matInput [ngModelOptions]="{standalone: true}" [(ngModel)]="filter.zipCode" name="zipCode" placeholder="Zip-code">
            </mat-form-field>

            <!-- DO WE NEED THIS BUTTON? -->
            <button mat-icon-button><i class="fas fa-search"></i></button>
        </form>
    </div>
</div>

<!-- Latest details -->
<div class="row row-eq-height margin-top-10">
    <!-- Total Transactions (amount) -->
    <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="widget widget-seven sonect-grey-bg color-white">
            <div class="widget-body">
                <div class="widget-body-inner">
                    <h5 class="text-uppercase">Total Transactions</h5>
                    <i class="counter-icon fad fa-money-bill-alt"></i>
                    <span class="counter-count">
                            <i *ngIf="!transactions.total.loaded" class="counter-icon fad fa-spinner fa-spin"></i>
                            <span *ngIf="transactions.total.loaded">
                                {{ country.currency }}
                                <span class="counter-init font-weight-600">{{ transactions.total.value }}</span>
                            </span>
                        </span>
                </div>
            </div>
        </div>
    </div>

    <!-- Total number of users -->
    <div class="col-xl-4 col-lg-6 col-sm-6 col-xs-12">
        <div class="widget widget-seven sonect-berry-bg color-white">
            <div class="widget-body">
                <div class="widget-body-inner">
                    <h5 class="text-uppercase float-left">Total Users</h5>
                    <i class="counter-icon fad fa-users"></i>
                    <span class="counter-count">
                            <i *ngIf="!users.total.loaded" class="counter-icon fad fa-spinner fa-spin"></i>
                            <span *ngIf="users.total.loaded" class="counter-init font-weight-600">{{ users.total.value }}</span>
                        </span>
                </div>
            </div>
        </div>
    </div>

    <!-- Total number of ATM/merchants -->
    <div class="col-xl-4 col-lg-6 col-sm-6 col-xs-12">
        <div class="widget widget-seven sonect-berry-bg color-white">
            <div class="widget-body">
                <div class="widget-body-inner">
                    <h5 class="text-uppercase float-left">Total ATMs</h5>
                    <i class="counter-icon fad fa-store"></i>
                    <span class="counter-count">
                        <i *ngIf="!atms.total.loaded" class="counter-icon fad fa-spinner fa-spin"></i>
                        <span *ngIf="atms.total.loaded" class="counter-init font-weight-600">{{ atms.total.value }}</span>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Alerts -->
<div class="panel margin-top-10">
    <div class="panel-heading padding-bottom-0">
        <h4>Alerts</h4>
    </div>
    <div class="panel-body">
        <div class="row row-eq-height margin-top-10">
            <!-- Percentage of successful transactions in last 10 -->
            <div class="col-xl-3 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <div class="step-block padding-20"
                     [ngClass]="{ 'step-success': alerts.lastTransactionsStatus.status === 'normal',
                                  'step-warning': alerts.lastTransactionsStatus.status === 'warning',
                                  'step-danger': alerts.lastTransactionsStatus.status === 'danger'}">
                    <span class="step-digit">
                        <i class="fad" [ngClass]="{ 'fa-siren': alerts.lastTransactionsStatus.status !== 'danger',
                                                    'fa-siren-on': alerts.lastTransactionsStatus.status === 'danger'}"></i>
                    </span>
                    <div class="step-desc">
                        <span class="step-title">Success ratio</span>
                        <p>{{ alerts.lastTransactionsStatus.ratio }}</p>
                    </div>
                </div>
            </div>

            <!-- Time since last successful transaction -->
            <div class="col-xl-3 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <div class="step-block padding-20"
                     [ngClass]="{ 'step-success': alerts.lastSuccessfulTransactionTime.status === 'normal',
                                  'step-warning': alerts.lastSuccessfulTransactionTime.status === 'warning',
                                  'step-danger': alerts.lastSuccessfulTransactionTime.status === 'danger'}">
                    <span class="step-digit">
                        <i class="fad" [ngClass]="{ 'fa-alarm-snooze': alerts.lastSuccessfulTransactionTime.status === 'normal',
                                                    'fa-alarm-clock': alerts.lastSuccessfulTransactionTime.status === 'warning',
                                                    'fa-alarm-exclamation': alerts.lastSuccessfulTransactionTime.status === 'danger'}"></i>
                    </span>
                    <div class="step-desc">
                        <span class="step-title">Last successful transaction</span>
                        <p>{{ alerts.lastSuccessfulTransactionTime.value | date:"y.MM.dd HH:mm:ss" }}</p>
                        <!--p>{{ alerts.lastSuccessfulTransactionTime.value | number:"1.0-2" }} {{ alerts.lastSuccessfulTransactionTime.unit }} ago</p-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
