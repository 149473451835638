<mat-sidenav-container>
    <!-- Left menu -->
    <mat-sidenav #sidenav opened class="left-menu" role="navigation"
                 [fixedInViewport]="mobileQuery.matches" [mode]="mobileQuery.matches ? 'over' : 'side'"
                 [opened]="!mobileQuery.matches">
        <div class="logo-container">
            <a class="logo" routerLink="./dashboard" (click)="menuItemClicked();">
                <img alt="SONECT" src="../../../assets/img/logo/Sonect_Wordmark_RGB_TRANSP.png">
            </a>
        </div>
        <div class="left-menu-inner">
            <mat-nav-list class="left-menu-list left-menu-list-root">
                <a mat-list-item class="left-menu-link" *ngIf="getCurrentUser().canViewDashboard"
                   routerLink="./dashboard" routerLinkActive="active" (click)="menuItemClicked();" >
                    <i class="left-menu-link-icon fa-fw fad fa-analytics"></i>
                    <span class="margin-left-10">Dashboard</span>
                </a>

                <mat-divider></mat-divider>

<!--                <a mat-list-item class="left-menu-link" *ngIf="getCurrentUser().canViewDashboard"-->
<!--                   routerLink="./okr" routerLinkActive="active" (click)="menuItemClicked();" >-->
<!--                    <i class="left-menu-link-icon fa-fw fad fa-bullseye-arrow"></i>-->
<!--                    <span class="margin-left-10">KPI</span>-->
<!--                </a>-->

<!--                <mat-divider></mat-divider>-->

                <a mat-list-item class="left-menu-link" *ngIf="getCurrentUser().canViewAtms"
                   routerLink="./atm" routerLinkActive="active" (click)="menuItemClicked();">
                    <i class="left-menu-link-icon fa-fw fad fa-store"></i>
                    <span class="margin-left-10">ATMs</span>
                </a>

                <a mat-list-item class="left-menu-link" *ngIf="getCurrentUser().canViewAtms"
                   routerLink="./atm-audits" routerLinkActive="active" (click)="menuItemClicked();">
                    <i class="left-menu-link-icon fa-fw fad fa-store"></i>
                    <span class="margin-left-10">ATM Limit Alerts</span>
                </a>

                <mat-divider></mat-divider>

                <a mat-list-item class="left-menu-link" *ngIf="getCurrentUser().canViewPartners"
                   routerLink="./partner" routerLinkActive="active" (click)="menuItemClicked();">
                    <i class="left-menu-link-icon fa-fw fad fa-handshake"></i>
                    <span class="margin-left-10">Partners</span>
                </a>

                <mat-divider></mat-divider>

                <a mat-list-item class="left-menu-link" *ngIf="showPayments"
                   routerLink="./payment" routerLinkActive="active" (click)="menuItemClicked();">
                    <i class="left-menu-link-icon fa-fw fad fa-money-check-alt"></i>
                    <span class="margin-left-10">Payments</span>
                </a>
                <a mat-list-item class="left-menu-link" *ngIf="getCurrentUser().isAccountAdmin || getCurrentUser().isAccountSupport"
                   routerLink="./payment-methods" routerLinkActive="active" (click)="menuItemClicked();">
                    <i class="left-menu-link-icon fa-fw fad fa-credit-card"></i>
                    <span class="margin-left-10">Payment methods</span>
                </a>
                <a mat-list-item class="left-menu-link" *ngIf="getCurrentUser().canViewTransactions"
                   routerLink="./transaction" routerLinkActive="active" (click)="menuItemClicked();">
                    <i class="left-menu-link-icon fa-fw fad fa-money-bill-alt"></i>
                    <span class="margin-left-10">Transactions</span>
                </a>
                <a mat-list-item class="left-menu-link"
                   routerLink="./limits" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="menuItemClicked();">
                    <i class="left-menu-link-icon fa-fw fad fa-monitor-heart-rate"></i>
                    <span class="margin-left-10">Limits</span>
                </a>

                <mat-divider></mat-divider>

                <a mat-list-item class="left-menu-link" *ngIf="getCurrentUser().canViewUsers"
                   routerLink="./user" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="menuItemClicked();">
                    <i class="left-menu-link-icon fa-fw fad fa-users"></i>
                    <span class="margin-left-10">Users</span>
                </a>

                <mat-divider></mat-divider>

                <!-- Compliance section -->
                <a mat-list-item class="left-menu-link" *ngIf="getCurrentUser().canViewUserProfiles"
                   routerLink="./user/profiles" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="menuItemClicked();">
                    <i class="left-menu-link-icon fa-fw fad fa-file-user"></i>
                    <span class="margin-left-10">KYC requests</span>
                </a>
                <a mat-list-item class="left-menu-link" *ngIf="getCurrentUser().canViewUserProfiles"
                   routerLink="./monitor" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="menuItemClicked();">
                    <i class="left-menu-link-icon fa-fw fad fa-monitor-heart-rate"></i>
                    <span class="margin-left-10">Monitoring</span>
                </a>
                <a mat-list-item class="left-menu-link" *ngIf="true || getCurrentUser().canViewPromotionCodes"
                    routerLink="./promocodes" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="menuItemClicked()">
                    <img width="18" src="../../../assets/img/promocodes-icons/promotion-code.svg">
                    <span class="margin-left-10">Promotion codes</span>
                </a>
                <a mat-list-item class="left-menu-link" *ngIf="true"
                    routerLink="./spotlights" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="menuItemClicked()">
                    <img width="18" src="../../../assets/img/icons/spotlight.svg">
                    <span class="margin-left-10">Spotlights</span>
                </a>

                <mat-divider></mat-divider>

                <a mat-list-item class="left-menu-link" *ngIf=" getCurrentUser().isAccountSupportExternal || getCurrentUser().canViewSettings"
                   routerLink="./job" routerLinkActive="active" (click)="menuItemClicked();">
                    <i class="left-menu-link-icon fa-fw fad fa-tasks-alt"></i>
                    <span class="margin-left-10">Jobs</span>
                </a>
                <a mat-list-item class="left-menu-link" *ngIf="getCurrentUser().canViewSettings"
                   routerLink="./settings" routerLinkActive="active" (click)="menuItemClicked();">
                    <i class="left-menu-link-icon fa-fw fad fa-sliders-h"></i>
                    <span class="margin-left-10">Settings</span>
                </a>

                <mat-divider></mat-divider>

                <a mat-list-item class="left-menu-link"
                   routerLink="./profile" routerLinkActive="active" (click)="menuItemClicked();">
                    <i class="left-menu-link-icon fa-fw fad fa-id-card"></i>
                    <span class="margin-left-10">My profile</span>
                </a>
                <a mat-list-item class="left-menu-link" (click)="logout()">
                    <i class="left-menu-link-icon fa-fw fad fa-sign-out-alt"></i>
                    <span class="margin-left-10">Logout</span>
                </a>
            </mat-nav-list>
        </div>
    </mat-sidenav>
    <mat-sidenav-content>
        <!-- Top menu -->
        <mat-toolbar class="top-menu">
            <button mat-icon-button (click)="sidenav.toggle()"><mat-icon>menu</mat-icon></button>
            <div class="menu">
                <bo-environment-name></bo-environment-name>
                <span class="menu-user-block font-weight-500">{{ getCurrentUser().name }}</span>
            </div>
        </mat-toolbar>

        <!-- Main Content -->
        <section class="page-content">
            <div class="page-content-inner">
                <!-- The routed views will be inserted here -->
                <router-outlet></router-outlet>
            </div>
        </section>
    </mat-sidenav-content>
</mat-sidenav-container>
