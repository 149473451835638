/**
 * Created by Arindam Bajpayee on 27-04-2017.
 */
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgProgress } from "@ngx-progressbar/core";
import { Papa } from "ngx-papaparse";
import moment from "moment";
import { ToastrService } from "ngx-toastr";
/* Models */
import { UserProfile } from "../../../models/user-profile";
/* Services */
import { AuthenticationService } from "../../../services/authentication.service";
import { IMonitorsResponse, MonitorService } from "../../../services/monitor.service";
import { ConfigurationService } from "../../../services/configuration.service";
import { ModalComponent, ModalOptions, Severity } from "../../modal/modal.component";

import { environment } from "../../../../environments/environment";
import { Chart } from "angular-highcharts";
import { IInternalSeriesOptions } from "../user/interfaces/user.interface";
import { CaseStatus, monitorCaseStatusToClassConfig } from "../../../models/monitor";

@Component({
    selector: "bo-monitor",
    templateUrl: "./monitor.component.html",
    styleUrls: ["./monitor.component.css"]
})
export class MonitorComponent implements OnInit {
    page = 1;
    pageSize = 100;
    period = "week";
    maxSize = 5;
    collectionSize = 100;
    userProfiles: UserProfile[] = [];
    country = {
        code: "",
        currency: "",
        name: ""
    };
    profileType = "user";
    expandedElement: any | null;
    status = "";

    // RTRA
    transactionLimitsChart: Chart;
    velocityLimitsChart: Chart;

    transactionLimits: any[] = [];
    trxLimits: any[] = [];
    velocityLimits: any[] = [];
    velLimits: any[] = [];

    // Red & High
    redTrxFlagsChart: Chart;
    redUserFlagsChart: Chart;

    highTrxFlagsChart: Chart;
    highUserFlagsChart: Chart;

    redFlagEvents: any = [];
    highFlagEvents: any = [];


    redTransactionFlags: any[] = [];
    redUserFlags: any[] = [];

    highTransactionFlags: any[] = [];
    highUserFlags: any[] = [];

    filter = {
        country: "",
        summary: {
            count: 0
        }
    };
    displayedColumns: string[] = ["name", "time", "event"];
    displayedNewColumns: string[] = ["name", "reason", "time", "status"];
    displayedRetroColumns: string[] = ["transactionId", "flag", "cor", "type", "name", "kycLevel", "time"];
    data = {} as IMonitorsResponse;
    dateFrom: Date = null;
    dateTo: Date = null;
    caseTypes: ["OPENED", "ESCALATED", "ON_HOLD", "CLOSED"];
    cases = {
        open: {
            array: [],
            count: 0
        },
        escalated: {
            array: [],
            count: 0
        },
        hold: {
            array: [],
            count: 0
        },
        closed: {
            array: [],
            count: 0
        }
    };
    postTrx = [];
    public options4country = [];

    constructor(private router: Router,
                private modalService: NgbModal,
                private papaParse: Papa,
                private progressBar: NgProgress,
                private authenticationService: AuthenticationService,
                private monitorService: MonitorService,
                private configurationService: ConfigurationService,
                private toastr: ToastrService) {
    }

    ngOnInit(): void {
        if (!this.authenticationService.isUserLoggedIn()) {
            this.router.navigate(["/login"]);
            return;
        }
        this.getCountryDetails();
        const stateData = this.authenticationService.getState("monitoring-table");
        if (stateData) {
            const data = JSON.parse(stateData);
            this.filter = data.filter;
            this.userProfiles = data.userProfiles;
            this.collectionSize = data.collectionSize;
            this.status = data.status;
            this.page = data.page;
            // TODO: remove the call
        } else {
            this.rtraRolling("week");
            this.redFlagsRolling("week");
            this.highFlagsRolling("week");
        }
    }

    navigateTo(id: string): void {
        const data: any = {
            filter: this.filter,
            userProfiles: this.userProfiles,
            collectionSize: this.collectionSize,
            status: this.status,
            page: this.page
        };
        this.authenticationService.saveState("monitoring-table", data);
        const path = this.profileType === "user" ? `/admin/user/${id}` : `/admin/atm/${id}`;
        this.router.navigate([ path ]);
    }

    getAmlCases(): void {
        this.monitorService.getAmlCases({ country: this.country, filter: { from: this.dateFrom, to: this.dateTo, type: this.profileType } }).subscribe(response => {
            const data: any = { ...response };
            this.cases = data.payload.cases;
        });
    }

    getCountryDetails(): void {
        /*Widget - Country Data */
        this.configurationService.getCountryData().subscribe(response => {
                const data: any = { ...response };
                this.options4country = data.countries;
            }
        );
    }

    getTransactionAlerts() {
        this.monitorService.getTransactionAlerts({ country: this.country, filter: { from: this.dateFrom, to: this.dateTo, type: this.profileType } }).subscribe(response => {
            const data: any = { ...response };
            this.postTrx = data.payload.alerts;
        });
    }

    /* Widget - Transaction limits events history */
    getTransactionLimitsChartDetails() {
        this.progressBar.ref().start();
        let from, to;
        from = Date.now();
        const A_WEEK_OLD = moment(from).subtract(1, "week");
        const A_MONTH_OLD = moment(from).subtract(1, "month");
        const A_YEAR_OLD = moment(from).subtract(1, "year");
        let startDate = moment(from).subtract(1, "week").add(1, "day");
        const endDate = moment(from);
        this.trxLimits = [];
        if (this.period === "week") {
            startDate = moment(from).subtract(1, "week").add(1, "day");
        }
        if (this.period === "month") {
            startDate = moment(from).subtract(1, "month").add(1, "day");
        }
        if (this.period === "year") {
            startDate = moment(from).subtract(1, "year").add(1, "month");
        }


        const withdrawalDailyLimit: IInternalSeriesOptions = {
            column: {
                color: "#ff372c",
                data: [],
                name: "Daily Limit",
                type: "column"
            },
            spline: {
                color: "#ff372c",
                data: [],
                name: "Daily Limit",
                yAxis: 1,
                type: "spline"
            }
        };

        const withdrawalWeeklyLimit: IInternalSeriesOptions = {
            column: {
                color: "#87c455",
                data: [],
                name: "Weekly Withdrawal",
                type: "column"
            },
            spline: {
                color: "#87c455",
                data: [],
                name: "Weekly Withdrawal",
                yAxis: 1,
                type: "spline"
            }
        };
        const withdrawalMonthlyLimit: IInternalSeriesOptions = {
            column: {
                color: "#e3e0c9",
                data: [],
                name: "Monthly Withdrawal",
                type: "column"
            },
            spline: {
                color: "#e3e0c9",
                data: [],
                name: "Monthly Withdrawal",
                yAxis: 1,
                type: "spline"
            }
        };
        const withdrawalYearlyLimit: IInternalSeriesOptions = {
            column: {
                color: "#40e3e3",
                data: [],
                name: "Yearly Withdrawal",
                type: "column"
            },
            spline: {
                color: "#40e3e3",
                data: [],
                name: "Yearly Withdrawal",
                yAxis: 1,
                type: "spline"
            }
        };

        let transactionLimits = this.transactionLimits;
        let event;

        const units = [];
        let i = 0, j = 0;
        while (startDate.isSameOrBefore(endDate)) {
            let unit;
            if (this.period === "week") {
                unit = startDate.format("ddd").toUpperCase();
                transactionLimits = transactionLimits.filter((event) => moment(event.statusTime).isAfter(A_WEEK_OLD));
            }
            if (this.period === "month") {
                unit = startDate.format("DD'MM").toUpperCase();
                transactionLimits = transactionLimits.filter((event) => moment(event.statusTime).isAfter(A_MONTH_OLD));
            }
            if (this.period === "year") {
                unit = startDate.format("MMM'YY").toUpperCase();
                transactionLimits = transactionLimits.filter((event) => moment(event.statusTime).isAfter(A_YEAR_OLD));
            }
            if (units.indexOf(unit) < 0) {
                units.push(unit);
                j = units.length - 1;

                withdrawalDailyLimit.column.data.push(0);
                withdrawalDailyLimit.spline.data.push(0);
                withdrawalWeeklyLimit.column.data.push(0);
                withdrawalWeeklyLimit.spline.data.push(0);
                withdrawalMonthlyLimit.column.data.push(0);
                withdrawalMonthlyLimit.spline.data.push(0);
                withdrawalYearlyLimit.column.data.push(0);
                withdrawalYearlyLimit.spline.data.push(0);
            }
            for (; i < transactionLimits.length;) {
                event = transactionLimits[ i ];
                let eventUnit;
                if (this.period === "week") {
                    eventUnit = moment(event.statusTime).format("ddd").toUpperCase();
                }
                if (this.period === "month") {
                    eventUnit = moment(event.statusTime).format("DD'MM").toUpperCase();
                }
                if (this.period === "year") {
                    eventUnit = moment(event.statusTime).format("MMM'YY").toUpperCase();
                }
                if (eventUnit === unit) {
                    this.trxLimits.push(transactionLimits[ i ]);
                    if (event.description === "USER_TRANSACTION_LIMIT_DAILY_EXCEEDED") {
                        withdrawalDailyLimit.column.data[ j ] += 1;
                        withdrawalDailyLimit.spline.data[ j ] += 1;
                    } else if (event.description === "USER_TRANSACTION_LIMIT_WEEKLY_EXCEEDED") {
                        withdrawalWeeklyLimit.column.data[ j ] += 1;
                        withdrawalWeeklyLimit.spline.data[ j ] += 1;
                    } else if (event.description === "USER_TRANSACTION_LIMIT_MONTHLY_EXCEEDED") {
                        withdrawalMonthlyLimit.column.data[ j ] += 1;
                        withdrawalMonthlyLimit.spline.data[ j ] += 1;
                    } else if (event.description === "USER_TRANSACTION_LIMIT_YEARLY_EXCEEDED") {
                        withdrawalYearlyLimit.column.data[ j ] += 1;
                        withdrawalYearlyLimit.spline.data[ j ] += 1;
                    }
                    i++;
                } else {
                    break;
                }
            }
            if (this.period === "year") {
                startDate.add(1, "month");
            } else {
                startDate.add(1, "day");
            }

        }

        this.transactionLimitsChart = new Chart({
            title: { text: "TRX LIMIT HIT EVENTS TIMELINE" },
            xAxis: { categories: units },
            yAxis: [{
                min: 0,
                title: {
                    text: "Count of Users"
                }
            }, {
                allowDecimals: false,
                opposite: true,
                title: { text: "Count" }
            }],
            legend: { shadow: false },
            tooltip: { shared: true },
            plotOptions: {
                column: {
                    borderWidth: 0,
                    dataLabels: { enabled: true },
                    stacking: "normal"
                }
            },
            series: [
                withdrawalDailyLimit.column,
                withdrawalWeeklyLimit.column,
                withdrawalMonthlyLimit.column,
                withdrawalYearlyLimit.column
            ]
        });
        this.progressBar.ref().complete();
    }

    /* Widget - Velocity limits events history */
    getVelocityLimitsChartDetails() {
        this.progressBar.ref().start();
        let from, to;
        from = Date.now();
        const A_WEEK_OLD = moment(from).subtract(1, "week");
        const A_MONTH_OLD = moment(from).subtract(1, "month");
        const A_YEAR_OLD = moment(from).subtract(1, "year");
        let startDate = moment(from).subtract(1, "week").add(1, "day");
        const endDate = moment(Date.now());
        this.velLimits = [];
        if (this.period === "week") {
            startDate = moment(from).subtract(1, "week").add(1, "day");
        }
        if (this.period === "month") {
            startDate = moment(from).subtract(1, "month").add(1, "day");
        }
        if (this.period === "year") {
            startDate = moment(from).subtract(1, "year").add(1, "month");
        }


        const velocityDailyLimit: IInternalSeriesOptions = {
            column: {
                color: "#96006b",
                data: [],
                name: "Daily Velocity",
                type: "column"
            },
            spline: {
                color: "#C40051",
                data: [],
                name: "Daily Velocity",
                yAxis: 1,
                type: "spline"
            }
        };
        const velocityWeeklyLimit: IInternalSeriesOptions = {
            column: {
                color: "#ffa300",
                data: [],
                name: "Weekly Velocity",
                type: "column"
            },
            spline: {
                color: "#C40051",
                data: [],
                name: "Weekly Velocity",
                yAxis: 1,
                type: "spline"
            }
        };
        const velocityMonthlyLimit: IInternalSeriesOptions = {
            column: {
                color: "#bfe500",
                data: [],
                name: "Monthly Velocity",
                type: "column"
            },
            spline: {
                color: "#C40051",
                data: [],
                name: "Monthly Velocity",
                yAxis: 1,
                type: "spline"
            }
        };

        let velocityLimits = this.velocityLimits;
        let event;

        const units = [];
        let i = 0, j = 0;
        while (startDate.isSameOrBefore(endDate)) {
            let unit;
            if (this.period === "week") {
                unit = startDate.format("ddd").toUpperCase();
                velocityLimits = velocityLimits.filter((event) => moment(event.statusTime).isAfter(A_WEEK_OLD));
            }
            if (this.period === "month") {
                unit = startDate.format("DD'MM").toUpperCase();
                velocityLimits = velocityLimits.filter((event) => moment(event.statusTime).isAfter(A_MONTH_OLD));
            }
            if (this.period === "year") {
                unit = startDate.format("MMM'YY").toUpperCase();
                velocityLimits = velocityLimits.filter((event) => moment(event.statusTime).isAfter(A_YEAR_OLD));
            }
            if (units.indexOf(unit) < 0) {
                units.push(unit);
                j = units.length - 1;

                velocityDailyLimit.column.data.push(0);
                velocityDailyLimit.spline.data.push(0);
                velocityWeeklyLimit.column.data.push(0);
                velocityWeeklyLimit.spline.data.push(0);
                velocityMonthlyLimit.column.data.push(0);
                velocityMonthlyLimit.spline.data.push(0);
            }
            for (; i < velocityLimits.length;) {
                event = velocityLimits[ i ];
                let eventUnit;
                if (this.period === "week") {
                    eventUnit = moment(event.statusTime).format("ddd").toUpperCase();
                }
                if (this.period === "month") {
                    eventUnit = moment(event.statusTime).format("DD'MM").toUpperCase();
                }
                if (this.period === "year") {
                    eventUnit = moment(event.statusTime).format("MMM'YY").toUpperCase();
                }
                if (eventUnit === unit) {
                    this.velLimits.push(velocityLimits[ i ]);
                    if (event.description === "USER_VELOCITY_LIMIT_DAILY_EXCEEDED") {
                        velocityDailyLimit.column.data[ j ] += 1;
                        velocityDailyLimit.spline.data[ j ] += 1;
                    } else if (event.description === "USER_VELOCITY_LIMIT_WEEKLY_EXCEEDED") {
                        velocityWeeklyLimit.column.data[ j ] += 1;
                        velocityWeeklyLimit.spline.data[ j ] += 1;
                    } else if (event.description === "USER_VELOCITY_LIMIT_MONTHLY_EXCEEDED") {
                        velocityMonthlyLimit.column.data[ j ] += 1;
                        velocityMonthlyLimit.spline.data[ j ] += 1;
                    }
                    i++;
                } else {
                    break;
                }
            }
            if (this.period === "year") {
                startDate.add(1, "month");
            } else {
                startDate.add(1, "day");
            }

        }
        this.velocityLimitsChart = new Chart({
            title: { text: "VELOCITY LIMIT HIT EVENTS TIMELINE" },
            xAxis: { categories: units },
            yAxis: [{
                min: 0,
                title: {
                    text: "Count of Users"
                }
            }, {
                allowDecimals: false,
                opposite: true,
                title: { text: "Count" }
            }],
            legend: { shadow: false },
            tooltip: { shared: true },
            plotOptions: {
                column: {
                    borderWidth: 0,
                    dataLabels: { enabled: true },
                    stacking: "normal"
                }
            },
            series: [
                velocityDailyLimit.column,
                velocityWeeklyLimit.column,
                velocityMonthlyLimit.column
            ]
        });
        this.progressBar.ref().complete();
    }

    /* Widget - Restrospective Red transaction flags events history */
    getRedFlagsTrxChartDetails() {
        this.progressBar.ref().start();
        let from, to;
        from = Date.now();
        const A_WEEK_OLD = moment(from).subtract(1, "week");
        const A_MONTH_OLD = moment(from).subtract(1, "month");
        const A_YEAR_OLD = moment(from).subtract(1, "year");
        let startDate = moment(from).subtract(1, "week").add(1, "day");
        const endDate = moment(Date.now());
        this.redTransactionFlags = [];
        if (this.period === "week") {
            startDate = moment(from).subtract(1, "week").add(1, "day");
        }
        if (this.period === "month") {
            startDate = moment(from).subtract(1, "month").add(1, "day");
        }
        if (this.period === "year") {
            startDate = moment(from).subtract(1, "year").add(1, "month");
        }
        const LowFlag: IInternalSeriesOptions = {
            column: {
                color: "#bfe500",
                data: [],
                name: "Low",
                type: "column"
            },
            spline: {
                color: "#C40051",
                data: [],
                name: "Low",
                yAxis: 1,
                type: "spline"
            }
        };

        const MediumFlag: IInternalSeriesOptions = {
            column: {
                color: "#ffa300",
                data: [],
                name: "Medium",
                type: "column"
            },
            spline: {
                color: "#C40051",
                data: [],
                name: "Medium",
                yAxis: 1,
                type: "spline"
            }
        };

        const HighFlag: IInternalSeriesOptions = {
            column: {
                color: "#96006b",
                data: [],
                name: "High",
                type: "column"
            },
            spline: {
                color: "#C40051",
                data: [],
                name: "High",
                yAxis: 1,
                type: "spline"
            }
        };

        let redTrxEvents = this.redFlagEvents.filter(({ type }) => {
            return type === "retro_transaction";
        });
        let event;
        const units = [];
        let i = 0, j = 0;
        while (startDate.isSameOrBefore(endDate)) {
            let unit;
            if (this.period === "week") {
                unit = startDate.format("ddd").toUpperCase();
                redTrxEvents = redTrxEvents.filter((event) => moment(event.statusTime).isAfter(A_WEEK_OLD));
            }
            if (this.period === "month") {
                unit = startDate.format("DD'MM").toUpperCase();
                redTrxEvents = redTrxEvents.filter((event) => moment(event.statusTime).isAfter(A_MONTH_OLD));
            }
            if (this.period === "year") {
                unit = startDate.format("MMM'YY").toUpperCase();
                redTrxEvents = redTrxEvents.filter((event) => moment(event.statusTime).isAfter(A_YEAR_OLD));
            }
            if (units.indexOf(unit) < 0) {
                units.push(unit);
                j = units.length - 1;

                LowFlag.column.data.push(0);
                LowFlag.spline.data.push(0);
                MediumFlag.column.data.push(0);
                MediumFlag.spline.data.push(0);
                HighFlag.column.data.push(0);
                HighFlag.spline.data.push(0);

            }
            for (; i < redTrxEvents.length;) {
                event = redTrxEvents[ i ];
                let eventUnit;
                if (this.period === "week") {
                    eventUnit = moment(event.statusTime).format("ddd").toUpperCase();
                }
                if (this.period === "month") {
                    eventUnit = moment(event.statusTime).format("DD'MM").toUpperCase();
                }
                if (this.period === "year") {
                    eventUnit = moment(event.statusTime).format("MMM'YY").toUpperCase();
                }
                if (eventUnit === unit) {
                    this.redTransactionFlags.push(redTrxEvents[ i ]);
                    if (event.event === "LOW") {
                        LowFlag.column.data[ j ] += 1;
                        LowFlag.spline.data[ j ] += 1;
                    } else if (event.event === "MEDIUM") {
                        MediumFlag.column.data[ j ] += 1;
                        MediumFlag.spline.data[ j ] += 1;
                    } else if (event.event === "HIGH") {
                        HighFlag.column.data[ j ] += 1;
                        HighFlag.spline.data[ j ] += 1;
                    }
                    i++;
                } else {
                    break;
                }
            }
            if (this.period === "year") {
                startDate.add(1, "month");
            } else {
                startDate.add(1, "day");
            }

        }
        // let i = 0, j = 0;

        this.redTrxFlagsChart = new Chart({
            title: { text: "Post Transactions alerts" },
            xAxis: { categories: units },
            yAxis: [{
                min: 0,
                title: {
                    text: "Count of Users"
                }
            }, {
                allowDecimals: false,
                opposite: true,
                title: { text: "Count" }
            }],
            legend: { shadow: false },
            tooltip: { shared: true },
            plotOptions: {
                column: {
                    borderWidth: 0,
                    dataLabels: { enabled: true },
                    stacking: "normal"
                }
            },
            series: [
                LowFlag.column,
                MediumFlag.column,
                HighFlag.column
            ]
        });
        this.progressBar.ref().complete();
    }

    /* Widget - Restrospective Red user flags events history */
    getRedFlagsUserChartDetails() {
        this.progressBar.ref().start();
        let from, to;
        from = Date.now();
        const A_WEEK_OLD = moment(from).subtract(1, "week");
        const A_MONTH_OLD = moment(from).subtract(1, "month");
        const A_YEAR_OLD = moment(from).subtract(1, "year");
        let startDate = moment(from).subtract(1, "week").add(1, "day");
        const endDate = moment(Date.now());
        this.redUserFlags = [];
        if (this.period === "week") {
            startDate = moment(from).subtract(1, "week").add(1, "day");
        }
        if (this.period === "month") {
            startDate = moment(from).subtract(1, "month").add(1, "day");
        }
        if (this.period === "year") {
            startDate = moment(from).subtract(1, "year").add(1, "month");
        }
        const LowFlag: IInternalSeriesOptions = {
            column: {
                color: "#bfe500",
                data: [],
                name: "Low",
                type: "column"
            },
            spline: {
                color: "#C40051",
                data: [],
                name: "Low",
                yAxis: 1,
                type: "spline"
            }
        };

        const MediumFlag: IInternalSeriesOptions = {
            column: {
                color: "#ffa300",
                data: [],
                name: "Medium",
                type: "column"
            },
            spline: {
                color: "#C40051",
                data: [],
                name: "Medium",
                yAxis: 1,
                type: "spline"
            }
        };

        const HighFlag: IInternalSeriesOptions = {
            column: {
                color: "#96006b",
                data: [],
                name: "High",
                type: "column"
            },
            spline: {
                color: "#C40051",
                data: [],
                name: "High",
                yAxis: 1,
                type: "spline"
            }
        };

        let redUserEvents = this.redFlagEvents.filter((event) => {
            return (event.type === "retro_user") ? true : false;
        });
        let event;
        const units = [];
        let i = 0, j = 0;
        while (startDate.isSameOrBefore(endDate)) {
            let unit;
            if (this.period === "week") {
                unit = startDate.format("ddd").toUpperCase();
                redUserEvents = redUserEvents.filter((event) => moment(event.statusTime).isAfter(A_WEEK_OLD));
            }
            if (this.period === "month") {
                unit = startDate.format("DD'MM").toUpperCase();
                redUserEvents = redUserEvents.filter((event) => moment(event.statusTime).isAfter(A_MONTH_OLD));
            }
            if (this.period === "year") {
                unit = startDate.format("MMM'YY").toUpperCase();
                redUserEvents = redUserEvents.filter((event) => moment(event.statusTime).isAfter(A_YEAR_OLD));
            }
            if (units.indexOf(unit) < 0) {
                units.push(unit);
                j = units.length - 1;

                LowFlag.column.data.push(0);
                LowFlag.spline.data.push(0);
                MediumFlag.column.data.push(0);
                MediumFlag.spline.data.push(0);
                HighFlag.column.data.push(0);
                HighFlag.spline.data.push(0);

            }
            for (; i < redUserEvents.length;) {
                event = redUserEvents[ i ];
                let eventUnit;
                if (this.period === "week") {
                    eventUnit = moment(event.statusTime).format("ddd").toUpperCase();
                }
                if (this.period === "month") {
                    eventUnit = moment(event.statusTime).format("DD'MM").toUpperCase();
                }
                if (this.period === "year") {
                    eventUnit = moment(event.statusTime).format("MMM'YY").toUpperCase();
                }
                if (eventUnit === unit) {
                    this.redTransactionFlags.push(redUserEvents[ i ]);
                    if (event.event === "LOW") {
                        LowFlag.column.data[ j ] += 1;
                        LowFlag.spline.data[ j ] += 1;
                    } else if (event.event === "MEDIUM") {
                        MediumFlag.column.data[ j ] += 1;
                        MediumFlag.spline.data[ j ] += 1;
                    } else if (event.event === "HIGH") {
                        HighFlag.column.data[ j ] += 1;
                        HighFlag.spline.data[ j ] += 1;
                    }
                    i++;
                } else {
                    break;
                }
            }
            if (this.period === "year") {
                startDate.add(1, "month");
            } else {
                startDate.add(1, "day");
            }

        }

        this.redUserFlagsChart = new Chart({
            title: { text: "RED ALERTS For Users" },
            xAxis: { categories: units },
            yAxis: [{
                min: 0,
                title: {
                    text: "Count of Users"
                }
            }, {
                allowDecimals: false,
                opposite: true,
                title: { text: "Count" }
            }],
            legend: { shadow: false },
            tooltip: { shared: true },
            plotOptions: {
                column: {
                    borderWidth: 0,
                    dataLabels: { enabled: true },
                    stacking: "normal"
                }
            },
            series: [
                LowFlag.column,
                MediumFlag.column,
                HighFlag.column
            ]
        });
        this.progressBar.ref().complete();
    }

    /* Widget - Restrospective High transaction flags events history */
    getHighFlagsTrxChartDetails() {
        this.progressBar.ref().start();
        let from, to;
        from = Date.now();
        const A_WEEK_OLD = moment(from).subtract(1, "week");
        const A_MONTH_OLD = moment(from).subtract(1, "month");
        const A_YEAR_OLD = moment(from).subtract(1, "year");
        let startDate = moment(from).subtract(1, "week").add(1, "day");
        const endDate = moment(Date.now());
        this.highTransactionFlags = [];
        if (this.period === "week") {
            startDate = moment(from).subtract(1, "week").add(1, "day");
        }
        if (this.period === "month") {
            startDate = moment(from).subtract(1, "month").add(1, "day");
        }
        if (this.period === "year") {
            startDate = moment(from).subtract(1, "year").add(1, "month");
        }
        const LowFlag: IInternalSeriesOptions = {
            column: {
                color: "#bfe500",
                data: [],
                name: "Low",
                type: "column"
            },
            spline: {
                color: "#C40051",
                data: [],
                name: "Low",
                yAxis: 1,
                type: "spline"
            }
        };

        const MediumFlag: IInternalSeriesOptions = {
            column: {
                color: "#ffa300",
                data: [],
                name: "Medium",
                type: "column"
            },
            spline: {
                color: "#C40051",
                data: [],
                name: "Medium",
                yAxis: 1,
                type: "spline"
            }
        };

        const HighFlag: IInternalSeriesOptions = {
            column: {
                color: "#96006b",
                data: [],
                name: "High",
                type: "column"
            },
            spline: {
                color: "#C40051",
                data: [],
                name: "High",
                yAxis: 1,
                type: "spline"
            }
        };

        let highTrxEvents = this.highFlagEvents.filter((event) => {
            return (event.type === "retro_transaction") ? true : false;
        });
        let event;
        const units = [];
        let i = 0, j = 0;
        while (startDate.isSameOrBefore(endDate)) {
            let unit;
            if (this.period === "week") {
                unit = startDate.format("ddd").toUpperCase();
                highTrxEvents = highTrxEvents.filter((event) => moment(event.statusTime).isAfter(A_WEEK_OLD));
            }
            if (this.period === "month") {
                unit = startDate.format("DD'MM").toUpperCase();
                highTrxEvents = highTrxEvents.filter((event) => moment(event.statusTime).isAfter(A_MONTH_OLD));
            }
            if (this.period === "year") {
                unit = startDate.format("MMM'YY").toUpperCase();
                highTrxEvents = highTrxEvents.filter((event) => moment(event.statusTime).isAfter(A_YEAR_OLD));
            }
            if (units.indexOf(unit) < 0) {
                units.push(unit);
                j = units.length - 1;

                LowFlag.column.data.push(0);
                LowFlag.spline.data.push(0);
                MediumFlag.column.data.push(0);
                MediumFlag.spline.data.push(0);
                HighFlag.column.data.push(0);
                HighFlag.spline.data.push(0);

            }
            for (; i < highTrxEvents.length;) {
                event = highTrxEvents[ i ];
                let eventUnit;
                if (this.period === "week") {
                    eventUnit = moment(event.statusTime).format("ddd").toUpperCase();
                }
                if (this.period === "month") {
                    eventUnit = moment(event.statusTime).format("DD'MM").toUpperCase();
                }
                if (this.period === "year") {
                    eventUnit = moment(event.statusTime).format("MMM'YY").toUpperCase();
                }
                if (eventUnit === unit) {
                    this.highTransactionFlags.push(highTrxEvents[ i ]);
                    if (event.event === "LOW") {
                        LowFlag.column.data[ j ] += 1;
                        LowFlag.spline.data[ j ] += 1;
                    } else if (event.event === "MEDIUM") {
                        MediumFlag.column.data[ j ] += 1;
                        MediumFlag.spline.data[ j ] += 1;
                    } else if (event.event === "HIGH") {
                        HighFlag.column.data[ j ] += 1;
                        HighFlag.spline.data[ j ] += 1;
                    }
                    i++;
                } else {
                    break;
                }
            }
            if (this.period === "year") {
                startDate.add(1, "month");
            } else {
                startDate.add(1, "day");
            }

        }

        this.highTrxFlagsChart = new Chart({
            title: { text: "HIGH ALERTS For Transactions" },
            xAxis: { categories: units },
            yAxis: [{
                min: 0,
                title: {
                    text: "Count of Users"
                }
            }, {
                allowDecimals: false,
                opposite: true,
                title: { text: "Count" }
            }],
            legend: { shadow: false },
            tooltip: { shared: true },
            plotOptions: {
                column: {
                    borderWidth: 0,
                    dataLabels: { enabled: true },
                    stacking: "normal"
                }
            },
            series: [
                LowFlag.column,
                MediumFlag.column,
                HighFlag.column
            ]
        });
        this.progressBar.ref().complete();
    }

    /* Widget - Restrospective High user flags events history */
    getHighFlagsUserChartDetails() {
        this.progressBar.ref().start();
        let from, to;
        from = Date.now();
        const A_WEEK_OLD = moment(from).subtract(1, "week");
        const A_MONTH_OLD = moment(from).subtract(1, "month");
        const A_YEAR_OLD = moment(from).subtract(1, "year");
        let startDate = moment(from).subtract(1, "week").add(1, "day");
        const endDate = moment(Date.now());
        this.highUserFlags = [];
        if (this.period === "week") {
            startDate = moment(from).subtract(1, "week").add(1, "day");
        }
        if (this.period === "month") {
            startDate = moment(from).subtract(1, "month").add(1, "day");
        }
        if (this.period === "year") {
            startDate = moment(from).subtract(1, "year").add(1, "month");
        }
        const LowFlag: IInternalSeriesOptions = {
            column: {
                color: "#bfe500",
                data: [],
                name: "Low",
                type: "column"
            },
            spline: {
                color: "#C40051",
                data: [],
                name: "Low",
                yAxis: 1,
                type: "spline"
            }
        };

        const MediumFlag = {
            column: {
                color: "#ffa300",
                data: [],
                name: "Medium",
                type: "column"
            },
            spline: {
                color: "#C40051",
                data: [],
                name: "Medium",
                yAxis: 1,
                type: "spline"
            }
        };

        const HighFlag: IInternalSeriesOptions = {
            column: {
                color: "#96006b",
                data: [],
                name: "High",
                type: "column"
            },
            spline: {
                color: "#C40051",
                data: [],
                name: "High",
                yAxis: 1,
                type: "spline"
            }
        };

        let highUserEvents = this.highFlagEvents.filter((event) => {
            return (event.type === "retro_user") ? true : false;
        });
        let event;
        const units = [];
        let i = 0, j = 0;
        while (startDate.isSameOrBefore(endDate)) {
            let unit;
            if (this.period === "week") {
                unit = startDate.format("ddd").toUpperCase();
                highUserEvents = highUserEvents.filter((event) => moment(event.statusTime).isAfter(A_WEEK_OLD));
            }
            if (this.period === "month") {
                unit = startDate.format("DD'MM").toUpperCase();
                highUserEvents = highUserEvents.filter((event) => moment(event.statusTime).isAfter(A_MONTH_OLD));
            }
            if (this.period === "year") {
                unit = startDate.format("MMM'YY").toUpperCase();
                highUserEvents = highUserEvents.filter((event) => moment(event.statusTime).isAfter(A_YEAR_OLD));
            }
            if (units.indexOf(unit) < 0) {
                units.push(unit);
                j = units.length - 1;

                LowFlag.column.data.push(0);
                LowFlag.spline.data.push(0);
                MediumFlag.column.data.push(0);
                MediumFlag.spline.data.push(0);
                HighFlag.column.data.push(0);
                HighFlag.spline.data.push(0);

            }
            for (; i < highUserEvents.length;) {
                event = highUserEvents[ i ];
                let eventUnit;
                if (this.period === "week") {
                    eventUnit = moment(event.statusTime).format("ddd").toUpperCase();
                }
                if (this.period === "month") {
                    eventUnit = moment(event.statusTime).format("DD'MM").toUpperCase();
                }
                if (this.period === "year") {
                    eventUnit = moment(event.statusTime).format("MMM'YY").toUpperCase();
                }
                if (eventUnit === unit) {
                    this.highUserFlags.push(highUserEvents[ i ]);
                    if (event.event === "LOW") {
                        LowFlag.column.data[ j ] += 1;
                        LowFlag.spline.data[ j ] += 1;
                    } else if (event.event === "MEDIUM") {
                        MediumFlag.column.data[ j ] += 1;
                        MediumFlag.spline.data[ j ] += 1;
                    } else if (event.event === "HIGH") {
                        HighFlag.column.data[ j ] += 1;
                        HighFlag.spline.data[ j ] += 1;
                    }
                    i++;
                } else {
                    break;
                }
            }
            if (this.period === "year") {
                startDate.add(1, "month");
            } else {
                startDate.add(1, "day");
            }

        }

        this.highUserFlagsChart = new Chart({
            title: { text: "HIGH ALERTS For Users" },
            xAxis: { categories: units },
            yAxis: [{
                min: 0,
                title: {
                    text: "Count of Users"
                }
            }, {
                allowDecimals: false,
                opposite: true,
                title: { text: "Count" }
            }],
            legend: { shadow: false },
            tooltip: { shared: true },
            plotOptions: {
                column: {
                    borderWidth: 0,
                    dataLabels: { enabled: true },
                    stacking: "normal"
                }
            },
            series: [
                LowFlag.column,
                MediumFlag.column,
                HighFlag.column
            ]
        });
        this.progressBar.ref().complete();
    }

    /* Return true or false based on whether the current user can download USER AML Cases */
    isDownloadCasesAllowed(): boolean {
        return this.authenticationService.getCurrentUser().canDownloadTransactionCsv;
    }

    // Get the domain, country and currency info
    selectedCountry(data: any) {
        this.country = data;
        this.getAmlCases();
        this.getTransactionAlerts();
        this.callService();
    }

    setType(type: string): void {
        this.profileType = type;
        this.getAmlCases();
        this.getTransactionAlerts();
        this.callService();
    }

    selectStatus($event, status: string): void {
        this.status = status;
        this.callService();
    }

    selectType($event, type: string): void {
        if (type === "Tranactions") {
            this.transactionLimits = this.data.events.transaction;
        }
        if (type === "Velocity") {
            this.velocityLimits = this.data.events.velocity;
        }
        this.callService();
    }

    /* Action: Download the users' data as CSV */
    download(): void {
        this.progressBar.ref().start();

        const data: any[] = [];
        let userProfile: UserProfile;
        for (let i = 0; i < this.userProfiles.length; i++) {
            userProfile = this.userProfiles[ i ];

            data.push({
                PHONE: userProfile.phone,
                FILE: userProfile.kyc ? userProfile.kyc.file : ""
            });
        }

        const dataAsCSV = this.papaParse.unparse(data);
        const blob = new Blob([dataAsCSV], { type: "text/csv;charset=utf-8;" });
        const downloadLink = document.createElement("a");
        const url = URL.createObjectURL(blob);
        const isSafariBrowser = navigator.userAgent.indexOf("Safari") !== -1 && navigator.userAgent.indexOf("Chrome") === -1;
        if (isSafariBrowser) {  // if Safari open in new window to save file with random filename.
            downloadLink.setAttribute("target", "_blank");
        }

        const fileName = "userProfiles" + "_" + moment().format("YYYYMMDD") + (environment.production ? "" : ("_" + environment.name)) + ".csv";
        downloadLink.setAttribute("href", url);
        downloadLink.setAttribute("download", "userProfiles.csv");
        downloadLink.style.visibility = "hidden";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

        this.progressBar.ref().complete();
    }

    /* Download All USER Cases */
    downloadAllCases(): void {
        this.progressBar.ref().start();
        const dataForCsv: any[] = [];
        this.monitorService.getAmlCases({ country: this.country }).subscribe(response => {
            const data: any = { ...response };
            const casesData = {
                open: data.payload.cases.open.array || [],
                escalated: data.payload.cases.escalated.array || [],
                hold: data.payload.cases.hold.array || [],
                closed: data.payload.cases.closed.array || []
            };
            // @ts-ignore
            for (const status of Object.keys(casesData)) {
                for (let i = 0; i < casesData[ status ].length; i++) {
                    try {
                        let rowData = {
                            COUNTRYCODE: casesData[ status ][ i ].countryCode,
                            USER_ID: casesData[ status ][ i ].id,
                            TIME: casesData[ status ][ i ].time,
                            STATUS: casesData[ status ][ i ].status
                            // REASONS: this.flatten(casesData[ status ][ i ].reasons.transactions)
                        };
                        for (let j = 0; j < casesData[ status ][ i ].reasons.transactions.length; j++) {
                            const trx = "TRXID_" + j;
                            const rule = "RULE_" + j;
                            const date = "DATE_" + j;
                            rowData[ trx ] = casesData[ status ][ i ].reasons.transactions[ j ].trxId;
                            rowData[ rule ] = Object.keys(casesData[ status ][ i ].reasons.transactions[ j ].rules);
                            rowData[ date ] = casesData[ status ][ i ].reasons.transactions[ j ].creationDate;
                        }
                        dataForCsv.push(rowData);
                    } catch (error) {
                        console.log("Error while trying to add case #" + casesData[ status ][ i ].id + "\n" + error);
                        this.toastr.error("Error while trying to add transaction #" + casesData[ status ][ i ].id, "");
                    }
                }
            }

            const dataAsCSV = this.papaParse.unparse(dataForCsv);
            const blob = new Blob([dataAsCSV], { type: "text/csv;charset=utf-8;" });
            const downloadLink = document.createElement("a");
            const url = URL.createObjectURL(blob);
            const isSafariBrowser = navigator.userAgent.indexOf("Safari") !== -1 && navigator.userAgent.indexOf("Chrome") === -1;
            if (isSafariBrowser) {  // if Safari open in new window to save file with random filename.
                downloadLink.setAttribute("target", "_blank");
            }
            const fileName = "cases_" + moment().format("YYYYMMDD") + (environment.production ? "" : ("_" + environment.name)) + ".csv";
            downloadLink.setAttribute("href", url);
            downloadLink.setAttribute("download", fileName);
            downloadLink.style.visibility = "hidden";
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);

            this.progressBar.ref().complete();
            this.toastr.success("Success", "Successfully Downloaded!");
        });
    }

    /* Download Filtered USER Cases */
    downloadCases(): void {
        this.progressBar.ref().start();
        const dataForCsv: any[] = [];
        const casesData = {
            open: this.cases.open.array || [],
            escalated: this.cases.escalated.array || [],
            hold: this.cases.hold.array || [],
            closed: this.cases.closed.array || []
        };
        // @ts-ignore
        for (const status of Object.keys(casesData)) {
            for (let i = 0; i < casesData[ status ].length; i++) {
                try {
                    let rowData = {
                        USER_ID: casesData[ status ][ i ].id,
                        TIME: casesData[ status ][ i ].time,
                        STATUS: casesData[ status ][ i ].status
                        // REASONS: this.flatten(casesData[ status ][ i ].reasons.transactions)
                    };
                    for (let j = 0; j < casesData[ status ][ i ].reasons.transactions.length; j++) {
                        const trx = "TRXID_" + j;
                        const rule = "RULE_" + j;
                        const date = "DATE_" + j;
                        rowData[ trx ] = casesData[ status ][ i ].reasons.transactions[ j ].trxId;
                        rowData[ rule ] = Object.keys(casesData[ status ][ i ].reasons.transactions[ j ].rules);
                        rowData[ date ] = casesData[ status ][ i ].reasons.transactions[ j ].creationDate;
                    }
                    dataForCsv.push(rowData);
                } catch (error) {
                    console.log("Error while trying to add case #" + casesData[ status ][ i ].id + "\n" + error);
                    this.toastr.error("Error while trying to add transaction #" + casesData[ status ][ i ].id, "");
                }
            }
        }

        const dataAsCSV = this.papaParse.unparse(dataForCsv);
        const blob = new Blob([dataAsCSV], { type: "text/csv;charset=utf-8;" });
        const downloadLink = document.createElement("a");
        const url = URL.createObjectURL(blob);
        const isSafariBrowser = navigator.userAgent.indexOf("Safari") !== -1 && navigator.userAgent.indexOf("Chrome") === -1;
        if (isSafariBrowser) {  // if Safari open in new window to save file with random filename.
            downloadLink.setAttribute("target", "_blank");
        }
        const fileName = "cases_" + moment().format("YYYYMMDD") + (environment.production ? "" : ("_" + environment.name)) + ".csv";
        downloadLink.setAttribute("href", url);
        downloadLink.setAttribute("download", fileName);
        downloadLink.style.visibility = "hidden";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

        this.progressBar.ref().complete();
        this.toastr.success("Success", "Successfully Downloaded!");
    }

    /* Download All USER Trx Alerts */
    downloadAllAlerts(): void {
        this.progressBar.ref().start();
        const dataForCsv: any[] = [];
        this.monitorService.getTransactionAlerts({filter: { downloadAll: true} }).subscribe(response => {
            const data: any = { ...response };
            for (let i = 0; i < data.payload.alerts.length; i++) {
                try {
                    const rowData = {
                        TRX_ID: data.payload.alerts[ i ].trxId,
                        FLAG: data.payload.alerts[ i ].flag,
                        COR: data.payload.alerts[ i ].cor,
                        TYPE: data.payload.alerts[ i ].type,
                        NAME: data.payload.alerts[ i ].name,
                        VERIFICATION_LEVEL: data.payload.alerts[ i ].verificationLevel,
                        TIME: data.payload.alerts[ i ].time
                    };
                    dataForCsv.push(rowData);
                } catch (error) {
                    console.log("Error while trying to add alert for trxId #" + this.postTrx[ i ].trxId + "\n" + error);
                    this.toastr.error("Error while trying to add alert for trxId #" + this.postTrx[ i ].trxId, "");
                }
            }

            const dataAsCSV = this.papaParse.unparse(dataForCsv);
            const blob = new Blob([dataAsCSV], { type: "text/csv;charset=utf-8;" });
            const downloadLink = document.createElement("a");
            const url = URL.createObjectURL(blob);
            const isSafariBrowser = navigator.userAgent.indexOf("Safari") !== -1 && navigator.userAgent.indexOf("Chrome") === -1;
            if (isSafariBrowser) {  // if Safari open in new window to save file with random filename.
                downloadLink.setAttribute("target", "_blank");
            }
            const fileName = "cases_" + moment().format("YYYYMMDD") + (environment.production ? "" : ("_" + environment.name)) + ".csv";
            downloadLink.setAttribute("href", url);
            downloadLink.setAttribute("download", fileName);
            downloadLink.style.visibility = "hidden";
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);

            this.progressBar.ref().complete();
            this.toastr.success("Success", "Successfully Downloaded!");
        });
    }

    /* Download Filtered USER Trx Alerts */
    downloadAlerts(): void {
        this.progressBar.ref().start();
        const dataForCsv: any[] = [];
        // @ts-ignore
        for (let i = 0; i < this.postTrx.length; i++) {
            try {
                let rowData = {
                    TRX_ID: this.postTrx[ i ].trxId,
                    FLAG: this.postTrx[ i ].flag,
                    COR: this.postTrx[ i ].cor,
                    NAME: this.postTrx[ i ].name,
                    VERIFICATION_LEVEL: this.postTrx[ i ].verificationLevel,
                    TIME: this.postTrx[ i ].time
                    // REASONS: this.flatten(casesData[ status ][ i ].reasons.transactions)
                };
                dataForCsv.push(rowData);
            } catch (error) {
                console.log("Error while trying to add alert for trxId #" + this.postTrx[ i ].trxId + "\n" + error);
                this.toastr.error("Error while trying to add alert for trxId #" + this.postTrx[ i ].trxId, "");
            }
        }

        const dataAsCSV = this.papaParse.unparse(dataForCsv);
        const blob = new Blob([dataAsCSV], { type: "text/csv;charset=utf-8;" });
        const downloadLink = document.createElement("a");
        const url = URL.createObjectURL(blob);
        const isSafariBrowser = navigator.userAgent.indexOf("Safari") !== -1 && navigator.userAgent.indexOf("Chrome") === -1;
        if (isSafariBrowser) {  // if Safari open in new window to save file with random filename.
            downloadLink.setAttribute("target", "_blank");
        }
        const fileName = "cases_" + moment().format("YYYYMMDD") + (environment.production ? "" : ("_" + environment.name)) + ".csv";
        downloadLink.setAttribute("href", url);
        downloadLink.setAttribute("download", fileName);
        downloadLink.style.visibility = "hidden";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

        this.progressBar.ref().complete();
        this.toastr.success("Success", "Successfully Downloaded!");
    }


    rtraRolling(period: string): void {
        this.period = period;
        this.getVelocityLimitsChartDetails();
        this.getTransactionLimitsChartDetails();
    }


    redFlagsRolling(period: string): void {
        this.period = period;
        this.getRedFlagsTrxChartDetails();
        this.getRedFlagsUserChartDetails();
    }

    highFlagsRolling(period: string): void {
        this.period = period;
        this.getHighFlagsTrxChartDetails();
        this.getHighFlagsUserChartDetails();
    }

    searchByFilter(): void {
        this.progressBar.ref().start();
        this.getAmlCases();
        this.progressBar.ref().complete();
    }

    /* Actions - search User Profiles */
    searchUserProfiles(): void {
        this.page = 1;
        this.callService();
    }

    paginate(e) {
        this.page = e;
        this.callService();
    }

    /* Return true or false based on whether the current user can download CSV */
    isDownloadAllowed(): boolean {
        return (this.authenticationService.getCurrentUser().isAccountLegal);
    }

    public getClassByCaseStatus(status: CaseStatus): string {
        return monitorCaseStatusToClassConfig.get(status) || "";
    }

    /* Call the service */
    private callService(): void {
        this.progressBar.ref().start();
        this.userProfiles = [];

        const filterOptions = {
            country: null
        };
        this.filter.country ? filterOptions.country = this.filter.country : delete filterOptions.country;

        this.monitorService.getAmlData({ country: this.country, filter: { type: this.profileType } }).subscribe(response => {
            const data = { ...response };
            // this.amlData = data.payload;
            this.data = data.payload;
            this.redFlagEvents = this.data.events.redFlags;
            this.highFlagEvents = this.data.events.highFlags;
            this.transactionLimits = this.data.events.transaction;
            this.velocityLimits = this.data.events.velocity;
            // can't find total at BE????
            this.collectionSize = (data.payload as any).total;
            this.getTransactionLimitsChartDetails();
            this.getVelocityLimitsChartDetails();
            this.getRedFlagsTrxChartDetails();
            this.getRedFlagsUserChartDetails();
            this.getHighFlagsTrxChartDetails();
            this.getHighFlagsUserChartDetails();
            this.progressBar.ref().complete();
        }, error => {
            this.handleError(error, false);
            this.progressBar.ref().complete();
        });

    }

    // Flatten a json object
    private flatten(obj: any) {
        return Object.keys(obj).reduce((acc, current) => {
            const _key = `${current}`;
            const currentValue = obj[ current ];
            if (Array.isArray(currentValue) || Object(currentValue) === currentValue) {
                Object.assign(acc, this.flatten(currentValue));
            } else {
                acc[ _key ] = currentValue;
            }
            return acc;
        }, {});
    }

    /* Error Handler */
    private handleError(error: any, showModal: boolean) {
        if (error.status == 0) { // or whatever condition you like to put
            this.toastr.error("Internet connection failed!", "Network error");
        } else if (error.status === 401 && error.statusText === "Unauthorized") {
            this.toastr.error("Unauthorized User", "User logout");
            this.authenticationService.logout();
            this.router.navigate(["/login"]);
        } else {
            let response = error.error;
            if (typeof response === "string") {
                response = JSON.parse(response);
            }
            this.toastr.error(response.message, response.result);
            if (showModal) {
                const modalRef = this.modalService.open(ModalComponent);
                modalRef.componentInstance.modalOptions = new ModalOptions(Severity.ERROR, error.statusText, response.message, null);
            }
        }
    }
}
