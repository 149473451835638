/**
 * Created by Arindam Bajpayee on 25-04-2017.
 */
// import { AngularMaterialModule } from './angular-material.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
/* 3rd party modules */
import { AgmCoreModule } from "@agm/core"; // for angular 2 googlelemaps component
import { ChartModule } from "angular-highcharts";
import { LaddaModule } from "angular2-ladda";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgIdleKeepaliveModule } from "@ng-idle/keepalive"; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { NgProgressModule } from "@ngx-progressbar/core";
import { ToastrModule } from "ngx-toastr";
/* App-root */
import { AppComponent } from "./app.component";
/* App Modules / Components */
import { AdminModule } from "./components/admin/admin.module";
import { LoginComponent } from "./components/login/login.component";
import { ModalComponent } from "./components/modal/modal.component";
/* Services */
import { AccountService } from "./services/account.service";
import { AlertService } from "./services/alert.service";
import { AtmService } from "./services/atm.service";
import { ConfigurationService } from "./services/configuration.service";
import { DataTableStateService } from "./services/data-table-state.service";
import { JobService } from "./services/job.service";
import { LimitService } from "./services/limit.service";
import { MigrationsService } from "./services/migrations.service";
import { MonitorService } from "./services/monitor.service";
import { PartnerService } from "./services/partner.service";
import { PaymentService } from "./services/payment.service";
import { PusherService } from "./services/pusher/pusher.service";
import { ReportService } from "./services/report.service";
import { TransactionService } from "./services/transaction.service";
import { UserService } from "./services/user.service";
import { SpotlightService } from "./services/spotlight.service";

import { HttpErrorInterceptor } from "./services/http-interceptor.service";
/* Routes */
import { AppRoutingModule } from "./app-routing.module";
import { AuthGuard } from "./guards/authentication.guard";

import { environment } from "../environments/environment";

@NgModule({
    bootstrap: [AppComponent],
    declarations: [
        AppComponent,
        LoginComponent,
        ModalComponent
    ],
    imports: [
        // Angular Material
        // AngularMaterialModule,
        // 3rd Party
        AgmCoreModule.forRoot({
            apiKey: environment.google.apiKey,
            libraries: ["places"]
        }),
        BrowserModule,
        BrowserAnimationsModule,
        ChartModule,
        FormsModule,
        LaddaModule,
        NgbModule,
        NgIdleKeepaliveModule.forRoot(),
        NgProgressModule,
        ToastrModule.forRoot({
            closeButton: true,
            countDuplicates: true,
            enableHtml: true,
            extendedTimeOut: 5000,
            positionClass: "toast-bottom-right",
            preventDuplicates: true,
            progressBar: true,
            resetTimeoutOnDuplicate: true,
            tapToDismiss: false,
            timeOut: 60000
        }),
        // Feature Modules
        AdminModule,
        // Routing - keep it at the end
        AppRoutingModule,
        HttpClientModule
    ],
    providers: [
        AuthGuard,
        AccountService,
        AlertService,
        AtmService,
        ConfigurationService,
        DataTableStateService,
        JobService,
        LimitService,
        MigrationsService,
        MonitorService,
        PusherService,
        PartnerService,
        PaymentService,
        ReportService,
        TransactionService,
        UserService,
        SpotlightService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true
        }
    ]
})
export class AppModule {
}
