import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpErrorResponse
} from "@angular/common/http";
import {Injectable } from "@angular/core";

import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import {AuthenticationService} from "./authentication.service";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {NgProgress} from "@ngx-progressbar/core";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,
        private toastr: ToastrService,
        private progressBar: NgProgress,
        private authenticationService: AuthenticationService
    ){

    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    if (error.status === 401 && error.url.includes(this.authenticationService.URL_2FA)) {
                        // when the user deny the 2FA for KYC ownel details the 401 returned from BE => we don't want to loggout user at this point
                        return throwError(error);
                    }

                    if (error.status === 401 && Boolean(this.authenticationService.isUserLoggedIn())) {
                        localStorage.removeItem("currentUser");
                        localStorage.setItem("LOGOUT", Date.now().toString());
                        localStorage.removeItem("LOGOUT");
                        localStorage.clear();
                        this.toastr.error("Unauthorized User", "User logout");
                        this.router.navigate(["/login"]);
                        this.progressBar.ref().complete();
                    }

                    return throwError(error);
                })
            );
    }
}
