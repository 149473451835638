/**
 * Created by Surya Vedula on 20-06-2019.
 */

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { ConfigurationService } from "../../../services/configuration.service";
import { Country } from "../../../models/country";
import { EMPTY, Observable, Subject } from "rxjs";
import { takeUntil, tap } from "rxjs/operators";
import { AuthenticationService } from "../../../services/authentication.service";

export interface SelectedCountryData {
    code: string;
    currency: string;
    name: string;
}

@Component({
    selector: "bo-domain-selector",
    styleUrls: [ "domain-selector.component.css" ],
    templateUrl: "domain-selector.component.html"
})

export class DomainSelectorComponent implements OnInit, OnDestroy {
    loaded = false;
    _countryList: Country[];
    countryName: string;
    currency: string;
    private unsubscribe$ = new Subject<any>();

    @Input() showEEA;

    @Input() set data(values: Country[]) {
        this._countryList = values;
    }

    @Input() initialCountryCode;
    @Input() showCurrency = true;
    @Output() out = new EventEmitter<SelectedCountryData>();
    @Output() countryListEvent = new EventEmitter<Country[]>();

    constructor(
        private configurationService: ConfigurationService,
        private authenticationService: AuthenticationService) {
    }

    ngOnInit(): void {
        this.getCountryDetails()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                this.changeCountry(this.initialCountryCode || this.authenticationService.getCurrentCountryCode());
                this.loaded = true;
                this.countryListEvent.emit(this._countryList);
            });
    }

    changeCountry(code) {
        const { name, currency } = this._countryList.find((e) => e.cca2 === code);
        this.currency = currency;
        this.authenticationService.setCurrentCountryCode(code);
        this.authenticationService.setCurrentCountry({ code, currency, name });
        this.countryName = name;
        this.out.emit({
            name,
            currency,
            code
        });
    }

    getCountryDetails(): Observable<any> {
        /*Widget - Country Data */
        if (!this._countryList) {
            return this.configurationService.getCountryData().pipe(
                tap(response => {
                    this._countryList = response.countries.sort((a, b) => {
                        if (a.name < b.name) {
                            return -1;
                        }
                        if (a.name > b.name) {
                            return 1;
                        }
                        return 0;
                    });

                    const currencies = this._countryList.map(item => ({currency: item.currency, country: item.country}));
                    sessionStorage.setItem("currencies", JSON.stringify(currencies));

                    // Hack for all EU countries
                    if (this.showEEA) {
                        this._countryList.push({
                            country: "EEA",
                            name: "EEA",
                            currency: "EUR",
                            callingCode: "0",
                            cca2: "EU",
                            cca3: "EU",
                            region: "Europe",
                            subregion: "All Europe",
                            tld: [".eu"],
                        });
                    }
                }),
                takeUntil(this.unsubscribe$));
        } else {
            return EMPTY;
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
