import { animate, state, style, transition, trigger } from "@angular/animations";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgProgress } from "@ngx-progressbar/core";
import { ToastrService } from "ngx-toastr";
/* Components */
import { ModalComponent, ModalOptions, Severity } from "../../../modal/modal.component";
/* Models */
import { AuditLimitBreach } from "../../../../models/audit";
/* Services */
import { AtmService } from "../../../../services/atm.service";
import { AuthenticationService } from "../../../../services/authentication.service";
import { PusherService } from "../../../../services/pusher/pusher.service";
import { MatTableDataSource } from "@angular/material/table";


@Component({
    selector: "bo-audits-atm",
    templateUrl: "./audits-atm.component.html",
    styleUrls: [ "./audits-atm.component.scss" ],
    animations: [
        trigger("detailExpand", [
            state("collapsed", style({ height: "0px", minHeight: "0", display: "none" })),
            state("expanded", style({ height: "*" })),
            transition("expanded <=> collapsed", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)"))
        ])
    ]
})
export class AuditsAtmComponent implements OnInit {
    country = {
        code: "CH",
        currency: "CHF",
        name: "Switzerland"
    };
    date: Date = new Date();
    dataTableColumns: string[] = [ "date", "type", "name", "time", "amount" ];
    dataTableSource: MatTableDataSource<AuditLimitBreach> = new MatTableDataSource([]);
    isLoading = false;
    audits: AuditLimitBreach[] = [];
    selectedTab = "list";

    constructor(private router: Router,
                public activeModal: NgbActiveModal,
                public dialog: MatDialog,
                private modalService: NgbModal,
                private progressBar: NgProgress,
                private authenticationService: AuthenticationService,
                private pusherService: PusherService,
                private atmService: AtmService,
                private toastr: ToastrService) {
    }

    /* LIFECYCLE */
    ngOnInit(): void {
        if (!this.authenticationService.isUserLoggedIn()) {
            this.router.navigate([ "/login" ]);
            return;
        }
        this.getAuditsForLimitBreaches();
    }

    /* FUNCTIONS */
    selectedCountry(data: any) {
        this.country = data;
        this.getAuditsForLimitBreaches();
    }

    selectTab($event, tab) {
        this.selectedTab = tab;
        if (tab === "list") {
            this.getAuditsForLimitBreaches();
        }
    }

    /* PRIVATE */
    private getAuditsForLimitBreaches() {
        this.progressBar.ref().start();
        this.atmService.getAuditsForLimitBreaches(this.country).subscribe(response => {
            const data: any = { ...response };
            this.audits = data.payload.audits;
            this.dataTableSource.data = this.audits;
            this.progressBar.ref().complete();
            this.isLoading = false;
        }, error => {
            this.handleError(error, true);
            this.progressBar.ref().complete();
            this.isLoading = false;
        });
    }

    private handleError(error: any, showModal: boolean) {
        if (error.status === 401 && error.statusText === "Unauthorized") {
            this.toastr.error("Unauthorized User", "User logout");
            this.authenticationService.logout();
            this.router.navigate([ "/login" ]);
        } else {
            let response = error.error;
            if (typeof response === "string") {
                response = JSON.parse(response);
            }
            this.toastr.error(response.message, response.result);
            if (showModal) {
                const modalRef = this.modalService.open(ModalComponent);
                modalRef.componentInstance.modalOptions = new ModalOptions(Severity.ERROR, error.statusText, response.message, null);
            }
        }
    }
}
