<div class="page-title">
    Profile
</div>

<div class="panel-body" style="background-color:white;">
    <!-- <form #profileForm="ngForm" class="form-validation"> -->
    <form #profileForm="ngForm" class="form-validation">

        <!-- Profile details -->
        <div class="modal-body profile-details">
            <!-- Account Details -->
            <div class="row row-eq-height">
                <div class="col-lg-12">
                    <div class="panel profile-contact">
                        <div class="panel-heading">
                            <h3>Details</h3>
                        </div>
                        <div class="panel-body">
                            <p class="margin-bottom-3"><i class="left-menu-link-icon fas fa-user margin-right-5"></i> {{ account.name }}</p>
                            <p class="margin-bottom-3"><i class="left-menu-link-icon fas fa-user margin-right-5"></i> {{ account.user }}</p>
                            <p class="margin-bottom-3"><i class="left-menu-link-icon far fa-at margin-right-5"></i> {{ account.email }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row row-eq-height">
                <div class="col-lg-6 col-md-12">
                    <div class="panel background-light-grey">
                        <div class="panel-heading clear-fix">
                            <h5>Change password
                                <a (click)="resetPassword($event)" style="padding-left:10px;">Reset password</a>
                                <a (click)="editable=true" *ngIf="!editable" [hidden]="editable" style="padding-left:10px;">Edit</a>
                                <a (click)="save($event, 'changePassword')" *ngIf="editable" [hidden]="!editable" style="padding-left:10px;">Save</a>
                                <a (click)="cancel()" *ngIf="editable" [hidden]="!editable" style="padding-left:10px;">Cancel</a>
                            </h5>
                        </div>
                        <div class="panel-body">
                            <div class="row">
                                <!-- Current password -->
                                <div class="col-lg-12">
                                    <mat-form-field [ngClass]="currentPassword.errors && ( currentPassword.dirty || currentPassword.touched ) ? 'form-control-input-error' : ''" class="width-full">
                                        <input #currentPassword="ngModel" [ngModelOptions]="{standalone: true}" [(ngModel)]="password.current" [readonly]="!editable ? true : null" [type]="hideCurrent ? 'password' : 'text'" matInput
                                               minlength="5" name="currentPassword" placeholder="Current password" required>
                                        <mat-icon (click)="hideCurrent = !hideCurrent" matSuffix>{{ hideCurrent ? 'visibility_off' : 'visibility' }}</mat-icon>
                                        <mat-error *ngIf="editable && password.current.length === 0">You must enter your password</mat-error>
                                    </mat-form-field>
                                </div>

                                <!-- New password -->
                                <div class="col-lg-12">
                                    <mat-form-field [ngClass]="newPassword.errors && ( newPassword.dirty || newPassword.touched ) ? 'form-control-input-error' : ''" class="width-full">
                                        <input #newPassword="ngModel" [ngModelOptions]="{standalone: true}" [(ngModel)]="password.new" [readonly]="!editable ? true : null" [type]="hideNew ? 'password' : 'text'" matInput
                                               minlength="5" name="newPassword" placeholder="New password" required>
                                        <mat-icon (click)="hideNew = !hideNew" matSuffix>{{ hideNew ? 'visibility_off' : 'visibility' }}</mat-icon>
                                        <mat-error *ngIf="editable && password.new.length < 5">Password must be atleast 5 characters long!</mat-error>
                                        <mat-error *ngIf="editable && password.new.length > 0 && password.confirm.length > 0 && password.new !== password.confirm">Passwords do not match!</mat-error>
                                    </mat-form-field>
                                </div>

                                <!-- New password - confirm -->
                                <div class="col-lg-12">
                                    <mat-form-field [ngClass]="confirmPassword.errors && ( confirmPassword.dirty || confirmPassword.touched ) ? 'form-control-input-error' : ''" class="width-full">
                                        <input #confirmPassword="ngModel" [ngModelOptions]="{standalone: true}" [(ngModel)]="password.confirm" [readonly]="!editable ? true : null" [type]="hideConfirm ? 'password' : 'text'" matInput
                                               minlength="5" name="confirmPassword" placeholder="Confirm new password" required>
                                        <mat-icon (click)="hideConfirm = !hideConfirm" matSuffix>{{ hideConfirm ? 'visibility_off' : 'visibility' }}</mat-icon>
                                        <mat-error *ngIf="editable && password.confirm.length < 5">Password must be atleast 5 characters long!</mat-error>
                                        <mat-error *ngIf="editable && password.new.length > 0 && password.confirm.length > 0 && password.new !== password.confirm">Passwords do not match!</mat-error>
                                    </mat-form-field>
                                </div>
                                <!--
                                <div *ngIf="editable">
                                    <div *ngIf="submitted && (password.current === '' || password.new === '' || password.confirm === '' )" style="color: red">All fields are mandatory.</div>
                                    <div *ngIf="submitted && ( confirmPassword.dirty || confirmPassword.touched || currentPassword.dirty || currentPassword.touched || newPassword.dirty || newPassword.touched ) &&( confirmPassword?.errors?.minlength || newPassword?.errors?.minlength || currentPassword?.errors?.minlength)"
                                         style="color: red">Password must be at least 5 characters long.
                                    </div>
                                    <div *ngIf="submitted && ( confirmPassword.dirty || confirmPassword.touched )">
                                        <div *ngIf="password.confirm !== password.new" style="color: red">Confirm password doesn't match.</div>
                                    </div>
                                </div>
                                -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
