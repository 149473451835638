/**
 * Created by Surya on 28-02-2021.
 */

export enum CaseStatus {
    Opened = "OPENED",
    Escalated = "ESCALATED",
    OnHold = "ON_HOLD",
    Closed = "CLOSED"
}

export const monitorCaseStatusToClassConfig: Map<CaseStatus, string> = new Map([
    [ CaseStatus.Opened, "sonect-orange-bg" ],
    [ CaseStatus.Escalated, "sonect-flamingo-bg" ],
    [ CaseStatus.OnHold, "sonect-grey-bg" ],
    [ CaseStatus.Closed, "sonect-lime-bg" ]
]);
