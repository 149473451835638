/**
 * Created by Arindam Bajpayee on 25-04-2017.
 */
import { MediaMatcher } from "@angular/cdk/layout";
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationService } from "../../services/authentication.service";

import pusherEvents from "../../services/pusher/events-list";
import { NavigationPaymentsGuard } from "../../guards/navigation-payments.guard";
import { PusherService } from "../../services/pusher/pusher.service";
import { ConfigurationService } from "../../services/configuration.service";
import { ToastrService } from "ngx-toastr";
import { ModalAction, ModalComponent, ModalOptions, Severity } from "../modal/modal.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DataTableStateService } from "src/app/services/data-table-state.service";

@Component({
    selector: "bo-admin",
    templateUrl: "./admin.component.html",
    styleUrls: [ "./admin.component.css" ]
})
export class AdminComponent implements AfterViewInit, OnDestroy, OnInit {
    @ViewChild("drawer", { static: false }) drawer: any;
    mobileQuery: MediaQueryList;
    title = "This is the ADMIN UI!";
    modalRef = null;

    private _mobileQueryListener: () => void;

    constructor(private changeDetectorRef: ChangeDetectorRef,
                private pusherService: PusherService,
                private toastr: ToastrService,
                private modalService: NgbModal,
                private configurationService: ConfigurationService,
                private media: MediaMatcher,
                private router: Router,
                private authenticationService: AuthenticationService,
                private dataTableStateService: DataTableStateService
                ) {
        this.mobileQuery = media.matchMedia("(max-width: 600px)");
        this._mobileQueryListener = () => changeDetectorRef.detectChanges();
        this.mobileQuery.addListener(this._mobileQueryListener);
    }

    ngAfterViewInit(): void {
        if (!this.pusherService.isConnected("admin")) {
            const user = this.getCurrentUser();
            for (const role of user.roles) {
                this.pusherService.subscribeToChannel(
                    role,
                    [ pusherEvents.USER_RIGHTS_UPDATED ],
                    (data) => {
                        const message = `Rights for role '${role}' updated. You will be logged out in 30 sec`;
                        const title = "Rights updated";
                        this.toastr.warning(message, title);
                    }
                );
                this.pusherService.subscribeToChannel(role, [ pusherEvents.LOGOUT ], (data) => this.logout());
            }

            // Notify user if backoffice version was changed
            this.pusherService.subscribeToChannel("notifications", [ "sonect-backoffice-version" ], ({ version }) => {
                const backofficeVersion = sessionStorage.getItem("sonect-backoffice-version");
                if (backofficeVersion) {
                    if (backofficeVersion !== version) {
                        this.modalRef = this.modalService.open(ModalComponent);
                        const parameters = { parentComponent: this };
                        this.modalRef.componentInstance.modalOptions =
                            new ModalOptions(
                                Severity.WARNING,
                                "New Backoffice version deployed",
                                "Please refresh this page to activate it!",
                                [
                                    new ModalAction("Refresh", parameters, () => location.reload())
                                ]);
                    }
                } else {
                    sessionStorage.setItem("sonect-backoffice-version", version);
                }
            });
        }
    }

    ngOnDestroy(): void {
        this.mobileQuery.removeListener(this._mobileQueryListener);
    }

    ngOnInit(): void {
        if (!this.authenticationService.isUserLoggedIn()) {
            this.router.navigate([ "/login" ]);
            return;
        }

        // Get backoffice backend version and save to session storage
        this.configurationService.getServerInfo().subscribe(response => {
            const { version }: any = { ...response };
            sessionStorage.setItem("sonect-backoffice-version", version);
        });
    }

    getCurrentUser() {
        return this.authenticationService.getCurrentUser();
    }

    /* Actions */
    logout(): void {
        this.authenticationService.logout();
        this.pusherService.unsubscribe();
        this.router.navigate([ "/login" ]);
    }

    menuItemClicked(): void {
        this.dataTableStateService.resetAllTables();
        if (this.mobileQuery.matches && this.drawer._mode === "over") {
            this.drawer.close();
        }
    }

    get showPayments(): boolean {
        const user = this.authenticationService.getCurrentUser() || {};
        return NavigationPaymentsGuard.isUserAllowed(user);
    }
}
