/**
 * Created by Arindam Bajpayee on 28-04-2017.
 */
import { Injectable } from "@angular/core";
// import { Http,Response }        from "@angular/http";
import { HttpClient } from "@angular/common/http";
// import { Observable } from "rxjs/Rx";
import { map } from "rxjs/operators";

import { environment } from "../../environments/environment";
import { AuthenticationService } from "./authentication.service";
import { Transaction, TransactionHistory } from "../models/transaction";

export enum LimitStatusColor {
    Green,
    Orange,
    Red
}

@Injectable()
export class TransactionService {
    constructor(private http: HttpClient,
        private authenticationService: AuthenticationService) {
    }

    /**
     * Cancel a transaction. Only a pending SendCash transaction (type: 2) can be cancelled by Admin or Support
     * @param transaction - the transaction to cancel
     * @param comments - the reason for the cancellation
     */
    cancelTransaction(transaction: Transaction, comments: string) {
        const httpOptions = {
            headers: this.authenticationService.getHeaders().headers,
            body: { comments }
        };
        return this.http.delete(`${environment.baseurl.api}/protected/transaction/${transaction.trxId}`, httpOptions).pipe(map(res => res));
    }

    convertTransaction(transaction: Transaction): Transaction {
        const lastHistoryItem: TransactionHistory = transaction.history && transaction.history[transaction.history.length - 1];
        const to = lastHistoryItem?.what?.to;
        if (to?.name === "n.a.") {
            return {
                ...transaction,
                from: { ...transaction.from, ...to }
            };
        }
        return transaction;
    }

    /* For Transactions page */

    /* Get the balance */
    getBalance(parameters) {
        return this.http.post(
            environment.baseurl.api + "/protected/transaction/balance",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                type: parameters.type,
                currency: parameters.currency,
                identifier: parameters.identifier,
                masterIdentifier: parameters.masterIdentifier,
                country: parameters.country
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    /* For transactions page */
    getTransactions(parameters) {
        return this.http.post(
            environment.baseurl.api + "/protected/transaction/all",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                filter: parameters.filter,
                from: parameters.from,
                to: parameters.to,
                country: parameters.country,
                status: parameters.status,
                page: parameters.page
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    /* For dashboard widgets */
    getTransactionsAmount(parameters) {
        return this.http.post(
            environment.baseurl.api + "/protected/transaction/amount",
            {
                country: parameters.country,
                currentUser: this.authenticationService.getCurrentUser(),
                currency: parameters.currency,
                frequency: parameters.frequency,
                filter: parameters.filter
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    getTransactionsAmountByPaymentMethod(currency: string, filter: any, country: any) {
        return this.http.post(
            environment.baseurl.api + "/protected/transaction/amount/payment",
            {
                country,
                currentUser: this.authenticationService.getCurrentUser(),
                currency,
                filter
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    getTransactionsHistory(parameters) {
        return this.http.post(
            environment.baseurl.api + "/protected/transaction/history",
            {
                country: parameters.country,
                currentUser: this.authenticationService.getCurrentUser(),
                currency: parameters.currency,
                filter: parameters.filter
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    /* For payout to partner */
    initiatePayout(type: string, currency: string, partner: string) {
        return this.http.post(
            environment.baseurl.api + "/protected/transaction/initiatePayout",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                type,
                currency,
                partner
            }, this.authenticationService.getHeadersForText()
        ).pipe(map(res => res));
    }

    /* For balance reset */
    resetBalance(parameters) {
        return this.http.post(
            environment.baseurl.api + "/protected/transaction/resetBalance",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                type: parameters.type,
                currency: parameters.currency,
                balance: parameters.balance,
                identifier: parameters.identifier,
                country: parameters.country,
                payout: parameters.payout
            }, this.authenticationService.getHeadersForText()
        ).pipe(map(res => res));
    }

    /* For balance reset all */
    resetAllBalance(type: string, currency: string, country: any) {
        return this.http.post(
            environment.baseurl.api + "/protected/transaction/resetAllBalance",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                type,
                currency,
                country
            }, this.authenticationService.getHeadersForText()
        ).pipe(map(res => res));
    }

    calculateLimitStatus(limitLeft: number, limit: number): LimitStatusColor {
        const onePercent = limit / 100; // 1%
        const usedInPersent = Math.round((limit - limitLeft) / onePercent); // how much used in % from 100%
        let status: LimitStatusColor;

        if (usedInPersent <= 90) {
            status = LimitStatusColor.Green;
        } else if (usedInPersent >= 90 && usedInPersent !== 100) {
            status = LimitStatusColor.Orange;
        } else {
            status = LimitStatusColor.Red;
        }

        return status;
    }
}
