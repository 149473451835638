/**
 * Created by Arindam Bajpayee on 13-02-2020.
 */
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";

import { environment } from "../../environments/environment";
import { AuthenticationService } from "./authentication.service";

@Injectable()
export class AlertService {
    constructor(private http: HttpClient,
                private authenticationService: AuthenticationService) {
    }

    /* Get alerts for dashboard */
    getAlertsForDashboard(countryCode: string = "CH") {
        let path = "/protected/alert/dashboard";
        if (countryCode) {
            path = path + "?countryCode=" + countryCode;
        }
        return this.http.get(
            environment.baseurl.api + path,
            this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }
}
