import { AfterViewInit, ChangeDetectorRef, Component, ViewChild } from "@angular/core";

@Component({
    selector: "bo-panel",
    templateUrl: "./panel.component.html",
    styleUrls: [ "./panel.component.scss" ]
})
export class PanelComponent implements AfterViewInit {
    @ViewChild("panelActions") panelActions;
    @ViewChild("panelBody") panelBody;
    @ViewChild("panelFooter") panelFooter;
    @ViewChild("panelHeader") panelHeader;

    showActions = false;
    showBody = false;
    showFooter = false;
    showHeader = false;

    constructor(private cdRef: ChangeDetectorRef) {
    }

    ngAfterViewInit(): void {
        this.showActions = this.panelActions.nativeElement && this.panelActions.nativeElement.children.length > 0;
        this.showBody = this.panelBody.nativeElement && this.panelBody.nativeElement.children.length > 0;
        this.showFooter = this.panelFooter.nativeElement && this.panelFooter.nativeElement.children.length > 0;
        this.showHeader = this.panelHeader.nativeElement && this.panelHeader.nativeElement.children.length > 0;
        this.cdRef.detectChanges();
    }
}
