/**
 * Created by Arindam Bajpayee on 28-04-2017.
 */
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { AuthenticationService } from "./authentication.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

interface IMonitor {
    amount: number;
    currency: string;
    description: string;
    event: string;
    statusTime: Date;
    transactionId: string;
    type: string;
    user: {
        id: string,
        name: string,
        phone: string,
    };
}

export interface ISuccessResponse<T> {
    code: 200;
    result: "Success";
    message: string;
    payload: T;
}

export interface IMonitorsResponse {
    events: {
        transaction: IMonitor[],
        velocity: IMonitor[],
        redFlags: IMonitor[],
        highFlags: IMonitor[]
    };
}

@Injectable()
export class MonitorService {
    constructor(private http: HttpClient,
                private authenticationService: AuthenticationService) {
    }

    getAmlData(parameters): Observable<ISuccessResponse<IMonitorsResponse>> {
        return this.http.post<ISuccessResponse<IMonitorsResponse>>(
            environment.baseurl.api + "/protected/monitor/all",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                country: parameters.country,
                filter: parameters.filter
            },
            this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    getAmlCases(parameters) {
        return this.http.post(
            environment.baseurl.api + "/protected/monitor/cases",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                country: parameters.country,
                filter: parameters.filter
            },
            this.authenticationService.getHeaders()
        );
    }

    getTransactionAlerts(parameters) {
        return this.http.post(
            environment.baseurl.api + "/protected/monitor/transaction/alerts",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                country: parameters.country,
                filter: parameters.filter
            },
            this.authenticationService.getHeaders()
        );
    }

    updateCase(parameters) {
        return this.http.post(
            environment.baseurl.api + "/protected/monitor/updateCase",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                country: parameters.country,
                caseId: parameters.caseId,
                comment: parameters.comment,
                feedback: parameters.feedback,
                newStatus: parameters.newStatus,
                oldStatus: parameters.oldStatus
            },
            this.authenticationService.getHeaders()
        );
    }
}
