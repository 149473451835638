<div class="page-title">
    Limits
    <div class="page-title-actions">
        <bo-domain-selector (out)="selectedCountry($event)"></bo-domain-selector>
        <div class="display-inline-block">
            <div class="dropdown margin-0">
                <button class="btn btn-secondary dropdown-toggle padding-horizontal-10 padding-vertical-0" data-toggle="dropdown">{{ filter.type }}</button>
                <ul class="dropdown-menu" role="menu">
                    <a (click)="selectLimitsType($event, 'User')" class="dropdown-item" href="javascript: void(0)">User</a>
                    <a (click)="selectLimitsType($event, 'Shop')" class="dropdown-item" href="javascript: void(0)">Shop</a>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="margin-top-10 margin-bottom-0 clear-fix">
    <!-- User -->
    <div *ngIf="filter.type === 'User'" class="row">
        <div class="col-xl-12">
            <ng-container *ngIf="loaded">
                <bo-limits-user [limits]="limits"></bo-limits-user>

                <!-- Partners with specified limits - if present -->
                <ng-container *ngIf="(limitsPartners | keyvalue)?.length">
                    <h5 class="margin-top-25">Partners with specified limits</h5>
                    <mat-accordion [multi]="true">
                        <mat-expansion-panel [expanded]="false" *ngFor="let p of limitsPartners | keyvalue">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <span class="font-size-15 font-weight-bold margin-right-15">{{ p.key | uppercase }}</span>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <bo-limits-user [limits]="p.value.user"></bo-limits-user>
                        </mat-expansion-panel>
                    </mat-accordion>
                </ng-container>
            </ng-container>
        </div>
    </div>
    <!-- Shop -->
    <div *ngIf="filter.type === 'Shop'" class="row">
        <div class="col-xl-12">
            <div class="panel panel-with-borders margin-top-10 margin-bottom-0 clear-fix">
                <div class="panel">
                    <mat-tab-group dynamicHeight>
                        <!-- Transactions -->
                        <mat-tab *ngIf="loaded" class="margin-top-10" label="Transactions">
                            <div class="panel-body">
                                <div class="display-flex-row">
                                    <mat-card *ngFor="let level of levels" class="limits-card">
                                        <mat-card-title>LEVEL <span>{{ level }}</span></mat-card-title>
                                        <!--mat-card-subtitle>Without any KYC</mat-card-subtitle-->
                                        <mat-card-content>
                                            <div *ngFor="let t of (sortLimitValues(limits.transaction))" class="display-flex-row space-between">
                                                <span>{{ t[0] | uppercase }}</span>
                                                <span class="font-weight-bold">{{ t[1] | number }}</span>
                                            </div>
                                        </mat-card-content>
                                    </mat-card>
                                </div>
                            </div>
                        </mat-tab>
                        <!-- Velocity -->
                        <mat-tab *ngIf="loaded" class="margin-top-10" label="Velocity">
                            <div class="panel-body">
                                <div class="display-flex-row">
                                    <mat-card *ngFor="let level of levels" class="limits-card">
                                        <mat-card-title>LEVEL <span>{{ level }}</span></mat-card-title>
                                        <!--mat-card-subtitle>Without any KYC</mat-card-subtitle-->
                                        <mat-card-content>
                                            <div *ngFor="let t of (sortLimitValues(limits.velocity))" class="display-flex-row space-between">
                                                <span>{{ t[0] | uppercase }}</span>
                                                <span class="font-weight-bold">{{ t[1] | number }}</span>
                                            </div>
                                        </mat-card-content>
                                    </mat-card>
                                </div>
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </div>

            <!-- Merchant Partners with specified limits - if present -->
            <ng-container *ngIf="(merchantPartnerLimits)?.length">
                <h5 class="margin-top-25">Partners with specified limits</h5>
                <mat-accordion [multi]="true">
                    <mat-expansion-panel [expanded]="false" *ngFor="let partner of merchantPartnerLimits">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <span class="font-size-15 font-weight-bold margin-right-15">{{ partner.name | uppercase }}</span>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <bo-limits-user [limits]="partner.limits"></bo-limits-user>
                    </mat-expansion-panel>
                </mat-accordion>
            </ng-container>
        </div>
    </div>
</div>
