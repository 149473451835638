/**
 * Created by Arindam Bajpayee on 01-05-2017.
 */
import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";

import { AuthenticationService } from "../services/authentication.service";

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private router: Router,
                private authenticationService: AuthenticationService) {
    }

    /* Used to protect access to main routes */
    canActivate(): boolean {
        if (this.authenticationService.isUserLoggedIn()) {
            return true;
        }

        this.router.navigate([ "/login" ]);
        return false;
    }

    /* Used to protect access to child routes */
    canActivateChild(): boolean {
        return this.canActivate();
    }

    canLoad(): boolean {
        return true;
    }
}
