import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatTooltipModule } from "@angular/material/tooltip";

import { CellTooltipComponent } from "./cell-tooltip/cell-tooltip.component";

@NgModule({
  imports: [
    CommonModule,
    MatTooltipModule,
  ],
  declarations: [CellTooltipComponent],
  exports: [CellTooltipComponent]
})
export class DataTableModule { }
