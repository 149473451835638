/**
 * Created by Arindam Bajpayee on 09-03-2020.
 */
import { animate, state, style, transition, trigger } from "@angular/animations";
import { AfterViewInit, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgProgress } from "@ngx-progressbar/core";
import { ToastrService } from "ngx-toastr";
/* Components */
import { ModalComponent, ModalOptions, Severity } from "../../modal/modal.component";
/* Models */
import { Country } from "../../../models/country";
import { Job } from "../../../models/job";
/* Services */
import { AuthenticationService } from "../../../services/authentication.service";
import { JobService } from "../../../services/job.service";
import { PusherService } from "../../../services/pusher/pusher.service";

@Component({
    selector: "bo-job",
    templateUrl: "./job.component.html",
    styleUrls: [ "./job.component.css" ],
    animations: [
        trigger("detailExpand", [
            state("collapsed", style({ height: "0px", minHeight: "0", display: "none" })),
            state("expanded", style({ height: "*" })),
            transition("expanded <=> collapsed", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)"))
        ])
    ]
})
export class JobComponent implements AfterViewInit, OnInit {
    count = 0;
    country: Country = new Country();
    date: Date = new Date();
    public job: Job = {
        history: [],
        lastRun: {
            by: "",
            date: null,
            status: "NO_RUN"
        },
        name: "JOB_NEW",
        region: [],
        schedule: {
            frequency: "",
            dayOfMonth: 1,
            dayOfWeek: 1,
            month: 0,
            time: {
                hour: "*",
                minute: "00",
                second: "00"
            }
        },
        type: ""
    };
    jobs = {
        DAILY: [],
        HOURLY: [],
        MANUAL: [],
        MONTHLY: [],
        WEEKLY: [],
        YEARLY: []
    };
    isLoading = false;
    options4day = [
        { name: "Mon", value: 1 },
        { name: "Tue", value: 2 },
        { name: "Wed", value: 3 },
        { name: "Thu", value: 4 },
        { name: "Fri", value: 5 },
        { name: "Sat", value: 6 },
        { name: "Sun", value: 7 }
    ];
    options4frequency = [
        { name: "Manual", value: "MANUAL" },
        { name: "Hourly", value: "HOURLY" },
        { name: "Daily", value: "DAILY" },
        { name: "Weekly", value: "WEEKLY" },
        { name: "Monthly", value: "MONTHLY" },
        { name: "Yearly", value: "YEARLY" }
    ];
    options4month = [
        { name: "Jan", value: 0 },
        { name: "Feb", value: 1 },
        { name: "Mar", value: 2 },
        { name: "Apr", value: 3 },
        { name: "May", value: 4 },
        { name: "Jun", value: 5 },
        { name: "Jul", value: 6 },
        { name: "Aug", value: 7 },
        { name: "Sep", value: 8 },
        { name: "Oct", value: 9 },
        { name: "Nov", value: 10 },
        { name: "Dec", value: 11 }
    ];
    options4region = [ "CH", "EU", "GLOBAL", "LATAM" ];
    options4type = [ "aml", "atm", "partner", "payment", "transaction", "user" ];
    selectedTab = "List";
    time: Date = new Date();
    displayedColumns: string[] = [ "name", "type", "frequency", "time", "region", "lastRunDate", "lastRunStatus", "action" ];
    expandedElement: any | null;

    constructor(private router: Router,
                public activeModal: NgbActiveModal,
                private modalService: NgbModal,
                private progressBar: NgProgress,
                private authenticationService: AuthenticationService,
                private jobService: JobService,
                private pusherService: PusherService,
                private toastr: ToastrService) {
    }

    ngOnInit(): void {
        if (!this.authenticationService.isUserLoggedIn()) {
            this.router.navigate([ "/login" ]);
            return;
        }
        this.callService();
    }

    ngAfterViewInit(): void {
        if (!this.pusherService.isConnected("dashboard")) {
            this.pusherService.subscribeToChannel("job", [ "started" ], (data) => {
                this.toastr.info(data.message, data.title);
                // (new Audio("../../../../assets/sound/quite-impressive.mp3")).play();
                // let sound = new Howl({ src: [ "../../../../assets/sound/quite-impressed.mp3" ] });
                // sound.play();
                if (this.router.url === "/admin/job") {
                    this.progressBar.ref().start();
                    this.callService();
                }
            });
            this.pusherService.subscribeToChannel("job", [ "completed" ], (data) => {
                if (data.job.lastRun.status === "COMPLETED") {
                    this.toastr.success(data.message, data.title);
                } else {
                    this.toastr.error(data.message, data.title);
                }
                // (new Audio("../../../../assets/sound/quite-impressive.mp3")).play();
                // let sound = new Howl({ src: [ "../../../../assets/sound/quite-impressed.mp3" ] });
                // sound.play();
                if (this.router.url === "/admin/job") {
                    this.progressBar.ref().start();
                    this.callService();
                }
            });
        }
    }

    create(isValid): void {
        this.progressBar.ref().start();
        if (isValid) {
            this.job.schedule.time.hour = this.time.getHours().toString();
            this.job.schedule.time.minute = this.time.getMinutes().toString();
            this.job.schedule.time.second = "00";
            if (this.job.schedule.frequency === "HOURLY") {
                this.job.schedule.time.hour = "**";
            } else if (this.job.schedule.frequency === "MONTHLY" || this.job.schedule.frequency === "YEARLY") {
                this.job.schedule.dayOfMonth = this.date.getDate();
            } else if (this.job.schedule.frequency === "YEARLY") {
                this.job.schedule.month = this.date.getMonth();
            }

            this.jobService.create(this.job).subscribe(response => {
                const data: any = { ...response };
                this.progressBar.ref().complete();
                this.isLoading = false;
                const modalRef = this.modalService.open(ModalComponent);
                modalRef.componentInstance.modalOptions = new ModalOptions(Severity.SUCCESS, "Success", data.message, null);
                this.router.navigate([ "/admin/job" ]);
            }, error => {
                this.handleError(error, true);
                this.progressBar.ref().complete();
                this.isLoading = false;
            });
        }
    }

    run(job): void {
        this.progressBar.ref().start();
        if (job && job.schedule.frequency === "MANUAL") {
            this.jobService.run(job).subscribe(response => {
                const data: any = { ...response };
                this.progressBar.ref().complete();
                this.isLoading = false;
                const modalRef = this.modalService.open(ModalComponent);
                modalRef.componentInstance.modalOptions = new ModalOptions(Severity.SUCCESS, "Success", data.message, null);
                this.router.navigate([ "/admin/job" ]);
            }, error => {
                this.handleError(error, true);
                this.progressBar.ref().complete();
                this.isLoading = false;
            });
        }
    }

    // Selection of the region
    selectRegion($event, region) {
        const index = this.job.region.indexOf(region);
        if ($event.checked) {
            if (index < 0) {
                this.job.region.push(region.toString());
            }
        } else {
            if (index >= 0) {
                this.job.region.splice(index, 1);
            }
        }
    }

    // Selection of view on the setting page
    selectTab($event, tab) {
        this.selectedTab = tab;
        if (tab === "List") {
            this.callService();
        }
    }

    /* Error Handler */
    private callService() {
        this.progressBar.ref().start();
        this.jobService.getJobs().subscribe(response => {
            const data: any = { ...response };
            const jobs = data.payload.jobs;
            this.count = jobs.length;
            this.jobs.DAILY = jobs.filter(job => job.schedule.frequency === "DAILY");
            this.jobs.HOURLY = jobs.filter(job => job.schedule.frequency === "HOURLY");
            this.jobs.MANUAL = jobs.filter(job => job.schedule.frequency === "MANUAL");
            this.jobs.MONTHLY = jobs.filter(job => job.schedule.frequency === "MONTHLY");
            this.jobs.WEEKLY = jobs.filter(job => job.schedule.frequency === "WEEKLY");
            this.jobs.YEARLY = jobs.filter(job => job.schedule.frequency === "YEARLY");
            this.progressBar.ref().complete();
            this.isLoading = false;
        }, error => {
            this.handleError(error, true);
            this.progressBar.ref().complete();
            this.isLoading = false;
        });
    }

    private handleError(error: any, showModal: boolean) {
        if (error.status === 401 && error.statusText === "Unauthorized") {
            this.toastr.error("Unauthorized User", "User logout");
            this.authenticationService.logout();
            this.router.navigate([ "/login" ]);
        } else {
            let response = error.error;
            if (typeof response === "string") {
                response = JSON.parse(response);
            }
            this.toastr.error(response.message, response.result);
            if (showModal) {
                const modalRef = this.modalService.open(ModalComponent);
                modalRef.componentInstance.modalOptions = new ModalOptions(Severity.ERROR, error.statusText, response.message, null);
            }
        }
    }
}
