<div class="page-title">
    <span>AML Monitoring</span>
    <div class="page-title-actions">
        <bo-domain-selector showEEA="true" (out)="selectedCountry($event)"></bo-domain-selector>
        <div class="display-inline-block">
            <div class="dropdown margin-0">
                <button class="btn btn-secondary dropdown-toggle padding-horizontal-10 padding-vertical-0" data-toggle="dropdown">{{ profileType | uppercase }}</button>
                <ul class="dropdown-menu" role="menu">
                    <li (click)="setType('user')" class="dropdown-item" style="cursor: pointer">User</li>
                    <li (click)="setType('merchant')" class="dropdown-item" style="cursor: pointer">Merchant
                    <li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="row margin-top-10">
    <div class="col">
        <form (ngSubmit)="searchByFilter()" class="display-inline-block">
            <mat-form-field class="margin-right-15">
                <input matInput [ngModelOptions]="{standalone: true}" [(ngModel)]="dateFrom" name="dateFrom" placeholder="From" [matDatepicker]="dateFromPicker">
                <mat-datepicker-toggle matSuffix [for]="dateFromPicker"></mat-datepicker-toggle>
                <mat-datepicker #dateFromPicker></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="margin-right-15">
                <input matInput [ngModelOptions]="{standalone: true}" [(ngModel)]="dateTo" name="dateTo" placeholder="To" [matDatepicker]="dateToPicker">
                <mat-datepicker-toggle matSuffix [for]="dateToPicker"></mat-datepicker-toggle>
                <mat-datepicker #dateToPicker></mat-datepicker>
            </mat-form-field>
            <!-- DO WE NEED THIS BUTTON? -->
            <!-- <button mat-icon-button><i class="fas fa-search"></i></button> -->
        </form>
    </div>
</div>

<h5 class="display-inline-block">Cases to be investigated</h5>
<div class="float-right">
    <div *ngIf="isDownloadCasesAllowed()" class="display-inline-block float-right">
        <div class="dropdown margin-0">
            <button class="btn btn-secondary dropdown-toggle padding-horizontal-10 padding-vertical-0" data-toggle="dropdown">
                <i class="far fa-download"></i> Download
            </button>
            <ul class="dropdown-menu" role="menu">
                <a (click)="downloadAllCases()" class="dropdown-item" href="javascript: void(0)">Download all as CSV</a>
                <a (click)="downloadCases()" class="dropdown-item" href="javascript: void(0)">Download filtered as CSV</a>
            </ul>
        </div>
    </div>
</div>
<div class="row row-eq-height margin-top-10">
    <!-- Open Cases -->
    <div class="col-xl-3 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="widget widget-seven background-secondary">
            <div class="widget-body">
                <div class="widget-body-inner">
                    <h5 class="text-uppercase float-left">Open</h5>
                    <i class="counter-icon fad fa-alarm-exclamation"></i>
                    <span class="counter-count">
                        <i *ngIf="!cases.open" class="counter-icon fad fa-spinner fa-spin"></i>
                        <span *ngIf="cases.open">
                            <span class="counter-init font-weight-600">{{cases.open.count}}</span>
                        </span>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <!-- Escalated Cases -->
    <div class="col-xl-3 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="widget widget-seven background-danger">
            <div class="widget-body">
                <div class="widget-body-inner">
                    <h5 class="text-uppercase float-left">Escalated</h5>
                    <i class="counter-icon fad fa-angle-double-up"></i>
                    <span class="counter-count">
                        <i *ngIf="!cases.escalated" class="counter-icon fad fa-spinner fa-spin"></i>
                        <span *ngIf="cases.escalated">
                            <span class="counter-init font-weight-600">{{cases.escalated.count}}</span>
                        </span>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <!-- On hold Cases -->
    <div class="col-xl-3 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="widget widget-seven background-warning">
            <div class="widget-body">
                <div class="widget-body-inner">
                    <h5 class="text-uppercase float-left">On hold</h5>
                    <i class="counter-icon fad fa-pause"></i>
                    <span class="counter-count">
                        <i *ngIf="!cases.hold" class="counter-icon fad fa-spinner fa-spin"></i>
                            <span *ngIf="cases.hold">
                                <span class="counter-init font-weight-600">{{cases.hold.count}}</span>
                            </span>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <!-- Closed Cases -->
    <div class="col-xl-3 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="widget widget-seven background-success">
            <div class="widget-body">
                <div class="widget-body-inner">
                    <h5 class="text-uppercase float-left">Closed</h5>
                    <i class="counter-icon fad fa-clipboard-list-check"></i>
                    <span class="counter-count">
                        <i *ngIf="!cases.closed" class="counter-icon fad fa-spinner fa-spin"></i>
                        <span *ngIf="cases.closed">
                            <span class="counter-init font-weight-600">{{cases.closed.count}}</span>
                        </span>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Cases Panel -->
<div class="panel background-light-grey margin-top-10 margin-bottom-0 clear-fix">
    <mat-tab-group dynamicHeight>
        <mat-tab label="Opened">
            <div class="" id="list_Open">
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <table [dataSource]="cases.open.array" class="mat-elevation-z1 table table-hover width-full" mat-table>
                            <!--- Note that these columns can be defined in any order.
                                  The actual rendered columns are set as a property on the row definition" -->
                            <!-- Name -->
                            <ng-container matColumnDef="name">
                                <th *matHeaderCellDef mat-header-cell>Name</th>
                                <td *matCellDef="let c" mat-cell>
                                    <bo-cell-tooltip (click)="navigateTo(c.id)" tooltipText="View">
                                        {{ c.id }}
                                    </bo-cell-tooltip>
                                </td>
                            </ng-container>
                            <!-- Alert -->
                            <ng-container matColumnDef="reason">
                                <th *matHeaderCellDef mat-header-cell>Reason</th>
                                <td *matCellDef="let c" mat-cell>{{ c.reasons.medium + "MEDIUM," + c.reasons.high + "HIGH" }}</td>
                            </ng-container>
                            <!-- Event Time -->
                            <ng-container matColumnDef="time">
                                <th *matHeaderCellDef mat-header-cell>Time</th>
                                <td *matCellDef="let c" mat-cell>{{ c?.time | date:'y.MM.dd HH:mm:ss' }}</td>
                            </ng-container>
                            <!-- Status -->
                            <ng-container matColumnDef="status">
                                <th *matHeaderCellDef mat-header-cell>Status</th>
                                <td *matCellDef="let c" mat-cell> {{ c?.status }} </td>
                            </ng-container>
                            <!-- Expanded row with details -->
                            <ng-container matColumnDef="expandedDetails">
                                <td *matCellDef="let account" [attr.colspan]="displayedColumns.length" mat-cell>
                                    <div [@detailExpand]="account == expandedElement ? 'expanded' : 'collapsed'" class="account-details">
                                        <!--Account roles-->
                                        <div style="line-height: 20px;">
                                            <mat-chip-listbox>
                                                <mat-chip *ngFor="let c" class="font-size-12 role">{{ c.transactions }}</mat-chip>
                                            </mat-chip-listbox>
                                            <br/>
                                        </div>
                                        <!--Account rights-->
                                        <div>
                                            <mat-chip-listbox>
                                                <mat-chip *ngFor="let right of account.rights" class="font-size-10 right">{{ right }}</mat-chip>
                                            </mat-chip-listbox>
                                        </div>
                                    </div>
                                </td>
                            </ng-container>
                            <tr *matHeaderRowDef="displayedNewColumns" mat-header-row></tr>
                            <tr *matRowDef="let row; columns: displayedNewColumns;" mat-row></tr>
                        </table>
                    </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Escalated">
            <div class="" id="list_Escalated">
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <table [dataSource]="cases.escalated.array" class="mat-elevation-z1 table table-hover width-full" mat-table>
                            <!--- Note that these columns can be defined in any order.
                                  The actual rendered columns are set as a property on the row definition" -->
                            <!-- Name -->
                            <ng-container matColumnDef="name">
                                <th *matHeaderCellDef mat-header-cell>Name</th>
                                <td *matCellDef="let c" mat-cell>
                                    <bo-cell-tooltip (click)="navigateTo(c.id)" tooltipText="View">
                                        {{ c.id }}
                                    </bo-cell-tooltip>
                                </td>
                            </ng-container>
                            <!-- Alert -->
                            <ng-container matColumnDef="reason">
                                <th *matHeaderCellDef mat-header-cell>Reason</th>
                                <td *matCellDef="let c" mat-cell>{{ c.reasons.medium + "MEDIUM," + c.reasons.high + "HIGH" }}</td>
                            </ng-container>
                            <!-- Event Time -->
                            <ng-container matColumnDef="time">
                                <th *matHeaderCellDef mat-header-cell>Time</th>
                                <td *matCellDef="let c" mat-cell>{{ c?.time | date:'y.MM.dd HH:mm:ss' }}</td>
                            </ng-container>
                            <!-- Status -->
                            <ng-container matColumnDef="status">
                                <th *matHeaderCellDef mat-header-cell>Status</th>
                                <td *matCellDef="let c" mat-cell>{{c.status}}</td>
                            </ng-container>
                            <tr *matHeaderRowDef="displayedNewColumns" mat-header-row></tr>
                            <tr *matRowDef="let row; columns: displayedNewColumns;" mat-row></tr>
                        </table>
                    </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="On hold">
            <div class="" id="list_Hold">
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <table [dataSource]="cases.hold.array" class="mat-elevation-z1 table table-hover width-full" mat-table>
                            <!--- Note that these columns can be defined in any order.
                                  The actual rendered columns are set as a property on the row definition" -->
                            <!-- Name -->
                            <ng-container matColumnDef="name">
                                <th *matHeaderCellDef mat-header-cell>Name</th>
                                <td *matCellDef="let c" mat-cell>
                                    <bo-cell-tooltip (click)="navigateTo(c.id)" tooltipText="View">
                                        {{ c.id }}
                                    </bo-cell-tooltip>
                                </td>
                            </ng-container>
                            <!-- Alert -->
                            <ng-container matColumnDef="reason">
                                <th *matHeaderCellDef mat-header-cell>Reason</th>
                                <td *matCellDef="let c" mat-cell>{{ c.reasons.medium + "MEDIUM," + c.reasons.high + "HIGH" }}</td>
                            </ng-container>
                            <!-- Event Time -->
                            <ng-container matColumnDef="time">
                                <th *matHeaderCellDef mat-header-cell>Time</th>
                                <td *matCellDef="let c" mat-cell>{{ c?.time | date:'y.MM.dd HH:mm:ss' }}</td>
                            </ng-container>
                            <!-- Status -->
                            <ng-container matColumnDef="status">
                                <th *matHeaderCellDef mat-header-cell>Status</th>
                                <td *matCellDef="let c" mat-cell>{{c.status}}</td>
                            </ng-container>
                            <tr *matHeaderRowDef="displayedNewColumns" mat-header-row></tr>
                            <tr *matRowDef="let row; columns: displayedNewColumns;" mat-row></tr>
                        </table>
                    </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Closed">
            <div class="" id="list_Closed">
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <table [dataSource]="cases.closed.array" class="mat-elevation-z1 table table-hover width-full" mat-table>
                            <!--- Note that these columns can be defined in any order.
                                  The actual rendered columns are set as a property on the row definition" -->
                            <!-- Name -->
                            <ng-container matColumnDef="name">
                                <th *matHeaderCellDef mat-header-cell>Name</th>
                                <td *matCellDef="let c" mat-cell>
                                    <bo-cell-tooltip (click)="navigateTo(c.id)" tooltipText="View">
                                        {{ c.id }}
                                    </bo-cell-tooltip>
                                </td>
                            </ng-container>
                            <!-- Alert -->
                            <ng-container matColumnDef="reason">
                                <th *matHeaderCellDef mat-header-cell>Reason</th>
                                <td *matCellDef="let c" mat-cell>{{ c.reasons.medium + "MEDIUM," + c.reasons.high + "HIGH" }}</td>
                            </ng-container>
                            <!-- Event Time -->
                            <ng-container matColumnDef="time">
                                <th *matHeaderCellDef mat-header-cell>Time</th>
                                <td *matCellDef="let c" mat-cell>{{ c?.time | date:'y.MM.dd HH:mm:ss' }}</td>
                            </ng-container>
                            <!-- Status -->
                            <ng-container matColumnDef="status">
                                <th *matHeaderCellDef mat-header-cell>Status</th>
                                <td *matCellDef="let c" mat-cell>{{c.status}}</td>
                            </ng-container>
                            <tr *matHeaderRowDef="displayedNewColumns" mat-header-row></tr>
                            <tr *matRowDef="let row; columns: displayedNewColumns;" mat-row></tr>
                        </table>
                    </div>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>

<!-- Transactions Alerts Panel -->
<div class="panel background-light-grey margin-top-10 margin-bottom-0 clear-fix">
    <mat-tab-group dynamicHeight>
        <mat-tab label="Post transactions">
            <div class="margin-top-10" id="list_RED">
                <div class="dropdown margin-0">
                    <button class="btn btn-secondary dropdown-toggle padding-horizontal-10 padding-vertical-0" data-toggle="dropdown"> {{ period }}</button>
                    <ul class="dropdown-menu" role="menu">
                        <a (click)="redFlagsRolling('week')" class="dropdown-item" href="javascript: void(0)">WEEK</a>
                        <a (click)="redFlagsRolling('month')" class="dropdown-item" href="javascript: void(0)">MONTH</a>
                        <a (click)="redFlagsRolling('year')" class="dropdown-item" href="javascript: void(0)">YEAR</a>
                    </ul>
                </div>
                <div class="row">
                    <!-- Retrospective Flags Chart -->
                    <div class="col-lg-12 col-md-12" style="height:400px !important;">
                        <div [chart]="redTrxFlagsChart"></div>
                    </div>
                </div>
                <div class="row margin-top-20">
                    <div class="col-lg-12 col-md-12">
                        <h5 class="display-inline-block">Transaction Alerts</h5>
                        <div class="float-right">
                            <div *ngIf="isDownloadCasesAllowed()" class="display-inline-block float-right">
                                <div class="dropdown margin-0">
                                    <button class="btn btn-secondary dropdown-toggle padding-horizontal-10 padding-vertical-0" data-toggle="dropdown">
                                        <i class="far fa-download"></i> Download
                                    </button>
                                    <ul class="dropdown-menu" role="menu">
                                        <a (click)="downloadAllAlerts()" class="dropdown-item" href="javascript: void(0)">Download all as CSV</a>
                                        <a (click)="downloadAlerts()" class="dropdown-item" href="javascript: void(0)">Download filtered as CSV</a>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <table [dataSource]="postTrx" class="mat-elevation-z1 table table-hover width-full" mat-table>
                            <!--- Note that these columns can be defined in any order.
                                  The actual rendered columns are set as a property on the row definition" -->
                            <!-- ID -->
                            <ng-container matColumnDef="transactionId">
                                <th *matHeaderCellDef mat-header-cell>TRX ID</th>
                                <td *matCellDef="let t" class="font-monospace" mat-cell>{{ t.trxId }}</td>
                            </ng-container>
                            <!-- Flag -->
                            <ng-container matColumnDef="flag">
                                <th *matHeaderCellDef mat-header-cell>Flag</th>
                                <td *matCellDef="let t" mat-cell>
                                    <mat-chip-listbox *ngIf="t.flag.length > 0" class="text-center">
                                        <mat-chip [ngClass]="{ 'sonect-transparent-bg color-transparent': t.flag === 'none',
                                                                           'sonect-beige-bg': t.flag === 'low',
                                                                           'sonect-orange-bg': t.flag === 'medium',
                                                                           'sonect-flamingo-bg color-white': t.flag === 'high' }"
                                                  class="status"
                                                  matTooltip="Transaction Risk Category">{{ t.flag + "(" + t.alert + ")" }}</mat-chip>
                                    </mat-chip-listbox>
                                </td>
                            </ng-container>
                            <!-- Country Of Residence -->
                            <ng-container matColumnDef="cor">
                                <th *matHeaderCellDef mat-header-cell>COR</th>
                                <td *matCellDef="let t" mat-cell>
                                        <span class="font-weight-bold margin-left-5">
                                            {{ t.cor }}
                                        </span>
                                </td>
                            </ng-container>
                            <!-- Type Of Alert -->
                            <ng-container matColumnDef="type">
                                <th *matHeaderCellDef mat-header-cell>TYPE</th>
                                <td *matCellDef="let t" mat-cell>
                                        <span class="font-weight-bold margin-left-5">
                                            {{ t.type }}
                                        </span>
                                </td>
                            </ng-container>
                            <!-- Name -->
                            <ng-container matColumnDef="name">
                                <th *matHeaderCellDef mat-header-cell>Name</th>
                                <td *matCellDef="let t" mat-cell>
                                        <span class="font-weight-bold margin-left-5">
                                            {{ t.name }}
                                        </span>
                                </td>
                            </ng-container>
                            <!-- KYC LEVEL  -->
                            <ng-container matColumnDef="kycLevel">
                                <th *matHeaderCellDef mat-header-cell>KYC Level</th>
                                <td *matCellDef="let c" mat-cell>{{ c.verificationLevel }}</td>
                            </ng-container>
                            <!-- Status Time -->
                            <ng-container matColumnDef="time">
                                <th *matHeaderCellDef mat-header-cell>Time</th>
                                <td *matCellDef="let c" mat-cell>{{ c?.time | date:'y.MM.dd HH:mm:ss' }}</td>
                            </ng-container>

                            <tr *matHeaderRowDef="displayedRetroColumns" mat-header-row></tr>
                            <tr *matRowDef="let row; columns: displayedRetroColumns;" mat-row></tr>
                        </table>
                    </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="RTRA">
            <div class="margin-top-10" id="list_RTRA">
                <div class="dropdown margin-0">
                    <button class="btn btn-secondary dropdown-toggle padding-horizontal-10 padding-vertical-0" data-toggle="dropdown"> {{ period }}</button>
                    <ul class="dropdown-menu" role="menu">
                        <a (click)="rtraRolling('week')" class="dropdown-item" href="javascript: void(0)">WEEK</a>
                        <a (click)="rtraRolling('month')" class="dropdown-item" href="javascript: void(0)">MONTH</a>
                        <a (click)="rtraRolling('year')" class="dropdown-item" href="javascript: void(0)">YEAR</a>
                    </ul>
                </div>
                <div class="row">
                    <!-- Transaction Limits Chart -->
                    <div class="col-lg-6 col-md-6" style="height:400px !important;">
                        <div [chart]="transactionLimitsChart"></div>
                    </div>
                    <!-- Velocity Limits Chart -->
                    <div class="col-lg-6 col-md-6" style="height:400px !important;">
                        <div [chart]="velocityLimitsChart"></div>
                    </div>
                </div>
                <div class="row margin-top-20">
                    <div class="col-lg-12 col-md-12">
                        <h5 class="display-inline-block">RTRA Alerts</h5>
                        <div class="float-right">
                            <div *ngIf="isDownloadCasesAllowed()" class="display-inline-block float-right">
                                <div class="dropdown margin-0">
                                    <button class="btn btn-secondary dropdown-toggle padding-horizontal-10 padding-vertical-0" data-toggle="dropdown">
                                        <i class="far fa-download"></i> Download
                                    </button>
                                    <ul class="dropdown-menu" role="menu">
                                        <a (click)="downloadAllCases()" class="dropdown-item" href="javascript: void(0)">Download all as CSV</a>
                                        <a (click)="downloadCases()" class="dropdown-item" href="javascript: void(0)">Download filtered as CSV</a>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="row margin-top-20">
                            <div class="col-lg-6 col-md-6">
                                <table [dataSource]="trxLimits.reverse()" class="mat-elevation-z1 table table-hover width-full" mat-table>
                                    <!--- Note that these columns can be defined in any order.
                                          The actual rendered columns are set as a property on the row definition" -->
                                    <!-- Name -->
                                    <ng-container matColumnDef="name">
                                        <th *matHeaderCellDef mat-header-cell>Name</th>
                                        <td *matCellDef="let t" mat-cell>
                                            <bo-cell-tooltip (click)="navigateTo(t.name)" tooltipText="View">
                                                {{ t.name }}
                                            </bo-cell-tooltip>
                                        </td>
                                    </ng-container>
                                    <!-- Status Time -->
                                    <ng-container matColumnDef="time">
                                        <th *matHeaderCellDef mat-header-cell>Time</th>
                                        <td *matCellDef="let c" mat-cell>{{ c?.statusTime | date:'y.MM.dd HH:mm:ss' }}</td>
                                    </ng-container>
                                    <!-- Event -->
                                    <ng-container matColumnDef="event">
                                        <th *matHeaderCellDef mat-header-cell>Event</th>
                                        <td *matCellDef="let t" mat-cell>{{ t.description }}</td>
                                    </ng-container>

                                    <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
                                    <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
                                </table>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <table [dataSource]="velLimits.reverse()" class="mat-elevation-z1 table table-hover width-full" mat-table>
                                    <!--- Note that these columns can be defined in any order.
                                          The actual rendered columns are set as a property on the row definition" -->
                                    <!-- Name -->
                                    <ng-container matColumnDef="name">
                                        <th *matHeaderCellDef mat-header-cell>Name</th>
                                        <td *matCellDef="let t" mat-cell>
                                            <bo-cell-tooltip (click)="navigateTo(t.name)" tooltipText="View">
                                                {{ t.user.name }}
                                            </bo-cell-tooltip>
                                        </td>
                                    </ng-container>
                                    <!-- Status Time -->
                                    <ng-container matColumnDef="time">
                                        <th *matHeaderCellDef mat-header-cell>Time</th>
                                        <td *matCellDef="let c" mat-cell>{{ c?.statusTime | date:'y.MM.dd HH:mm:ss' }}</td>
                                    </ng-container>
                                    <!-- Event -->
                                    <ng-container matColumnDef="event">
                                        <th *matHeaderCellDef mat-header-cell>Event</th>
                                        <td *matCellDef="let t" mat-cell>{{ t.description }}</td>
                                    </ng-container>

                                    <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
                                    <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>
