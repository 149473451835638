/**
 * Created by Arindam Bajpayee on 12-01-2020.
 */
import { Injectable } from "@angular/core";
import Pusher from "pusher-js";

import { environment } from "../../../environments/environment";

@Injectable({ providedIn: "root" })
export class PusherService {
    private pusher: any;
    private components: Array<string> = new Array<string>();

    constructor() {
        this.pusher = new Pusher(environment.pusher.key, {
            cluster: environment.pusher.cluster
        });
    }

    isConnected(component: string = "admin"): boolean {
        if (this.pusher.connection.state !== "connected") {
            this.components = [];
            this.pusher = new Pusher(environment.pusher.key, {
                cluster: environment.pusher.cluster
            });
        }
        const connected = this.components.includes(component);
        if (!connected) {
            this.components.push(component);
        }
        return connected;
    }

    subscribeToChannel(channelName: string, events: string[], callback: (data: any) => void) {
        const channel = this.pusher.subscribe(channelName);
        events.forEach(event => channel.bind(event, (data) => callback(data)));
    }

    unsubscribe() {
        this.components = [];
        this.pusher.unbind_all();
        this.pusher.disconnect();
    }
}
