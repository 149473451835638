<div class="page-title">Jobs</div>
<div class="panel panel-with-borders margin-top-10 margin-bottom-0 clear-fix">
    <div class="panel-body">
        <div class="nav-tabs-horizontal margin-bottom-20">
            <ul class="nav nav-tabs">
                <li class="nav-item">
                    <a (click)="selectTab($event, 'List')" [ngClass]="{'active': selectedTab === 'List'}" class="nav-link"
                       href="javascript: void(0);">List</a>
                </li>
                <li class="nav-item">
                    <a (click)="selectTab($event, 'New')" [ngClass]="{'active': selectedTab === 'New'}" class="nav-link"
                       href="javascript: void(0);">Create new</a>
                </li>
            </ul>
        </div>

        <div *ngIf="selectedTab === 'List'" class="" id="list_jobs">
            <!-- Filter -->
            <!--<form (ngSubmit)="searchUserProfiles()">-->
            <!--    <div class="row margin-bottom-15">-->
            <!--        <div class="col">-->
            <!--            <domain-selector (out)="selectedCountry($event)"></domain-selector>-->
            <!--            <button mat-icon-button><i class="fas fa-search"></i></button>-->
            <!--        </div>-->
            <!--    </div>-->
            <!--</form>-->
            <div class="row margin-bottom-15 padding-horizontal-15">
                <div class="col-lg-12 background-light-grey padding-vertical-8 padding-horizontal-16">
                    Total number of jobs: <b>{{ count }}</b>.
                </div>
            </div>

            <div *ngFor="let frequency of options4frequency;" class="row margin-bottom-10">
                <div class="col-lg-12"><h5 class="margin-bottom-0">{{ frequency.name }}</h5></div>
                <div class="col-lg-12">
                    <table [dataSource]="jobs[frequency.value]" class="mat-elevation-z1 table table-hover width-full" mat-table multiTemplateDataRows>
                        <!--- Note that these columns can be defined in any order.
                              The actual rendered columns are set as a property on the row definition" -->
                        <!-- Name -->
                        <ng-container matColumnDef="name">
                            <th *matHeaderCellDef mat-header-cell>Name</th>
                            <td *matCellDef="let job" mat-cell>{{ job?.name }}</td>
                        </ng-container>
                        <!-- Frequency -->
                        <ng-container matColumnDef="frequency">
                            <th *matHeaderCellDef mat-header-cell>Frequency</th>
                            <td *matCellDef="let job" class="" mat-cell>{{ job?.schedule?.frequency }}</td>
                        </ng-container>
                        <!-- Type -->
                        <ng-container matColumnDef="type">
                            <th *matHeaderCellDef mat-header-cell>Type</th>
                            <td *matCellDef="let job" mat-cell>{{ job?.type }}</td>
                        </ng-container>
                        <!-- Time -->
                        <ng-container matColumnDef="time">
                            <th *matHeaderCellDef mat-header-cell>Time</th>
                            <td *matCellDef="let job" class="" mat-cell>
                                <span class="clock hour">{{ job?.schedule?.time?.hour }}</span>
                                <span class="clock separator">:</span>
                                <span class="clock minute">{{ job?.schedule?.time?.minute }}</span>
                                <span class="clock separator">:</span>
                                <span class="clock second">{{ job?.schedule?.time?.second }}</span>
                            </td>
                        </ng-container>
                        <!-- Region -->
                        <ng-container matColumnDef="region">
                            <th *matHeaderCellDef mat-header-cell>Region</th>
                            <td *matCellDef="let job" mat-cell>{{ job?.region }}</td>
                        </ng-container>
                        <!-- Last Run Date -->
                        <ng-container matColumnDef="lastRunDate">
                            <th *matHeaderCellDef class="text-center" mat-header-cell>Last Run Date</th>
                            <td *matCellDef="let job" class="text-center" mat-cell>
                                {{ job?.lastRun?.date | date:'y.MM.dd HH:mm:ss' }}
                            </td>
                        </ng-container>
                        <!-- Last Run Status -->
                        <ng-container matColumnDef="lastRunStatus">
                            <th *matHeaderCellDef class="" mat-header-cell>Last Run Status</th>
                            <td *matCellDef="let job" class="text-center" mat-cell>
                                <mat-chip-listbox *ngIf="job?.lastRun?.status && job?.lastRun?.status.length > 0" class="status">
                                    <mat-chip [ngClass]="{ 'sonect-grey-bg': job?.lastRun?.status === 'NO_RUN' || job?.lastRun?.status === 'DISABLED',
                                                           'sonect-beige-bg': job?.lastRun?.status === 'STARTED',
                                                           'background-danger': job?.lastRun?.status === 'FAILED',
                                                           'sonect-orange-bg': job?.lastRun?.status === 'CANCELLED',
                                                           'background-success': job?.lastRun?.status === 'COMPLETED',
                                                           'color-white': job?.lastRun?.status === 'NO_RUN' ||
                                                                          job?.lastRun?.status === 'DISABLED' ||
                                                                          job?.lastRun?.status === 'FAILED' ||
                                                                          job?.lastRun?.status === 'COMPLETED' }"
                                              class="status">
                                        {{ job?.lastRun?.status }}
                                    </mat-chip>
                                </mat-chip-listbox>
                            </td>
                        </ng-container>
                        <!-- Action -->
                        <ng-container matColumnDef="action">
                            <th *matHeaderCellDef mat-header-cell></th>
                            <td *matCellDef="let job" mat-cell>
                                <a (click)="run(job)" *ngIf="job?.schedule?.frequency === 'MANUAL'"
                                   class="row-action font-size-16 float-right"
                                   href="javascript: void(0);" matTooltip="Run job">
                                    <i class="fad fa-play-circle"></i>
                                </a>
                            </td>
                        </ng-container>
                        <!-- Expanded row with details -->
                        <ng-container matColumnDef="expandedDetails">
                            <td *matCellDef="let job" [attr.colspan]="displayedColumns.length" mat-cell>
                                <div [@detailExpand]="job == expandedElement ? 'expanded' : 'collapsed'" class="job-details">
                                </div>
                            </td>
                        </ng-container>

                        <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
                        <tr (click)="expandedElement = expandedElement === job ? null : job" *matRowDef="let job; columns: displayedColumns;"
                            [class.expanded]="expandedElement === job" class="job-row"
                            mat-row></tr>
                        <tr *matRowDef="let row; columns: ['expandedDetails']" class="job-details-row" mat-row></tr>
                    </table>
                </div>
            </div>
        </div>
        <div *ngIf="selectedTab === 'New'" style="background-color:white;">
            <form #jobCreateForm="ngForm" class="form-validation">
                <!-- job details -->
                <div class="job-details">
                    <div class="row">
                        <div class="col-lg-6 col-md-12">
                            <div class="row margin-bottom-10">
                                <div class="col-md-4 padding-right-0">
                                    <label class="margin-bottom-3">Name:</label>
                                </div>
                                <div class="col-md-8">
                                    <mat-form-field class="width-full">
                                        <input #name="ngModel" [ngModelOptions]="{standalone: true}" [(ngModel)]="job.name" [ngClass]="{'form-control-input-error': name.errors && ( name.dirty || name.touched )}"
                                               autocomplete="off"
                                               id="name" matInput minlength="3" name="name" required>
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="row margin-bottom-10">
                                <div class="col-md-4 padding-right-0">
                                    <label class="margin-bottom-3">Region:</label>
                                </div>
                                <div class="col-md-8">
                                    <div *ngFor="let region of options4region;" class="checkbox-wrapper margin-right-5">
                                        <mat-checkbox (change)="selectRegion($event, region)" [checked]="job.region.indexOf(region) !== -1"
                                                      [value]="region">
                                            {{ region }}
                                        </mat-checkbox>
                                    </div>
                                </div>
                            </div>

                            <div class="row margin-bottom-10">
                                <div class="col-md-4 padding-right-0">
                                    <label class="margin-bottom-3">Type:</label>
                                </div>
                                <div class="col-md-8">
                                    <mat-radio-group [ngModelOptions]="{standalone: true}" [(ngModel)]="job.type" name="type">
                                        <mat-radio-button *ngFor="let type of options4type;" [value]="type" class="margin-right-5">
                                            {{ type }}
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </div>
                            </div>

                            <!-- Buttons -->
                            <button (click)="create(jobCreateForm.form.valid)" [disabled]="jobCreateForm.form.invalid"
                                    [ladda]="isLoading" class="btn btn-primary float-left" data-spinner-size="20" data-style="zoom-out">
                                Create
                            </button>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="panel background-light-grey">
                                <div class="panel-heading"><h5>Schedule</h5></div>
                                <div class="panel-body">
                                    <!-- frequency -->
                                    <div class="row margin-bottom-10">
                                        <div class="col-md-4 padding-right-0">
                                            <label class="margin-bottom-3">Frequency:</label>
                                        </div>
                                        <div class="col-md-8">
                                            <mat-radio-group [ngModelOptions]="{standalone: true}" [(ngModel)]="job.schedule.frequency" name="frequency">
                                                <mat-radio-button *ngFor="let frequency of options4frequency;" [value]="frequency.value" class="margin-right-5">
                                                    {{ frequency.name }}
                                                </mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                    </div>
                                    <div *ngIf="job.schedule.frequency !== 'MANUAL'">
                                        <!-- dayOfWeek -->
                                        <div *ngIf="job.schedule.frequency === 'MONTHLY' || job.schedule.frequency === 'YEARLY'" class="row margin-bottom-10">
                                            <div class="col-md-4 padding-right-0">
                                                <label class="margin-bottom-3">Date:</label>
                                            </div>
                                            <div class="col-md-8">
                                                <mat-form-field class="margin-right-15">
                                                    <input [ngModelOptions]="{standalone: true}" [(ngModel)]="date" [matDatepicker]="datePicker" matInput name="date">
                                                    <mat-datepicker-toggle [for]="datePicker" matSuffix></mat-datepicker-toggle>
                                                    <mat-datepicker #datePicker startView="year"></mat-datepicker>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <!-- dayOfWeek -->
                                        <div *ngIf="job.schedule.frequency === 'WEEKLY'" class="row margin-bottom-10">
                                            <div class="col-md-4 padding-right-0">
                                                <label class="margin-bottom-3">Day (week):</label>
                                            </div>
                                            <div class="col-md-8">
                                                <mat-radio-group [ngModelOptions]="{standalone: true}" [(ngModel)]="job.schedule.dayOfWeek" name="dayOfWeek">
                                                    <mat-radio-button *ngFor="let dayOfWeek of options4day;" [value]="dayOfWeek.value" class="margin-right-5">
                                                        {{ dayOfWeek.name }}
                                                    </mat-radio-button>
                                                </mat-radio-group>
                                            </div>
                                        </div>
                                        <!-- time -->
                                        <div class="row">
                                            <div class="col-md-4 padding-right-0">
                                                <label class="margin-bottom-3">Time:</label>
                                            </div>
                                            <div class="col-md-8">
                                                <input [ngModelOptions]="{standalone: true}" [(ngModel)]="time" [owlDateTimeTrigger]="dt1" [owlDateTime]="dt1" class="time-picker"
                                                       name="time" placeholder="00:00"
                                                       style="cursor: pointer; max-width: 300px;" type="text">
                                                <owl-date-time #dt1 [pickerType]="'timer'" [stepMinute]="30"></owl-date-time>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
