/**
 * Created by Arindam Bajpayee on 23/05/2022.
 */
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";

import { environment } from "../../environments/environment";
import { AuthenticationService } from "./authentication.service";
import { Report } from "../models/report";

@Injectable()
export class ReportService {
    constructor(private http: HttpClient,
                private authenticationService: AuthenticationService) {
    }

    /* Create a new report */
    create(report: Report) {
        return this.http.post(
            `${environment.baseurl.api}/protected/report`,
            { report },
            this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    /* Download the report */
    download(report: Report) {
        return this.http.post(
            `${environment.baseurl.api}/protected/report/id=${report._id}/download`,
            { report },
            this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    /* Run the report */
    run(report: Report) {
        return this.http.post(
            `${environment.baseurl.api}/protected/report/id=${report._id}/run`,
            { report },
            this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    /* Get all reports */
    getReports() {
        return this.http.get(
            `${environment.baseurl.api}/protected/report/all`,
            this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }
}
