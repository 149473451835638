import { DatePipe } from "@angular/common";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

export interface GroupedPayout {
    date: string;
    currency: string;
    amount: number;
    showStatuses: boolean;
    payoutList: [];
    mainStatus: string;
    statuses: {};
    description?: string;
    phone?: string;
    status?: string;
}

@Injectable({
    providedIn: "root"
})
export class AtmPayoutsStateService {
    readonly PAYOUT_STATUSES = {
        initiated: "initiated",
        uploaded: "uploaded",
        validated: "validated",
        accepted: "accepted",
        rejected: "rejected"
    };

    readonly ALL_YEARS_KEY = "all";

    public groupedPayouts: BehaviorSubject<GroupedPayout[]> = new BehaviorSubject<GroupedPayout[]>([]);
    public filteredPayouts: BehaviorSubject<GroupedPayout[]> = new BehaviorSubject<GroupedPayout[]>([]);
    public availableYears: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

    constructor(
        private datePipe: DatePipe,
    ) {
    }

    setAtmPayouts(payouts: any[], dateKeyName = "date", showPopover = false) {
        const years: Set<string> = new Set();

        const groupedPayouts = payouts?.reduce((prev, cur) => {
            const date = this.datePipe.transform(cur[dateKeyName], "y.MM");
            years.add(this.datePipe.transform(cur[dateKeyName], "YYYY"));

            if (prev[date]) {
                prev[date].list = [...prev[date].list, cur];
                prev[date].amount = Number((prev[date].amount + cur.amount).toFixed(3));

                if (showPopover && cur.status) {
                    prev[date].showStatuses = true;
                    prev[date].statuses[cur.status] = prev[date].statuses[cur.status] ? prev[date].statuses[cur.status] + 1 : 1;
                }
            } else {
                prev[date] = {};
                prev[date].date = cur[dateKeyName];
                prev[date].list = [cur];
                prev[date].amount = cur.amount;
                prev[date].currency = cur.currency;
                prev[date].statuses = {};

                if (showPopover && cur.status) {
                    prev[date].showStatuses = true;
                    prev[date].statuses = {
                        [cur.status]: 1
                    };
                }
            }

            return prev;
        }, {});

        this.availableYears.next(Array.from(years));
        if (groupedPayouts) {
            const parsedPayouts = Object.values(groupedPayouts) as GroupedPayout[];
            parsedPayouts.forEach((payout) => {
                if (!showPopover) {
                    payout.mainStatus = this.PAYOUT_STATUSES.accepted;
                } else {
                    if (payout.statuses[this.PAYOUT_STATUSES.rejected]) {
                        payout.mainStatus = this.PAYOUT_STATUSES.rejected;
                    } else if (payout.statuses[this.PAYOUT_STATUSES.uploaded]) {
                        payout.mainStatus = this.PAYOUT_STATUSES.uploaded;
                    } else if (payout.statuses[this.PAYOUT_STATUSES.validated]) {
                        payout.mainStatus = this.PAYOUT_STATUSES.validated;
                    } else if (payout.statuses[this.PAYOUT_STATUSES.accepted]) {
                        payout.mainStatus = this.PAYOUT_STATUSES.accepted;
                    }
                }
            });

            this.groupedPayouts.next(parsedPayouts);
            this.filteredPayouts.next(parsedPayouts);
        }
    }

    onFilterPayouts(year) {
        if (year === this.ALL_YEARS_KEY) {
            this.filteredPayouts.next(this.groupedPayouts.value);
        } else {
            this.filteredPayouts.next(this.groupedPayouts.value.filter(({date}) => date.includes(year)));
        }
    }

}
