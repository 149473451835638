/**
 * Created by Arindam Bajpayee on 27-04-2017.
 */
import { SeriesOptionsType } from "highcharts";
import { AfterViewInit, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { Chart } from "angular-highcharts";
import moment from "moment";
import { NgProgress } from "@ngx-progressbar/core";
import { ToastrService } from "ngx-toastr";

/* Services */
import { AlertService } from "../../../../../services/alert.service";
import { AtmService } from "../../../../../services/atm.service";
import { AuthenticationService } from "../../../../../services/authentication.service";
import { ConfigurationService } from "../../../../../services/configuration.service";
import { PusherService } from "../../../../../services/pusher/pusher.service";
import { TransactionService } from "../../../../../services/transaction.service";
import { UserService } from "../../../../../services/user.service";
import { SelectedCountryData } from "../../../../shared/components/domain-selector.component";

@Component({
    selector: "bo-dashboard",
    templateUrl: "./dashboard.component.html",
    styleUrls: [ "./dashboard.component.css" ]
})
export class DashboardComponent implements AfterViewInit, OnInit {
    alerts = {
        lastSuccessfulTransactionTime: { value: null, unit: "", status: "" },
        lastTransactionsStatus: {
            value: {
                failed: 0,
                successful: 0
            },
            ratio: 0,
            status: ""
        }
    };
    atms = {
        total: { value: 0, loaded: false },
        monthly: { value: 0, loaded: false },
        weekly: { value: 0, loaded: false },
        daily: { value: 0, loaded: false }
    };
    atmsHistory = [];
    country = {
        code: "",
        currency: "",
        name: ""
    };
    currency = "CHF";
    dateFrom: Date = null;
    dateTo: Date = null;
    filter: any = { country: "", zipCode: "" };
    filterChange = false;
    month = (new Date()).toLocaleString("en-us", { month: "long" });
    noOfWidgets = 0;
    noOfWidgetsLoaded = 0;
    selectedTabIndex = 0; // 0 = OKR/KPI, 1 = ATMs, 2 = Transactions, 3 = Users
    transactions = {
        total: { value: 0, loaded: false },
        monthly: { value: 0, loaded: false },
        weekly: { value: 0, loaded: false },
        daily: { value: 0, loaded: false }
    };
    transactionsChart: Chart;
    transactionsPieChart: Chart;
    transactionsHistory = [];
    users = {
        total: { value: 0, loaded: false },
        monthly: { value: 0, loaded: false },
        weekly: { value: 0, loaded: false },
        daily: { value: 0, loaded: false }
    };
    usersHistory = [];
    usersChart: Chart;
    usersPaymentOptionsChart: Chart;
    countryCode: string;

    maxDate = new Date();
    constructor(
        private router: Router,
        private progressBar: NgProgress,
        private toastr: ToastrService,
        private alertService: AlertService,
        private atmService: AtmService,
        private authenticationService: AuthenticationService,
        private configurationService: ConfigurationService,
        private pusherService: PusherService,
        private transactionService: TransactionService,
        private userService: UserService) {
    }

    ngOnInit(): void {
        if (!this.authenticationService.isUserLoggedIn()) {
            this.router.navigate([ "/login" ]);
            return;
        }

        this.noOfWidgets = 0;
        this.noOfWidgetsLoaded = 0;
    }

    ngAfterViewInit(): void {
        if (!this.pusherService.isConnected("dashboard")) {
            this.pusherService.subscribeToChannel("topup", [ "credited" ], (data) => {
                this.toastr.info(data.message, data.title);
                // const sound = new Howl({ src: [ "../../../../assets/sound/quite-impressed.mp3" ] });
                // sound.play();
            });

            this.pusherService.subscribeToChannel("transaction", [ "confirmed" ], (data) => {
                this.toastr.info(data.message, data.title);
                // const sound = new Howl({ src: [ "../../../../assets/sound/quite-impressed.mp3" ] });
                // sound.play();
                if (this.router.url === "/admin/dashboard") {
                    this.noOfWidgets = 0;
                    this.noOfWidgetsLoaded = 0;
                    this.progressBar.ref().start();
                    this.reloadWidgetsForTransactions();
                }
            });

            this.pusherService.subscribeToChannel("transaction", [ "failed" ], (data) => {
                this.toastr.error(data.message, data.title);
                // const sound = new Howl({ src: [ "../../../../assets/sound/beyond-doubt.mp3" ] });
                // sound.play();
                if (this.router.url === "/admin/dashboard") {
                    this.noOfWidgets = 0;
                    this.noOfWidgetsLoaded = 0;
                    this.progressBar.ref().start();
                    this.reloadWidgetsForTransactions();
                }
            });

            this.pusherService.subscribeToChannel("user", [ "registered" ], (data) => this.toastr.info(data.message, data.title));
        }
    }

    reloadWidgetsForTotal(): void {
        this.filter.from = (this.dateFrom !== null && typeof this.dateFrom !== "undefined") ?
            this.dateFrom.getTime() : null; // 01.04.2017 00:00:00
        this.filter.to = (this.dateTo !== null && typeof this.dateTo !== "undefined") ? this.dateTo.getTime() : null; // now
        this.noOfWidgets += 3;

        /* Widget - Total ATMs */
        this.atmService.getAtmsCount(null, this.filter, this.country)
            .subscribe(response => {
                const data: any = { ...response };
                this.atms.total.value = data.payload.count;
                this.atms.total.loaded = true;
                this.incrementProgressBar();
            });

        /* Widget - Total Transactions */
        this.transactionService.getTransactionsAmount({
            currency: this.country.currency, frequency: null, filter: this.filter, country: this.country
        }).subscribe(response => {
            const data: any = { ...response };
            this.transactions.total.value = data.payload.amount;
            this.transactions.total.loaded = true;
            this.incrementProgressBar();
        });

        /* Widget - Total Users */
        this.userService.getUsersCount(null, this.filter, this.country)
            .subscribe(response => {
                const data: any = { ...response };
                this.users.total.value = data.payload.count;
                this.users.total.loaded = true;
                this.incrementProgressBar();
            });
        this.reloadWidgetsForTransactions();
    }

    reloadWidgetsForTransactions(): void {
        this.filter.from = (this.dateFrom !== null && typeof this.dateFrom !== "undefined") ?
            this.dateFrom.getTime() : null; // 01.04.2017 00:00:00
        this.filter.to = (this.dateTo !== null && typeof this.dateTo !== "undefined") ? this.dateTo.getTime() : null; // now
        this.noOfWidgets += 2;

        /* Alerts */
        this.alertService.getAlertsForDashboard(this.country.code)
            .subscribe(response => {
                const data: any = { ...response };

                const lastSuccessfulTransactionTime = data.payload.lastSuccessfulTransactionTime;
                const duration = moment.duration(moment().diff(moment(lastSuccessfulTransactionTime)));
                const durationInMinutes = duration.asMinutes();
                if (durationInMinutes <= 60) {
                    this.alerts.lastSuccessfulTransactionTime.status = "normal";
                    this.alerts.lastSuccessfulTransactionTime.unit = "minutes";
                } else if (durationInMinutes > 60 && durationInMinutes <= 180) {
                    this.alerts.lastSuccessfulTransactionTime.status = "warning";
                    this.alerts.lastSuccessfulTransactionTime.unit = "hours";
                } else {
                    this.alerts.lastSuccessfulTransactionTime.status = "danger";
                    this.alerts.lastSuccessfulTransactionTime.unit = "hours";
                }
                this.alerts.lastSuccessfulTransactionTime.value = lastSuccessfulTransactionTime;

                this.alerts.lastTransactionsStatus.value.failed = data.payload.lastTransactionsStatus.failed;
                this.alerts.lastTransactionsStatus.value.successful = data.payload.lastTransactionsStatus.successful;
                this.alerts.lastTransactionsStatus.ratio =
                    this.alerts.lastTransactionsStatus.value.successful
                    / (this.alerts.lastTransactionsStatus.value.successful + this.alerts.lastTransactionsStatus.value.failed);
                if (this.alerts.lastTransactionsStatus.ratio >= 0.8) {
                    this.alerts.lastTransactionsStatus.status = "normal";
                } else if (this.alerts.lastTransactionsStatus.ratio >= 0.5 && this.alerts.lastTransactionsStatus.ratio < 0.8) {
                    this.alerts.lastTransactionsStatus.status = "warning";
                } else {
                    this.alerts.lastTransactionsStatus.status = "danger";
                }
            });

        /* Widget - Total Transactions history */
        this.transactionService.getTransactionsHistory({ currency: this.country.currency, filter: this.filter, country: this.country })
            .subscribe(response => {
                const data: any = { ...response };
                this.transactionsHistory = data.payload.history;
                this.transactionsChart = new Chart({
                    title: { text: "Transactions history" },
                    xAxis: { categories: data.payload.chartData.months },
                    yAxis: [ {
                        allowDecimals: false,
                        min: 0,
                        title: { text: "Amount (" + this.currency + ")" }
                    }, {
                        allowDecimals: false,
                        opposite: true,
                        title: { text: "Count" }
                    } ],
                    legend: { shadow: false },
                    tooltip: { shared: true },
                    plotOptions: {
                        column: {
                            grouping: false,
                            shadow: false,
                            borderWidth: 0
                        }
                    },
                    series: [ {
                        type: "column",
                        name: "Amount",
                        color: "rgba(255, 163, 0, 1)",
                        data: data.payload.chartData.amounts,
                        yAxis: 0
                    }, {
                        type: "spline",
                        name: "Count",
                        color: "rgba(186, 60, 61, .9)",
                        data: data.payload.chartData.counts,
                        yAxis: 1
                    } ]
                });
                this.incrementProgressBar();
            });

        /* Widget - Total Transactions */
        this.transactionService.getTransactionsAmountByPaymentMethod(this.country.currency, this.filter, this.country)
            .subscribe(response => {
                const data: any = { ...response };
                const series: SeriesOptionsType[] = [ {
                    name: "Brands",
                    data: data.payload.pieChart
                } as any ];
                this.transactionsPieChart = new Chart({
                    chart: {
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        type: "pie"
                    },
                    title: { text: "By payment method" },
                    tooltip: { pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>" },
                    plotOptions: {
                        pie: {
                            colors: data.payload.colors,
                            allowPointSelect: true,
                            cursor: "pointer",
                            dataLabels: {
                                enabled: true,
                                format: "<b>{point.name}</b>: {point.percentage:.1f} %"
                            }
                        }
                    },
                    series
                });
                this.incrementProgressBar();
            }, (error) => {
                console.log("error in data by method", error);
            });
    }

    searchByFilter(): void {
        this.filterChange = true;
        this.noOfWidgets = 0;
        this.progressBar.ref().start();
        this.reloadWidgetsForTotal();
    }

    // Get the domain, country and currency info
    selectedCountry(data: SelectedCountryData) {
        this.country = data;
        this.countryCode = data.code;
        this.filterChange = true;
        // this.noOfWidgets = 0;
        this.progressBar.ref().start();
        this.reloadWidgetsForTotal();
    }

    selectCurrency($event, currency: string): void {
        this.currency = currency;
        this.filterChange = true;
        // this.noOfWidgets = 0;
        this.progressBar.ref().start();
        this.reloadWidgetsForTotal();
    }

    /* PRIVATE METHODS */
    private incrementProgressBar(): void {
        this.noOfWidgetsLoaded += 1;
        if (this.noOfWidgetsLoaded === this.noOfWidgets) {
            this.noOfWidgets = 0;
            this.noOfWidgetsLoaded = 0;
            this.progressBar.ref().complete();
        } else {
            this.progressBar.ref().inc(1 / this.noOfWidgets);
        }
    }
}
