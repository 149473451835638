import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class DataTableStateService {
  atmTableState$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  atmTableState: Observable<any> = this.atmTableState$.asObservable();

  partnerTableState$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  partnerTableState: Observable<any> = this.partnerTableState$.asObservable();

  paymentTableState$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  paymentTableState: Observable<any> = this.paymentTableState$.asObservable();

  trasactionTableState$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  trasactionTableState: Observable<any> = this.trasactionTableState$.asObservable();

  userTableState$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  userTableState: Observable<any> = this.userTableState$.asObservable();

  setAtmTableState(state) {
    this.atmTableState$.next(state);
  }

  setPartnerTableState(state) {
    this.partnerTableState$.next(state);
  }

  setPaymentTableState(state) {
    this.paymentTableState$.next(state);
  }

  setTrasnactionTableState(state) {
    this.trasactionTableState$.next(state);
  }

  setUserTableState(state) {
    this.userTableState$.next(state);
  }

  resetAllTables() {
    this.setAtmTableState(null);
    this.setPartnerTableState(null);
    this.setPaymentTableState(null);
    this.setTrasnactionTableState(null);
    this.setUserTableState(null);
  }
}

export enum TABLE_NAMES {
  atm,
  partner,
  payment,
  trasaction,
  user,
}
