/**
 * Created by Surya Vedula on 22-05-2017.
 */
import { CommonModule, DatePipe } from "@angular/common";
import { NgModule } from "@angular/core";
import { NgbPopoverModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule } from "@angular/forms";
import { AngularMaterialModule } from "../../angular-material.module";

/* Components */
import { DomainSelectorComponent } from "./components/domain-selector.component";
import { EditActionsComponent } from "./components/edit-actions/edit-actions.component";
import { PanelComponent } from "./components/panel/panel.component";
import { PayoutListComponent } from "./components/payout-list/payout-list.component";
import { TransactionHistoryComponent } from "./components/transaction-history.component";
import { TransactionStatusComponent } from "./components/transaction-status.component";
import { UserProfileStatusComponent } from "./components/user-profile-status.component";
import { ConfirmDeleteEntityWithBalanceComponent } from "./components/confirm-delete-entity-with-balance/confirm-delete-entity-with-balance.component";
import { FileUploadComponent } from "./components/file-upload/file-upload.component";
import { PrintComponent } from "./components/print/print.component"

/* Pipe */
import { TransactionStatusPipe } from "./pipes/transaction-status.pipe";
import { TransactionFilterPipe } from "./pipes/transaction-filter.pipe";

import { AtmPayoutsStateService } from './components/payout-list/atm-payouts-state.service'

@NgModule({
    declarations: [
        DomainSelectorComponent,
        EditActionsComponent,
        PanelComponent,
        PayoutListComponent,
        TransactionFilterPipe,
        TransactionHistoryComponent,
        TransactionStatusComponent,
        TransactionStatusPipe,
        UserProfileStatusComponent,
        ConfirmDeleteEntityWithBalanceComponent,
        FileUploadComponent,
        PrintComponent
    ],
    exports: [
        DomainSelectorComponent,
        EditActionsComponent,
        PanelComponent,
        PayoutListComponent,
        TransactionFilterPipe,
        TransactionHistoryComponent,
        TransactionStatusComponent,
        TransactionStatusPipe,
        UserProfileStatusComponent,
        ConfirmDeleteEntityWithBalanceComponent,
        FileUploadComponent,
        PrintComponent
    ],
    imports: [
        CommonModule,
        AngularMaterialModule,
        NgbPopoverModule,
        FormsModule
    ],
    providers: [
        DatePipe,
        AtmPayoutsStateService
    ]
})
export class SharedModule {
}
