import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

export interface DialogData {
    entity: string;
    actionName: string;
}

@Component({
    selector: "bo-confirm-delete-entity-with-balance",
    templateUrl: "./confirm-delete-entity-with-balance.component.html",
    styleUrls: ["./confirm-delete-entity-with-balance.component.scss"]
})
export class ConfirmDeleteEntityWithBalanceComponent {

    constructor(
        public dialogRef: MatDialogRef<ConfirmDeleteEntityWithBalanceComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    }

    apply(): void {
        this.dialogRef.close(true);
    }

    cancel(): void {
        this.dialogRef.close();
    }
}
