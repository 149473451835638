/**
 * Created by Arindam Bajpayee on 17-06-2017.
 */
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";

import { environment } from "../../environments/environment";
import { AuthenticationService } from "./authentication.service";
import { Observable } from "rxjs";
import { Country } from "../models/country";

@Injectable({ providedIn: "root" })
export class ConfigurationService {
    constructor(private http: HttpClient, private authenticationService: AuthenticationService) {
    }

    /* Get all report configurations */
    getReportConfigs(timezoneId: string) {
        return this.http.post(
            environment.baseurl.api + "/protected/config/reports/all", {timezoneId}, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    /* Get Country list */
    getCountryData(): Observable<{ countries: Country[] }> {
        return this.http.get<{ countries: Country[] }>(
            environment.baseurl.api + "/protected/countries",
            this.authenticationService.getHeaders()
        );
    }
    getServerInfo() {
        return this.http.get(
            environment.baseurl.api + "/public/server/version"
        ).pipe(map(res => res));
    }
}
