/**
 * Created by Arindam Bajpayee on 27-04-2017.
 */
import { Component, Input } from "@angular/core";

@Component({
    selector: "bo-transaction-status",
    template: `
        <div *ngIf="status"
             class="transaction-status"
             [ngClass]="{
                 'failed':status === -999,
                 'expired':status === -9,
                 'cancelled':status === -2,
                 'rejected':status === -1,
                 'pending':status === 1,
                 'confirmed':status === 2
             }">
            {{ status | transactionStatus }}
        </div>
        <div *ngIf="potentialUnlinked"
            class="transaction-status cancelled"
            >
            POTENTIAL UNLINKED
        </div>
    `,
    styleUrls: [ "../../admin/transaction/transaction.component.scss" ]
})
export class TransactionStatusComponent {
    @Input() status: number;
    @Input() potentialUnlinked: boolean;
}
