/**
 * Created by Arindam Bajpayee on 23/05/2022.
 */
import { History } from "./history";

export class Report {
    _id: string;
    creationDate: Date;
    for: string;
    history: History [];
    lastRun: {
        by: string,
        date: Date,
        status: string
    };
    modificationDate: Date;
    name: string;
    owner: {
        id: string,
        name: string,
        roles: string []
    };
    private: boolean;
    query: string;
    reportId: string;
    schedule: {
        day: string [],
        time: string []
    };
}
