<div class="shops-actions">
    <div class="shops-actions__title">{{ title }}</div>
    <label for="file" *ngIf="!selectedFiles.length">
        <input #ref type="file" id="file" accept="text/csv"
               [disabled]="disabledAction"
               (change)="selectFile($event)">
        <button class="btn btn-secondary" type="button"
                [disabled]="disabledAction"
                (click)="ref.click()">
            {{ action }}
        </button>
    </label>
</div>

<ul class="file-list" *ngIf="selectedFiles.length">
    <li class="file-list__item file-item" *ngFor="let file of selectedFiles; let idx = index;">
        <mat-icon class="file-item__icon">insert_drive_file</mat-icon>
        <span class="file-item__name">{{ file[0].name }}</span>
        <mat-icon class="file-item__icon file-item__icon--remove" (click)="removeFile(idx)">close</mat-icon>
    </li>
</ul>

<div class="shops-actions">
    <mat-checkbox *ngIf="autoApproveUI"
                  [disabled]="!selectedFiles.length"
                  (change)="changeAutoApprove($event)">
        <span class="file-approve">Auto-Approve</span>
    </mat-checkbox>
    <div class="margin-top-20" *ngIf="changeStatusBtn">
        <button mat-raised-button class="btn-shops-status"
                [color]="count?.unverified === 0 ? 'warn' : 'accent'"
                [disabled]="count?.total === 0"
                (click)="changeAtmsStatus()">
            {{ count?.unverified === 0 ? "Disable" : "Enable" }}
        </button>
    </div>
</div>
