<div class="atm-info-print">
  <button mat-icon-button (click)="print()" matTooltip="Save as PDF">
      <mat-icon>print</mat-icon>
  </button>

  <div id="{{printDataNodeId}}">
    <ng-content></ng-content>
  </div>

</div>
