<div class="panel">
    <div class="panel-heading" #panelHeader [hidden]="!showHeader">
        <ng-content select="[header]"></ng-content>
        <div class="panel-actions" #panelActions [hidden]="!showHeader">
            <ng-content select="[header-actions]"></ng-content>
        </div>
    </div>
    <div class="panel-body" #panelBody [hidden]="!showBody">
        <ng-content select="[body]"></ng-content>
    </div>
    <div class="panel-footer" #panelFooter [hidden]="!showFooter">
        <ng-content select="[footer]"></ng-content>
    </div>
</div>
