/**
 * Created by Arindam Bajpayee on 22-05-2017.
 */
import { Pipe, PipeTransform } from "@angular/core";
import { Transaction } from "../../../models/transaction";

@Pipe({
    name: "transactionFilter",
    pure: false
})
export class TransactionFilterPipe implements PipeTransform {
    transform(value: Transaction[], filter: any): Transaction[] {
        /* If the filter object is missing return the original list */
        if (!filter) { return value; } else {
            filter.summary.count = 0;
            filter.summary.amount = 0;

            return value.filter((trx: Transaction) => {
                const showEntry = this.applyFilter(trx, filter);
                if (showEntry) {
                    filter.summary.count += 1;
                    filter.summary.amount += trx.amount;
                }

                return showEntry;
            });
        }
    }

    applyFilter(trx: Transaction, filter: any): boolean {
        let showEntry = true;
        if (typeof filter.atm !== "undefined" && filter.atm !== null && filter.atm.length > 0) {
            if (trx.to &&
                ((trx.to.name && trx.to.name.toLocaleLowerCase().indexOf(filter.atm.toLocaleLowerCase()) >= 0) ||
                    (trx.to.phone && trx.to.phone.indexOf(filter.atm.toLocaleLowerCase()) >= 0) ||
                    (trx.to.referenceId && trx.to.referenceId.indexOf(filter.atm.toLocaleLowerCase()) >= 0) ||
                    (trx.to.master &&
                        ((trx.to.master.name && (trx.to.master.name.toLocaleLowerCase()).indexOf(filter.atm.toLocaleLowerCase()) >= 0) ||
                            (trx.to.master.phone && trx.to.master.phone.indexOf(filter.atm.toLocaleLowerCase()) >= 0) ||
                            (trx.to.master.referenceId && trx.to.master.referenceId.toLocaleLowerCase().indexOf(filter.atm.toLocaleLowerCase()) >= 0))))) {
                showEntry = showEntry && true;
            } else {
                showEntry = showEntry && false;
            }
        } else {
            showEntry = showEntry && true;
        }

        if (filter.user && filter.user.length > 0 && trx.from) {
            if (trx.from.phone?.indexOf(filter.user.toLocaleLowerCase()) >= 0
                || (trx.from.name.toLocaleLowerCase()).indexOf(filter.user.toLocaleLowerCase()) >= 0
                || (trx.from.id?.toLocaleLowerCase()).indexOf(filter.user.toLocaleLowerCase()) >= 0) {
                showEntry = showEntry && true;
            } else {
                showEntry = showEntry && false;
            }
        } else {
            showEntry = showEntry && true;
        }

        if (typeof filter.payment !== "undefined" && filter.payment !== null && filter.payment.length > 0) {
            if (typeof trx.payment !== "undefined" && trx.payment !== null
                && typeof trx.payment.method !== "undefined" && trx.payment.method !== null && trx.payment.method.length > 0) {
                showEntry = showEntry && ((trx.payment.method.toLocaleLowerCase().indexOf(filter.payment.toLocaleLowerCase()) >= 0) || (trx.payment.bankId.toLocaleLowerCase().indexOf(filter.payment.toLocaleLowerCase()) >= 0));
            } else {
                showEntry = showEntry && true;
            }
        } else {
            showEntry = showEntry && true;
        }

        return showEntry;
    }
}
