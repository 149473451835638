/**
 * Created by Arindam Bajpayee on 25-04-2017.
 */
import { AngularMaterialModule } from "../../angular-material.module";
import { ChartModule } from "angular-highcharts";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { LaddaModule } from "angular2-ladda";
import { NgModule } from "@angular/core";
import { NgbActiveModal, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgProgressModule } from "@ngx-progressbar/core";
/* App-root */
import { AdminComponent } from "./admin.component";
/* Other Modules / Components */
import { AuditsAtmComponent } from "./audits/atm/audits-atm.component";
import { DashboardComponent } from "./dashboard/containers/dashboard/dashboard.component";
import { JobComponent } from "./job/job.component";
import { LimitsComponent } from "./limits/limits.component";
import { LimitsUserComponent } from "./limits/components/limits-user.component";
import { MonitorComponent } from "./monitor/monitor.component";
import { PaymentComponent } from "./payment/payment.component";
import { ProfileComponent } from "./profile/profile.component";
import { ReportComponent, ReportQueryDialog } from "./report/report.component";

/* Routes */
import { AdminRoutingModule } from "./admin-routing.module";
import { NavigationPartnerGuard } from "../../guards/navigation-partner.guard";
import { NavigationReportsGuard } from "../../guards/navigation-reports.guard";
import { NavigationTransactionGuard } from "../../guards/navigation-transaction.guard";
import { NavigationUserGuard } from "../../guards/navigation-user.guard";
import { SharedModule } from "../shared/shared.module";
import { OWL_DATE_TIME_FORMATS, OwlDateTimeModule, OwlNativeDateTimeModule } from "@danielmoncada/angular-datetime-picker";
import { DataTableModule } from "../shared/components/data-table/data-table.module";
import { EnvironmentNameComponent } from "../shared/components/environment-name/environment-name.component";

/* Services */
export const MY_NATIVE_FORMATS = {
    timePickerInput: {hour: "numeric", minute: "numeric", hour12: false}
};

@NgModule({
    declarations: [
        AdminComponent,
        AuditsAtmComponent,
        DashboardComponent,
        JobComponent,
        LimitsComponent,
        LimitsUserComponent,
        MonitorComponent,
        PaymentComponent,
        ProfileComponent,
        ReportComponent,
        ReportQueryDialog,
        EnvironmentNameComponent
    ], // Since this is loaded by type
    imports: [
        // Angular Material
        AngularMaterialModule,
        ChartModule,
        CommonModule,
        FormsModule,
        HttpClientModule,
        LaddaModule,
        NgbModule,
        NgProgressModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        ReactiveFormsModule,
        SharedModule,
        DataTableModule,
        // Routing - keep it at the end
        AdminRoutingModule
    ],
    providers: [
        NavigationPartnerGuard,
        NavigationReportsGuard,
        NavigationTransactionGuard,
        NavigationUserGuard,
        NgbActiveModal,
        { provide: OWL_DATE_TIME_FORMATS, useValue: MY_NATIVE_FORMATS }
    ]
})
export class AdminModule {
}
