import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { AuthenticationService } from "../services/authentication.service";

@Injectable({
    providedIn: "root"
})
export class NavigationReportsGuard implements CanActivate {
    constructor(private authenticationService: AuthenticationService) {
    }

    static isUserAllowed({ isAccountAdmin, isAccountSupport, isAccountLegal } = {} as any): boolean {
        return isAccountAdmin || isAccountSupport; // || isAccountLegal;
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        const user = this.authenticationService.getCurrentUser();
        return NavigationReportsGuard.isUserAllowed(user);
    }

}
