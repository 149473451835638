<div [ngClass]="modalOptions.severity" class="modal-header">
    <h4 class="modal-title">
        {{ modalOptions.title }}
    </h4>
    <button *ngIf="!creationPartnerId" (click)="activeModal.dismiss('Cross click')" aria-label="Close" class="close" type="button">
        <i class="far fa-times"></i>
    </button>
</div>

<div class="modal-body" [ngClass]="modalOptions.severity">
    <div class="row">
        <div class="col-lg-12">
            {{ modalOptions.message }}
        </div>
    </div>
</div>

<!-- Buttons -->
<div class="modal-footer" [ngClass]="modalOptions.severity">
    <button *ngFor="let action of modalOptions.actions" type="button" class="btn float-left"
            (click)="action.execute($event, activeModal)">
        {{ action.title }}
    </button>

    <button type="button" *ngIf="!creationPartnerId" class="btn float-right margin-right-0" (click)="close()">
        Close
    </button>
</div>
