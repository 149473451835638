<div *ngIf="loaded" class="display-inline-block margin-horizontal-10">
    <div class="dropdown">
        <button class="btn btn-secondary dropdown-toggle padding-horizontal-10 padding-vertical-0"
                data-toggle="dropdown"> {{ countryName || "Switzerland" }}
        </button>
        <ul class="dropdown-menu" role="menu">
            <a class="dropdown-item" *ngFor="let country of _countryList"
               (click)="changeCountry(country.cca2)" href="javascript: void(0)">
                {{ country.name }}
            </a>
        </ul>
    </div>
    <div *ngIf="showCurrency" class="dropdown padding-left-5">
        <button class="btn btn-secondary disabled padding-horizontal-10 padding-vertical-0"
                data-toggle="dropdown" href="#"> {{ currency || "CHF" }}
        </button>
    </div>
</div>
