import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";

import { environment } from "../../environments/environment";
import { AuthenticationService } from "./authentication.service";

@Injectable()
export class MigrationsService {
    constructor(private http: HttpClient,
                private authenticationService: AuthenticationService) {
    }

    getMigrations() {
        return this.http.get(
            environment.baseurl.api + "/protected/migrations",
            this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    runMigrations() {
        return this.http.post(
            environment.baseurl.api + "/protected/migrations",
            {
                currentUser: this.authenticationService.getCurrentUser()
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    rollbackMigration() {
        return this.http.delete(
            environment.baseurl.api + "/protected/migrations",
            this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }
}
