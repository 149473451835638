/**
 * Created by Arindam Bajpayee on 30-04-2018.
 */
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";

import { environment } from "../../environments/environment";
import { AuthenticationService } from "./authentication.service";
import { ResponseBO } from "../models/common";
import { ProtectedRequestService } from "./protected-request.service";
import { Observable } from "rxjs";
import { Partner, Tutorial } from "../models/partner";
import { PartnerDropdown } from "../models/partner-dropdown";
import { Response } from "../models/response";

export interface OptionCheckbox {
    name: string;
    checked: boolean;
}

export interface OnboardingStep {
    step: string;
    children?: OptionCheckbox[];
}

export interface PartnerSDKConfiguration {
    contact: {
        chat: string,
        faq: string,
        phone: string,
        email: string,
        partnerUrl: string,
        customTCName?: string,
        customPPName?: string
    };
    denominations: {
        topup: number[],
        transfer: number[],
        withdraw: number[],
        step: number,
    };
    features: {
        USER: any,
        SHOP: any,
    };
    mapCategories: {
        [key: string]: { name: string, checked: boolean }
    };
    backButtonLink?: string;
    authorizeTokenExpiry: number;
    scanditKeys: ScanditKeys[];
    transactionExpiry: {
        withdraw: number
    };
    sliderStepSize: number;
    onboarding: {
        user: OnboardingStep[],
        shop: OnboardingStep[]
    };
    kycProvider: KYCProvider;
    spotlight: Record<string, number[]>;
    legalCompliance?: {
        links?: any[];
        updatedTime?: string;
    };
}

export interface TutorialPayload {
    customerTutorials: Tutorial[];
    customerTutorialOptions: OptionCheckbox[];
    merchantTutorials?: Tutorial[];
    merchantTutorialOptions?: OptionCheckbox[];
}

export interface ScanditKeys {
    bundleId: string;
    scanditKey: string;
}

export type KYCProvider = {
    [key: string]: string;
};

@Injectable()
export class PartnerService {
    constructor(
        private http: HttpClient,
        private authenticationService: AuthenticationService,
        private protectedRequestService: ProtectedRequestService
    ) {
    }

    // private getCurrentUser(): string {
    //     return JSON.parse(sessionStorage.getItem('currentUser'));
    // }

    /* For PARTNER's page */
    create(partner: Partner) {
        return this.http.post(
            environment.baseurl.api + "/protected/partner/create",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                partner
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    checkSftp() {
        return this.http.get(environment.baseurl.api + "/protected/partner/checkSftp", this.authenticationService.getHeaders()).pipe(map(res => res));
    }

    downloadBNLOutputFiles() {
        return this.http.get(environment.baseurl.api + "/protected/partner/downloadBNLOutputFiles", this.authenticationService.getHeaders()).pipe(map(res => res));
    }

    createOwnersForPartner(country: any, data: object): Observable<any> {
        return this.http.post(
            environment.baseurl.api + "/protected/atm/createOwners",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                country,
                data
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    /* For creating multiple shops for a PARTNER */
    createAtmsForPartner(country: any, data: object) {
        return this.http.post(
            environment.baseurl.api + "/protected/atm/createForPartner",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                country,
                data
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    /* For enabling atms of a PARTNER */
    enableAtmForPartner(data: string, country: any) {
        return this.http.post(
            environment.baseurl.api + "/protected/atm/enablePartnerAtm",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                data,
                country
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    /* For disabling atms of a PARTNER */
    disableAtmForPartner(data: string, country: any) {
        return this.http.post(
            environment.baseurl.api + "/protected/atm/disablePartnerAtm",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                data,
                country
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    /* For getting count of atms onBoarded by a PARTNER */
    getBundleIds(partnerId: string) {
        return this.http.get(environment.baseurl.api + "/protected/partner/bundleIds?id=" + partnerId, this.authenticationService.getHeaders()).pipe(map(res => res));
    }

    /* For getting count of atms onBoarded by a PARTNER */
    getCountAtmByPartner(partnerId: string, country: any): Observable<Response> {
        return this.http.post<Response>(
            environment.baseurl.api + "/protected/atm/countPartnerAtm",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                partnerId,
                country
            }, this.authenticationService.getHeaders());
    }

    getPayoutsToPartner(data: string, country: any) {
        return this.http.post(
            environment.baseurl.api + "/protected/partner/payouts",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                data,
                country
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    getPartners() {
        return this.http.get(environment.baseurl.api + "/public/partner/all").pipe(map(res => res));
    }

    getAll(parameters, type: string) {
        const payload = {
            currentUser: this.authenticationService.getCurrentUser(),
            country: parameters.country,
            filter: parameters.filter,
            page: parameters.page,
            type: undefined
        };

        if (type === "RETAIL") {
            payload.type = "retail";
        }

        if (type === "BANK") {
            payload.type = "bank";
        }

        return this.http.post(
            environment.baseurl.api + "/protected/partner/all",
            payload, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    getByPartnerId = (partnerId: string) => this.http.get(`${environment.baseurl.api}/protected/partner/id=${partnerId}`, this.authenticationService.getHeaders()).pipe(map(res => res));

    getCountries = () => this.http.get(`${environment.baseurl.api}/public/partner/countries`).pipe(map(res => res));

    getLimitsByCountryCode(countryCode = "CH") {
        return this.http.get(
            `${environment.baseurl.api}/protected/partner/country=${countryCode}/limits`, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    getMerchantLimitsBy(countryCode?: string) {
        return this.http.get(
            `${environment.baseurl.api}/protected/partner/country=${countryCode}/limits/merchant`, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    // This service gets the existing SDK configuration of the partner
    getSdkConfig(partnerId: string): Observable<PartnerSDKConfiguration> {
        return this.protectedRequestService.post<PartnerSDKConfiguration>("/partner/sdkConfig", { partnerId }).pipe(
            map(({ payload }) => payload)
        );
    }

    getTutorial(partnerId: string): Observable<TutorialPayload> {
        return this.protectedRequestService.get<TutorialPayload>("/partner/tutorials?id=" + partnerId).pipe(
            map(({ payload }) => payload)
        );
    }

    getTypes() {
        return this.http.get(environment.baseurl.api + "/public/partner/types").pipe(map(res => res));
    }

    /* For generating summary(invoice) for PARTNER for a particular month and year */
    partnerSummary(partnerId: string, month: string, year: string) {
        const url = `${environment.baseurl.jobs}/job/start?name=PARTNER_SUMMARY&id=${partnerId}&month=${month}&year=${year}`;
        return this.http.get(url, this.authenticationService.getHeaders()).pipe(map(res => res));
    }

    /* For payout to partner */
    payout(parameters) {
        /*
        return this.http.post(
            environment.baseurl.api + '/protected/partner/payout',
            {
                currentUser: this.authenticationService.getCurrentUser(),
                type: type,
                currency: currency,
                partner: partner
            }, this.authenticationService.getHeadersForText()
        ).pipe(map(res => res));
        */
        // return this.http.get(
        //     environment.baseurl.jobs + '/job/start?name=PARTNER_PAYOUT&id=' + partner + '&currency=' + currency,
        //     this.authenticationService.getHeaders()
        // ).pipe(map(res => res));
        let url = "";
        if (parameters.payoutType === "commission") {
            url = environment.baseurl.jobs + "/job/start?name=PARTNER_PAYOUT_COMMISSIONS&id=" + parameters.partner + "&currency=" + parameters.currency;
        } else {
            url = environment.baseurl.jobs + "/job/start?name=PARTNER_PAYOUT&id=" + parameters.partner + "&currency=" + parameters.currency;
        }

        return this.http.get(url, this.authenticationService.getHeaders()).pipe(map(res => res));
    }

    /* Save Bundle Ids to Partner Collection in DB */
    saveBundleIds(id: string, bundleIds: string[]): Observable<Response> {
        return this.http.post<Response>(
            environment.baseurl.api + "/protected/partner/setBundleIds",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                partnerId: id,
                bundleIds
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    /* Save Bundle Ids to Partner Collection in DB */
    saveSdkConfig(id: string, data: any) {
        return this.http.post(
            environment.baseurl.api + "/protected/partner/setSdkConfig",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                partnerId: id,
                data
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    /* Save Tutorials to Partner Collection in DB */
    saveTutorials(payload: TutorialPayload & { partnerId: string }): Observable<any> {
        return this.protectedRequestService.post("/partner/setTutorials", payload);
    }

    /* Set the partner's credentials */
    setCommission(id: string, commission: any) {
        return this.http.post(
            environment.baseurl.api + "/protected/partner/commission",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                partnerId: id,
                commission
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    /* Set the partner's credentials */
    setCredentials(id: string) {
        return this.http.post(
            environment.baseurl.api + "/protected/partner/credentials",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                partnerId: id
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    /* Set the partner's credentials */
    setFees(id: string, pricing: any) {
        return this.http.post(
            environment.baseurl.api + "/protected/partner/fees",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                partnerId: id,
                pricing
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    /* Set the partner's KYC */
    setPartnerKyc(id: string, partnerKyc: any) {
        return this.http.post(
            environment.baseurl.api + "/protected/partner/setKyc",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                partnerId: id,
                partnerKyc
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    /* Set the partner's credentials */
    setPaymentDetails(id: string, paymentDetails: any) {
        return this.http.post(
            environment.baseurl.api + "/protected/partner/paymentDetails",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                partnerId: id,
                paymentDetails
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    setCategories(id: string, categories: string[]) {
        return this.http.post(
            environment.baseurl.api + "/protected/partner/categories",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                partnerId: id,
                categories
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    setMerchantLimits(id: string, limits: { [key: string]: any }) {
        return this.http.post(
            environment.baseurl.api + "/protected/partner/limits/merchant",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                partnerId: id,
                limits
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    // TODO: Remove after category is working fine
    setType(id: string, type: string) {
        return this.http.post(
            environment.baseurl.api + "/protected/partner/type",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                partnerId: id,
                type
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    triggerMissedPayout(parameters) {
        return this.http.get(
            environment.baseurl.jobs + "/job/start?name=PARTNER_PAYOUT_MISSED&id=" + parameters.id + "&currency=" + parameters.currency + "&payoutDateFrom=" + (parameters.payoutTriggerDateFrom !== null ? parameters.payoutTriggerDateFrom : "") + "&payoutDateTo=" + (parameters.payoutTriggerDateTo !== null ? parameters.payoutTriggerDateTo : "") + "&reference=" + (parameters.reference !== null ? parameters.reference : "")
            , this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    triggerMissedPayoutCommission(parameters) {
        const {
            id,
            currency,
            payoutDateFrom = "",
            payoutDateTo = ""
        } = parameters;

        return this.http.get(
            `${environment.baseurl.jobs}/job/start?name=PARTNER_PAYOUT_COMMISSIONS_MISSED&id=${id}&currency=${currency}&payoutDateFrom=${payoutDateFrom}&payoutDateTo=${payoutDateTo}`
            , this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    triggerReconciliationReport(parameters) {
        return this.http.get(
            environment.baseurl.jobs + "/job/start?name=PARTNER_RECONCILIATION_REPORT&id=" + parameters.id + "&currency=" + parameters.currency + (parameters.from !== null && parameters.to !== null ? ("&from=" + parameters.from + "&to=" + parameters.to) : "") + (parameters.sendEmailOnlyToSupport !== null ? ("&sendOnlyToSupport=" + parameters.sendEmailOnlyToSupport) : "")
            , this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    triggerReport(id: string, currency: string) {
        return this.http.get(environment.baseurl.jobs + "/job/start?name=PARTNER_SUMMARY&id=" + id + "&currency=" + currency, this.authenticationService.getHeaders()).pipe(map(res => res));
    }

    /* For Partner Tutorial's Image Update */
    updateImage(parameters: { country: object, partnerId: string, imageBase64: string, tutorialScreen: string }) {
        return this.http.post<ResponseBO<Partner>>(
            environment.baseurl.api + "/protected/partner/tutorial/updateImage",
            {
                country: parameters.country,
                partnerId: parameters.partnerId,
                imageBase64: parameters.imageBase64,
                tutorialScreen: parameters.tutorialScreen
            }, this.authenticationService.getHeaders()
        ).pipe();

    }

    getPartnersList(): Observable<PartnerDropdown[]> {
        return this.http.get(environment.baseurl.api + "/protected/partner/list", this.authenticationService.getHeaders())
            .pipe(map((res: any) => res.payload));
    }

    upload(fileName: string, fileContent: string | ArrayBuffer) {
        return this.http.post(
            environment.baseurl.api + "/protected/partner/output-file",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                fileName,
                data: fileContent
            }, this.authenticationService.getHeaders());
    }

    trigger() {
        return this.http.get(
            environment.baseurl.kyc.url + "/webhook/output-cronjob",
            this.authenticationService.getHeaders());
    }

    isUploadButtonDisabled(shopListFile?: number | File, ownerListFile?: number | File, uploadedAtmsCount?: number) {
        if (!shopListFile && !ownerListFile) {
            return true;
        }

        if (uploadedAtmsCount > 0 && ownerListFile) {
            return false;
        }

        if (shopListFile) {
            return false;
        }

        return true;
    }

    getUploadFilesButtonText(shopListFile?: number | File, ownerListFile?: number | File) {
        let text = "Upload files and save";
        if (!shopListFile && !ownerListFile) {
            return text;
        }

        if (shopListFile && !ownerListFile) {
            text = "Upload shops file and save";
        }

        if (!shopListFile && ownerListFile) {
            text = "Upload owners file and save";
        }

        return text;
    }

}
