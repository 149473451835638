/**
 * Created by Arindam Bajpayee on 28-04-2017.
 */
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";

import { environment } from "../../environments/environment";
import { AuthenticationService } from "./authentication.service";

@Injectable()
export class AccountService {
    constructor(private http: HttpClient,
                private authenticationService: AuthenticationService) {
    }

    /* For creating new user */
    createAccount(account: object) {
        return this.http.post(
            environment.baseurl.api + "/protected/account/create",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                account
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    changePassword(password: any) {
        return this.http.post(
            environment.baseurl.api + "/protected/account/changePassword",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                password
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    getAccounts() {
        return this.http.get(
            environment.baseurl.api + "/protected/account/all",
            this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    getRights() {
        return this.http.get(
            environment.baseurl.api + "/protected/account/rights",
            this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    updateRoleRights(role, rights) {
        return this.http.post(
            environment.baseurl.api + "/protected/account/updateRoleRights",
            { role, rights },
            this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    updateRoles(user, roles) {
        return this.http.post(
            environment.baseurl.api + "/protected/account/updateRoles",
            { user, roles },
            this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    resetPassword() {
        return this.http.post(
            environment.baseurl.api + "/protected/account/resetPassword",
            {
                currentUser: this.authenticationService.getCurrentUser()
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    resetPasswordForAccount(parameters) {
        return this.http.post(
            environment.baseurl.api + "/protected/account/resetPassword",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                name: parameters.name,
                user: parameters.user
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }

    deleteAccount(identifier: string) {
        return this.http.post(
            environment.baseurl.api + "/protected/account/delete",
            {
                currentUser: this.authenticationService.getCurrentUser(),
                identifier
            }, this.authenticationService.getHeaders()
        ).pipe(map(res => res));
    }
}
