export enum TutorialCustomization {
    TUTORIAL_USER_INTRO = "Tutorial User Intro",
    TUTORIAL_USER_PAYMENT_METHOD = "Tutorial User Payment Method",
    TUTORIAL_USER_TOPUP = "Tutorial User TopUp",
    TUTORIAL_USER_WITHDRAWAL = "Tutorial User Withdraw",
    TUTORIAL_SHOP_INTRO = "Tutorial Shop Intro"
}

/* Consumer tab. Feature settings enums */
export enum TabBarDashboard {
    DASHBOARD_EXPLORE = "Explore",
    DASHBOARD_WITHDRAW = "Withdraw",
    DASHBOARD_BACK_BUTTON = "Back Button",
    DASHBOARD_SEND = "Send",
    DASHBOARD_TOPUP = "Topup",
    DASHBOARD_SPOTLIGHT = "Spotlight"
}

export enum UserCapture {
    CAPTURE_USER_IBAN = "User IBAN",
    CAPTURE_USER_EMAIL = "User Email"
}

export enum TabBarProfile {
    DASHBOARD_PROFILE = "Tab bar PROFILE"
}

export enum TabBarContact {
    CONTACT_US = "Contact",
    CONTACT_US_PARTNER = "Partner URL"
}

export enum TabBarMonitor {
    DASHBOARD_MONITOR = "Tab bar MONITOR",
    DASHBOARD_MONITOR_TRANSACTION = "Monitor Transactions",
    DASHBOARD_MONITOR_LIMITS = "Monitor Limits",

    LIMIT_AMOUNT_WITHDRAW_DAILY = "Daily limit",
    LIMIT_AMOUNT_WITHDRAW_MONTHLY = "Monthly limit",
    LIMIT_AMOUNT_WITHDRAW_YEARLY = "Yearly limit",

    LIMIT_AMOUNT_SEND_DAILY = "Send Daily limit",
    LIMIT_AMOUNT_SEND_MONTHLY = "Send Monthly limit",
    LIMIT_AMOUNT_SEND_YEARLY = "Send Yearly limit",

    LIMIT_AMOUNT_RECEIVE_DAILY = "Receive Daily limit",
    LIMIT_AMOUNT_RECEIVE_MONTHLY = "Receive Monthly limit",
    LIMIT_AMOUNT_RECEIVE_YEARLY = "Receive Yearly limit",

    LIMIT_AMOUNT_TRANSACTION_DAILY = "Daily transaction",
    LIMIT_AMOUNT_TRANSACTION_WEEKLY = "Weekly transaction",
    LIMIT_AMOUNT_TRANSACTION_MONTHLY = "Monthly transaction",
    LIMIT_AMOUNT_TRANSACTION_YEARLY = "Yearly transaction"
}

export enum Transaction {
    TRANSACTION_CANCEL_BUTTON = "Transaction cancel button"
}

export enum TCPolices {
    CAPTURE_TC_PP = "Capture T&C / PP Approval",
    CAPTURE_TC_PP_CUSTOM = "Custom Partner T&C / PP"
}

export enum WithdrawGuaranteeMessage {
    INFO_MESSAGES_WITHDRAW_SCREEN = "Show / Hide"
}

export enum SCARequired {
    SCA_REQUIRED = "SCA Required",
    SCA_PASSCODE_REQUIRED = "SCA Passcode Required",
}

export enum ThemeSwitcher {
    THEME_SWITCHER = "Toggle theme"
}

export enum AuthorizePartnerProvidedData {
    APPLY_PARTNER_FEES = "Apply partner fees",
    APPLY_PARTNER_TAGS = "Apply partner tags"
}
/* Consumer tab. Feature settings enums */

/* Consumer tab. OnBoarding enums */
export enum OnBoarding {
    PERSONAL_INFORMATION = "Capture consumer personal info",
    ADDRESS = "Capture consumer Address",
    TAX_INFORMATION = "Capture consumer Tax Information",
    EMAIL = "Consumer Email",
    NAME = "Consumer Name",
    DATE_OF_BIRTH = "Consumer Date of Birth",
    TIN = "Consumer Tin",
    PHONE = "Phone",
    IBAN = "IBAN"
}
/* Consumer tab. OnBoarding enums */

/* Merchant tab. Feature settings enums */
export enum DashboardSettings {
    DASHBOARD_SETTINGS_SHOP = "Settings",
    DASHBOARD_MONITOR_TRANSACTION_HISTORY_SHOP = "Transaction (button shortcut)",
    // Merchant/Shop Profile (button shortcut)
    SHOW_ON_THE_GO_SHOP = "On the go (button shortcut)"
}

export enum AdditionalFunctions {
    ACTIVATE_WORKERS_SHOP = "Activate shop workers",
    // On the go
}

export enum ScanAndConfirm {
    SCAN_AND_CONFIRM_SHOP = "Confirm trx prompt after successful scan"
}

export enum ShowHide {
    SETTINGS_ACCOUNTS_SHOP = "Account Profile",
    SETTINGS_EDIT_SHOP = "Edit shop information",
    SETTINGS_SHOP_INFO_SHOP = "Shop information",
    PAYOUT_DETAILS = "Payout details",
    SHOW_TRANSACTIONS_SHOP = "Show Transactions",
    SHOW_PAYOUTS_SHOP = "Show payouts",
}

export enum Limits {
    LIMITS_PER_TIME_RANGE = "Limits per time range"
}

export enum TCPolicesShop {
    CAPTURE_TC_PP_SHOP = "Capture T&C / PP Approval",
    CAPTURE_TC_PP_SHOP_CUSTOM = "Custom Partner T&C / PP"
}
/* Merchant tab. Feature settings enums */

/* Merchant tab. OnBoarding Shop */
export enum OnBoardingShop {
    NAME = "Shop name",
    ADDRESS = "Address",
    OPENING_HOURS = "Opening hours",
    IMAGE = "Image",
    PAYOUT_AMOUNT = "Payout amount",
    BENEFICIARY = "Beneficiary",
    BENEFICIARY_INFORMATION = "Beneficiary information",
    EMAIL = "Email",
    IBAN = "IBAN",
}
/* Merchant tab. OnBoarding Shop */
