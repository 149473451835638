/**
 * Created by Arindam Bajpayee on 31-05-2017.
 */
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

/* Class to define modal buttons */
export class ModalAction {
    constructor(public title: string,
                public parameters: any,
                private callback?: ($event, parameters) => void) {
    }

    execute($event, activeModal) {
        activeModal.close("close");

        if (typeof this.callback === "function") {
            if (this.parameters === null || typeof this.parameters === "undefined") {
                this.parameters = {
                    parentComponent: null
                };
            }

            this.callback($event, this.parameters);
        }
    }
}


/* Class to define all the options */
export class ModalOptions {
    constructor(public severity?: Severity,
                public title?: string,
                public message?: string,
                public actions?: ModalAction[]) {
        if (this.severity == null) { this.severity = Severity.WARNING; }
        if (this.title == null || this.title.trim().length === 0) { this.title = "Alert"; }
        if (this.message == null || this.message.trim().length === 0) { this.message = "Are you sure you want to proceed?"; }
        if (this.actions == null || this.actions.length === 0) {
            this.actions = [];
        }
    }
}

export class Severity {
    static readonly NONE: string = "";
    static readonly INFO: string = "information";
    static readonly SUCCESS: string = "success";
    static readonly WARNING: string = "warning";
    static readonly ERROR: string = "error";
}

@Component({
    templateUrl: "./modal.component.html",
    styleUrls: [ "./modal.component.css" ]
})
export class ModalComponent implements OnInit {
    @Output() closeEvent = new EventEmitter<string>();
    @Input() modalOptions: ModalOptions = null;
    @Input() creationPartnerId: string;

    constructor(public activeModal: NgbActiveModal) {
    }

    ngOnInit(): void {
        if (this.modalOptions == null) {
            this.modalOptions = new ModalOptions(Severity.INFO, "Alert", "", null);
        }
    }

    close(): void {
        this.activeModal.close("Close click");
        // this.closeEvent.emit(this.creationPartnerId);
    }
}
