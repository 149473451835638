/**
 * Created by Surya Vedula on 20-06-2019.
 */

import { Component, Inject } from "@angular/core";

import { Transaction } from "../../../models/transaction";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

export interface DialogData {
    title: string;
    transaction: Transaction;
}

@Component({
    selector: "bo-transaction-history",
    styleUrls: [ "transaction-history.component.css" ],
    templateUrl: "transaction-history.component.html"
})
export class TransactionHistoryComponent {
    constructor(
        public dialogRef: MatDialogRef<TransactionHistoryComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    }

    onClickClose(): void {
        this.dialogRef.close();
    }
}
