/**
 * Created by Arindam Bajpayee on 27-04-2017.
 */
import { Component, ElementRef, OnDestroy, OnInit, ViewChild, inject } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgProgress } from "@ngx-progressbar/core";
import { ToastrService } from "ngx-toastr";
/* Models */
import { PaymentPostfinance } from "../../../models/payment";
/* Services */
import { AuthenticationService } from "../../../services/authentication.service";
import { PaymentService } from "../../../services/payment.service";
import { ModalComponent, ModalOptions, Severity } from "../../modal/modal.component";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { environment } from "../../../../environments/environment";
import { DataTableStateService } from "../../../services/data-table-state.service";
import { SelectedCountryData } from "../../shared/components/domain-selector.component";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
    selector: "bo-payment",
    templateUrl: "./payment.component.html",
    styleUrls: ["./payment.component.css"],
    animations: [
        trigger("detailExpand", [
            state("collapsed", style({ height: "0px", minHeight: "0", display: "none" })),
            state("expanded", style({ height: "*" })),
            transition("expanded <=> collapsed", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)"))
        ])
    ]
})

export class PaymentComponent implements OnInit, OnDestroy {
    @ViewChild("camt") camt: ElementRef;
    private fb: UntypedFormBuilder = inject(UntypedFormBuilder);
    public formGroup = this.fb.group({
        file: [null]
    });
    country = {
        code: "",
        currency: "",
        name: ""
    };
    filter = {
        bank: "",
        country: "",
        dateFrom: null,
        dateTo: null,
        debitor: "",
        reference: "",
        type: "CREDIT"
    };
    formData = new FormData();
    outstandingBalance = {
        atm: 0,
        user: 0
    };
    page = 1;
    pageSize = 50;
    maxSize = 5;
    paymentsInPostfinance: PaymentPostfinance[] = [];
    pagedPaymentsInPostfinance: PaymentPostfinance[] = [];
    noOfWidgets = 0;
    noOfWidgetsLoaded = 0;
    displayedColumns: string[] = ["identifier", "statementDate", "bookingDate", "valueDate", "from", "amount", "status"];
    expandedElement: any | null;
    percentDone: number;
    uploadSuccess: boolean;
    BANK_TRANSFER_NAME = "Bank transfer";
    SOFORT_TRANSFER_NAME = "Sofort transfer";
    bankTransferRE = /SONECT TOPUP/i;
    sofortTransferRE = /SONECT WITH.*/i;
    paymentTypes = [];
    paymentTypesList: string[] = [this.BANK_TRANSFER_NAME, this.SOFORT_TRANSFER_NAME];
    collectionSize: number;

    private fileName;

    private unsubscribe$ = new Subject<void>();

    constructor(
        private router: Router,
        private modalService: NgbModal,
        private progressBar: NgProgress,
        private authenticationService: AuthenticationService,
        private paymentService: PaymentService,
        private toastr: ToastrService,
        private dataTableStateService: DataTableStateService,
    ) {}

    ngOnInit(): void {
        if (!this.authenticationService.isUserLoggedIn()) {
            this.router.navigate(["/login"]);
            return;
        }
        const pageState = this.dataTableStateService.paymentTableState$.value;
        if (pageState) {
            this.filter = pageState.filter;
            this.paymentTypes = pageState.filter.paymentTypes;
            this.collectionSize = pageState.collectionSize;
            this.page = pageState.page;
        }
    }

    handlePaymentOptionsFilter = () => {
        this.callService(this.paymentTypes);
    }

    public classMapping = (status) => {
        return "payment-details-status " + {
            SUCCESSFUL: "successful",
            REFUNDED: "refunded",
            PROCESSING: "processing",
            FAILED: "failed",
            AUTHORIZED: "authorized",
            CANCELLED: "cancelled"
        }[status] || "";
    }

    /* Actions - search payments */
    searchPayments(): void {
        // this.page = 1;
        this.callService();
    }

    getEnvironment() {
        return {
            name: environment.name
        };
    }

    /* Action: Upload camt file to PoFi SFTP */
    upload(event) {
        const reader = new FileReader();

        if (event.target.files && event.target.files.length) {
            this.fileName = event.target.files[0].name;
            const [file] = event.target.files;
            reader.readAsDataURL(file);

            reader.onload = () => {
                this.formGroup.patchValue({
                    file: reader.result
                });
                this.paymentService.upload(this.fileName, this.formGroup.get("file").value)
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe(result => {
                        this.percentDone = 100;
                        this.uploadSuccess = true;
                        this.camt.nativeElement.value = null;
                        alert("Success!!!\n Please trigger the job in next 5 seconds");
                    });
            };
        }
    }

    get filteredPaymentsInPostfinance(): PaymentPostfinance[] {
        return this.paymentsInPostfinance.filter((payment: PaymentPostfinance) => this.applyFilter(payment, this.filter));
    }

    applyFilter(payment: PaymentPostfinance, filter: any): boolean {
        if (typeof filter.debitor !== "undefined" && filter.debitor.length > 0) {
            if (!payment.from.name || (payment.from.name && payment.from.name.toLocaleLowerCase().indexOf(filter.debitor.toLocaleLowerCase()) === -1)) {
                return false;
            }
        } else if (typeof filter.bank !== "undefined" && filter.bank.length > 0) {
            if (!payment.from.bank || (payment && payment.from.bank.toLocaleLowerCase().indexOf(filter.bank.toLocaleLowerCase()) === -1)) {
                return false;
            }
        } else if (filter.reference) {
            if (!payment || !payment.reference || (payment.reference.toLocaleLowerCase().indexOf(filter.reference.toLocaleLowerCase()) === -1)) {
                return false;
            }
        }

        return true;
    }

    // Get the domain, country and currency info
    selectedCountry(data: SelectedCountryData) {
        this.country = data;
        this.callService();
    }

    /* PRIVATE METHODS */

    /* Call the service */
    private callService(paymentTypeFilter?: string[]): void {
        this.noOfWidgets = 2;
        this.noOfWidgetsLoaded = 0;

        this.progressBar.ref().start();
        this.paymentsInPostfinance = [];

        // Inbound Payments only available for CH at the moment
        if (this.country.code == "CH") {
            this.noOfWidgets = 3;
            this.paymentService.geAllInbound({})
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(response => {
                    const data: any = { ...response };
                    // this.amlData = data.payload;
                    // @ts-ignore
                    const payments = data.payload.payments.sort((a, b) => new Date(b.statementDate) - new Date(a.statementDate)) || [];

                    if (paymentTypeFilter && paymentTypeFilter.length) {
                        const found = [];
                        paymentTypeFilter.forEach(searchable => {
                            if (searchable === this.BANK_TRANSFER_NAME) {
                                found.push(...payments.filter(x => this.bankTransferRE.test(x.reference)));
                            }

                            if (searchable === this.SOFORT_TRANSFER_NAME) {
                                found.push(...payments.filter(x => this.sofortTransferRE.test(x.reference)));
                            }
                        });

                        this.paymentsInPostfinance = found;
                    } else {
                        this.paymentsInPostfinance = payments;
                    }
                    this.collectionSize = this.filteredPaymentsInPostfinance.length;
                    this.paginate();
                    this.incrementProgressBar();
                }, error => {
                    this.handleError(error, false);
                    this.incrementProgressBar();
                });
        }

        this.paymentService.getAllOutstanding({ country: this.country.code, currency: this.country.currency, type: "atm" })
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(response => {
                const data: any = { ...response };
                this.outstandingBalance.atm = data.payload.amount;
                this.incrementProgressBar();
            }, error => {
                this.handleError(error, false);
                this.incrementProgressBar();
            });

        this.paymentService.getAllOutstanding({ country: this.country.code, currency: this.country.currency, type: "user" })
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(response => {
                const data: any = { ...response };
                this.outstandingBalance.user = data.payload.amount;
                this.incrementProgressBar();
            }, error => {
                this.handleError(error, false);
                this.incrementProgressBar();
            });
    }

    paginate(currentPage = 1) {
        const page = currentPage || 1;
        const perPage = this.pageSize || 10;
        const offset = (page - 1) * perPage;
        this.pagedPaymentsInPostfinance = this.filteredPaymentsInPostfinance.slice(offset).slice(0, this.pageSize);
    }

    saveTableConfig() {
        this.dataTableStateService.setPaymentTableState({
            filter: {
                bank: this.filter.bank,
                dateFrom: this.filter.dateFrom,
                dateTo: this.filter.dateTo,
                debitor: this.filter.debitor,
                reference: this.filter.reference,
                type: this.filter.type,
                paymentTypes: this.paymentTypes
            },
            collectionSize: this.collectionSize,
            currency: this.country.currency,
            country: this.country,
            page: this.page
        })
    }

    /* Error Handler */
    private handleError(error: any, showModal: boolean) {
        if (error.status == 0) { // or whatever condition you like to put
            this.toastr.error("Internet connection failed!", "Network error");
        } else if (error.status === 401 && error.statusText === "Unauthorized") {
            this.toastr.error("Unauthorized User", "User logout");
            this.authenticationService.logout();
            this.router.navigate(["/login"]);
        } else {
            let response = error.error;
            if (typeof response === "string") {
                response = JSON.parse(response);
            }
            this.toastr.error(response.message, response.result);
            if (showModal) {
                const modalRef = this.modalService.open(ModalComponent);
                modalRef.componentInstance.modalOptions = new ModalOptions(Severity.ERROR, error.statusText, response.message, null);
            }
        }
    }

    private incrementProgressBar(): void {
        this.noOfWidgetsLoaded += 1;
        if (this.noOfWidgetsLoaded === this.noOfWidgets) {
            this.progressBar.ref().complete();

        } else {
            this.progressBar.ref().inc(1 / this.noOfWidgets);
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
