/**
 * Created by Arindam Bajpayee on 27-04-2017.
 */
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
/* Guards */
import { AuthGuard } from "../../guards/authentication.guard";
import { NavigationPaymentsGuard } from "../../guards/navigation-payments.guard";
/* Components */
import { AdminComponent } from "./admin.component";
import { AuditsAtmComponent } from "./audits/atm/audits-atm.component";
import { DashboardComponent } from "./dashboard/containers/dashboard/dashboard.component";
import { JobComponent } from "./job/job.component";
import { LimitsComponent } from "./limits/limits.component";
import { MonitorComponent } from "./monitor/monitor.component";
import { PaymentComponent } from "./payment/payment.component";
import { ProfileComponent } from "./profile/profile.component";

const adminRoutes: Routes = [
    {
        path: "admin",
        component: AdminComponent,
        canActivate: [ AuthGuard ],
        children: [
            {
                path: "",
                canActivateChild: [ AuthGuard ],
                children: [
                    { path: "", component: DashboardComponent },
                    { path: "atm", loadChildren: () => import("./atm/atm.module").then(m => m.AtmModule) },
                    { path: "atm-audits", component: AuditsAtmComponent },
                    { path: "dashboard", component: DashboardComponent },
                    { path: "job", component: JobComponent },
                    { path: "limits", component: LimitsComponent },
                    { path: "monitor", component: MonitorComponent },
                    { path: "partner", loadChildren: () => import("./partner/partner.module").then(m => m.PartnerModule) },
                    { path: "payment", canActivate: [ NavigationPaymentsGuard ], component: PaymentComponent },
                    {
                        path: "payment-methods",
                        loadChildren: () => import("./payment-method/payment-methods.module")
                            .then(({ PaymentMethodsModule }) => PaymentMethodsModule)
                    },
                    { path: "profile", component: ProfileComponent },
                    { path: "transaction", loadChildren: () => import("./transaction/transaction.module").then(m => m.TransactionModule) },
                    { path: "user", loadChildren: () => import("./user/user.module").then(m => m.UserModule) },
                    { path: "settings", loadChildren: () => import("./settings/settings.module").then(m => m.SettingsModule) },
                    { path: "promocodes", loadChildren: () => import("./promo-codes/promo-codes.module").then(m => m.PromoCodesModule) },
                    { path: "spotlights", loadChildren: () => import("./spotlight/spotlight.module").then(m => m.SpotlightModule) },
                ]
            }
        ]
    }
];

@NgModule({
    imports: [ RouterModule.forChild(adminRoutes) ],
    exports: [ RouterModule ]
})
export class AdminRoutingModule {
}
