<div class="page-title">
    Payments
    <div class="page-title-actions">
        <bo-domain-selector (out)="selectedCountry($event)"></bo-domain-selector>
    </div>
</div>

<!-- Total outstanding balance Panel -->
<div class="panel panel-with-borders margin-top-10 margin-bottom-0 clear-fix">
    <div class="panel-heading clear-fix">
        <h5 class="display-inline">Outstanding Balance</h5>
        <div *ngIf="getEnvironment().name === 'DEV'" class="pull-right">
            <form [formGroup]="formGroup">
                <input #camt (change)="upload($event)" type="file"/>
            </form>
        </div>
    </div>
    <div class="panel-body font-size-30">
        <div class="row">
            <div class="col-lg-6 padding-right-0"><label class="margin-bottom-3">Total amount payable to <b>merchants</b>:</label></div>
            <div class="col-lg-6 font-weight-bold">
                <p class="margin-bottom-3 text-right">{{ outstandingBalance.atm }} {{ country.currency }}</p>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 padding-right-0"><label class="margin-bottom-3">Total amount payable to <b>users</b>:</label></div>
            <div class="col-lg-6 font-weight-bold">
                <p class="margin-bottom-3 text-right">{{ outstandingBalance.user }} {{ country.currency }}</p>
            </div>
        </div>
    </div>
</div>

<!-- Payments Panel -->
<div class="panel panel-with-borders margin-top-10 margin-bottom-0 clear-fix">
    <div class="panel-body" *ngIf="country?.code === 'CH'">
        <form (ngSubmit)="searchPayments()">
            <div class="" id="list_payments">

                <!-- Filter -->
                <div class="row margin-bottom-15">
                    <div class="col">
                        <mat-form-field class="margin-right-15 width-full" style="max-width: 350px;">
                            <input [ngModelOptions]="{standalone: true}" [(ngModel)]="filter.dateFrom" [matDatepicker]="dateFromPicker" matInput name="dateFrom" placeholder="From">
                            <mat-datepicker-toggle [for]="dateFromPicker" matSuffix></mat-datepicker-toggle>
                            <mat-datepicker #dateFromPicker></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field class="margin-right-15 width-full" style="max-width: 350px;">
                            <input [ngModelOptions]="{standalone: true}" [(ngModel)]="filter.dateTo" [matDatepicker]="dateToPicker" matInput name="dateTo" placeholder="To">
                            <mat-datepicker-toggle [for]="dateToPicker" matSuffix></mat-datepicker-toggle>
                            <mat-datepicker #dateToPicker></mat-datepicker>
                        </mat-form-field>

                        <!-- DO WE NEED THIS BUTTON? -->
                        <button mat-icon-button><i class="fas fa-search"></i></button>
                    </div>
                </div>

                <div class="row margin-bottom-15">
                    <div class="col">
                        <mat-form-field class="margin-right-15 width-full" style="max-width: 350px;">
                            <input [ngModelOptions]="{standalone: true}" [(ngModel)]="filter.debitor" matInput name="debitor" placeholder="Debitor">
                        </mat-form-field>
                        <mat-form-field class="margin-right-15 width-full" style="max-width: 350px;">
                            <input [ngModelOptions]="{standalone: true}" [(ngModel)]="filter.bank" matInput name="bank" placeholder="Bank">
                        </mat-form-field>
                        <mat-form-field class="margin-right-15 width-full" style="max-width: 350px;">
                            <input [ngModelOptions]="{standalone: true}" [(ngModel)]="filter.reference" matInput name="refId" placeholder="Reference">
                        </mat-form-field>
                        <mat-form-field class="margin-right-15 width-full" style="max-width: 350px;">
                            <mat-label>Payment type</mat-label>
                            <mat-select [ngModelOptions]="{standalone: true}" (selectionChange)="handlePaymentOptionsFilter()" [(ngModel)]="paymentTypes" multiple>
                                <mat-option *ngFor="let paymentType of paymentTypesList" [value]="paymentType">{{paymentType}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row margin-bottom-15 padding-horizontal-15">
                    <div class="col-lg-12 background-light-grey padding-vertical-8 padding-horizontal-16">
                        Total number of inbound payments: <b> {{ (page - 1) * pageSize + 1 }} - {{ page * pageSize }} / {{ collectionSize }}</b>.
                    </div>
                </div>

                <div *ngIf="paymentsInPostfinance.length > 0" class="row">
                    <div class="col-lg-12">
                        <ngb-pagination [collectionSize]="collectionSize" [pageSize]="pageSize" [rotate]="true"
                        (pageChange)="paginate($event)" [ellipses]="false"
                        [(page)]="page" [maxSize]="maxSize"></ngb-pagination>

                        <table [dataSource]="pagedPaymentsInPostfinance" class="mat-elevation-z1 table table-hover width-full" mat-table
                               multiTemplateDataRows>
                            <!--- Note that these columns can be defined in any order.
                                  The actual rendered columns are set as a property on the row definition" -->
                            <!-- Identifier -->
                            <ng-container matColumnDef="identifier">
                                <th *matHeaderCellDef mat-header-cell>Statement Id</th>
                                <td *matCellDef="let payment" mat-cell>{{ payment?.statementId }}</td>
                            </ng-container>
                            <!-- Booking Date -->
                            <ng-container matColumnDef="statementDate">
                                <th *matHeaderCellDef mat-header-cell>Statement Date</th>
                                <td *matCellDef="let payment" mat-cell>{{ payment?.statementDate | date:'y.MM.dd HH:mm:ss' }}</td>
                            </ng-container>
                            <!-- Booking Date -->
                            <ng-container matColumnDef="bookingDate">
                                <th *matHeaderCellDef mat-header-cell>Booking Date</th>
                                <td *matCellDef="let payment" mat-cell>{{ payment?.bookingDate | date:'y.MM.dd' }}</td>
                            </ng-container>
                            <!-- Value Date -->
                            <ng-container matColumnDef="valueDate">
                                <th *matHeaderCellDef mat-header-cell>Value Date</th>
                                <td *matCellDef="let payment" mat-cell>{{ payment?.valueDate | date:'y.MM.dd' }}</td>
                            </ng-container>
                            <!-- From -->
                            <ng-container matColumnDef="from">
                                <th *matHeaderCellDef mat-header-cell>Debitor</th>
                                <td *matCellDef="let payment" mat-cell>{{ payment?.from?.name }}</td>
                            </ng-container>
                            <!-- Amount -->
                            <ng-container matColumnDef="amount">
                                <th *matHeaderCellDef mat-header-cell>Amount</th>
                                <td *matCellDef="let payment" mat-cell>{{ payment?.amount }} {{ payment?.currency }}</td>
                            </ng-container>

                            <!-- Status -->
                            <ng-container matColumnDef="status">
                                <th *matHeaderCellDef mat-header-cell>Status</th>
                                <td *matCellDef="let payment" mat-cell>
                                    <div *ngIf="!!payment?.status" [class]="classMapping(payment?.status)">
                                        {{ payment?.status }}
                                    </div>
                                </td>
                            </ng-container>

                            <!-- Expanded row with details -->
                            <ng-container matColumnDef="expandedDetails">
                                <td *matCellDef="let payment" [attr.colspan]="displayedColumns.length" mat-cell>
                                    <div [@detailExpand]="payment == expandedElement ? 'expanded' : 'collapsed'" class="payment-details">
                                        <div>
                                            <span class="font-weight-bold">Debitor account: </span>
                                            <span>{{ payment?.from?.iban }} ({{ payment?.from?.bank }})</span>
                                        </div>
                                        <div>
                                            <span class="font-weight-bold">Additional Info: </span>
                                            <span>{{ payment?.additionalInfo }}</span>
                                        </div>
                                        <div>
                                            <span class="font-weight-bold">Reference: </span>
                                            <span>{{ payment?.reference }}</span>
                                        </div>
                                        <div>
                                            <span class="font-weight-bold">File: </span>
                                            <span>{{ payment?.file }}</span>
                                        </div>

                                        <div *ngIf="payment.status === 'REFUNDED'">
                                            <span class="font-weight-bold">Refund reason: </span>
                                            <span>{{ payment?.comment }}</span>
                                            <br/>
                                            <span class="font-weight-bold">Statement data: </span>
                                            <span>{{ payment?.refundStatementId }} was send at {{ payment?.refundStatementDate | date:'y.MM.dd' }}</span>
                                        </div>
                                    </div>
                                </td>
                            </ng-container>

                            <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
                            <tr (click)="expandedElement = expandedElement === payment ? null : payment" *matRowDef="let payment; columns: displayedColumns;"
                                [class.expanded]="expandedElement === payment" class="payment-row"
                                mat-row></tr>
                            <tr *matRowDef="let row; columns: ['expandedDetails']" class="payment-details-row" mat-row></tr>
                        </table>

                        <ngb-pagination [collectionSize]="collectionSize" [pageSize]="pageSize" [rotate]="true"
                        (pageChange)="paginate($event)" [ellipses]="false"
                        [(page)]="page" [maxSize]="maxSize"></ngb-pagination>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
