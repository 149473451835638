/**
 * Created by Arindam Bajpayee on 25-04-2017.
 */
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationService } from "./services/authentication.service";
import { DEFAULT_INTERRUPTSOURCES, Idle } from "@ng-idle/core";
import { Keepalive } from "@ng-idle/keepalive";

import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalAction, ModalComponent, ModalOptions, Severity } from "./components/modal/modal.component";
import { environment } from "../environments/environment";
import { PusherService } from "./services/pusher/pusher.service";

@Component({
    selector: "bo-root",
    template: `
        <router-outlet></router-outlet>
        <ng-progress [color]="'#ff0069'" [thick]="true"></ng-progress>
    `
})
export class AppComponent implements OnInit {
    idleState = "Not started.";
    idleTime = environment.production ? 60 * 30 : 24 * 60 * 60;
    timeoutPeriod = environment.production ? 60 * 5 : 24 * 60 * 60;
    timedOut = false;
    lastPing?: Date = null;

    modalRef = null;

    constructor(private router: Router,
                private idle: Idle, private keepalive: Keepalive,
                private authenticationService: AuthenticationService,
                private modalService: NgbModal, /*private swPush: SwPush,*/
                private pusherService: PusherService,
    ) {
        this.setIdleTimeout();
    }

    ngOnInit() {
        window.addEventListener("storage", (event) => {
            if (event.key === "LOGOUT"){
                this.logout();
            }

            if (event.key === "currentUser" && !event.newValue){
                if (this.modalRef) {
                    this.modalRef.componentInstance.activeModal.close("timed out");
                }
                this.idle.stop();
                this.router.navigate([ "/login" ]);
            }
        });
    }

    getCurrentUser() {
        return this.authenticationService.getCurrentUser();
    }

    logout(): void {
        this.authenticationService.logout();
        this.pusherService.unsubscribe();
        this.router.navigate([ "/login" ]);
    }

    reset() {
        this.idle.watch();
        this.idleState = "Started.";
        this.timedOut = false;
    }

    setIdleTimeout(): void {
        this.idle.setIdle(this.idleTime);
        // after 5 min of inactivity, the user will be considered timed out.
        this.idle.setTimeout(this.timeoutPeriod);
        // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

        this.idle.onIdleEnd.subscribe(() => {
            this.idleState = "No longer idle.";
        });
        this.idle.onTimeout.subscribe(() => {
            this.idleState = "Timed out!";
            this.timedOut = true;
            this.modalRef.componentInstance.activeModal.close("timed out");
            this.logout();
        });
        this.idle.onIdleStart.subscribe(() => {
            this.idleState = "You've gone idle!";
            this.idleStateConfirmBox();
        });

        this.authenticationService.getUserLoggedIn().subscribe(userLoggedIn => {
            if (userLoggedIn) {
                this.idle.watch();
                this.timedOut = false;
            } else {
                this.idle.stop();
            }
        });

        this.reset();
    }

    /* Action: Confirm Box Popup Weather to expire session or remain login */
    idleStateConfirmBox(): void {
        this.modalRef = this.modalService.open(ModalComponent);
        const parameters = { parentComponent: this };
        this.modalRef.componentInstance.modalOptions =
            new ModalOptions(
                Severity.WARNING,
                "You have gone idle!",
                "Do you want to stay logged in or else the session will expire!",
                [
                    new ModalAction("Yes", parameters, function() {
                        this.reset();
                    }.bind(this))
                ]);
    }
}
