/**
 * Created by Arindam Bajpayee on 9/13/2020.
 */
export class Fees {
    fixed: number;
    variable: FeesVariable;
    payer: FeesPayerType;
    trial: boolean;
    min?: number;
    max?: number;
}

export interface FeesForm {
    fees: Fees[];
    trial: boolean;
    tags: [];
}

export class FeesVariable {
    value: number;
    type: FeesVariableType;
    of: FeesVariablePercentOfType;
}

/* Enums */

export enum FeesPayerType {
    CUSTOMER = "customer",
    PARTNER = "partner"
}

export enum FeesVariableType {
    NONE = "",
    FIXED = "fixed",
    PERCENT = "percent"
}

export enum FeesVariablePercentOfType {
    NONE = "",
    AMOUNT = "amount",
    FIXED = "fixed"
}
